import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import APIs from '../Api';

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productInfo:{}
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements,getCredit } = this.props;
    if (!stripe || !elements) {
      return;
    }
  
    const card = elements.getElement(CardElement);
  
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error) {
      console.error(error.message);
    } else {

      const token = localStorage.getItem('token');
      const headerOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      };
      
      headerOptions.body = JSON.stringify({id:paymentMethod.id})
      const response = await fetch(APIs.STRIPE_PAYMENT_INTENT, headerOptions);
      const { key } = await response.json();
  
      const confirmResult = await stripe.confirmCardPayment(key, {
        payment_method: paymentMethod.id,
        return_url: 'http://www.farmin-app.com/',
      });
  
      if (confirmResult.error) {
        console.error(confirmResult.error.message);
      } else {
        let intent_id = confirmResult.paymentIntent.id;
        let amount = this.state.productInfo.amount
        let credit = 10000
        headerOptions.body = JSON.stringify({intent_id,amount,credit})
        await fetch(APIs.STRIPE_PAYMENT_SUCCESS, headerOptions);
        getCredit()
      }
    }
  };

  getProductInfo = async ()=> {
      const token = localStorage.getItem('token');
      const headerOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`,
        },
      };
      const response  = await fetch(APIs.STRIPE_SINGLE_PRODUCT_INFO, headerOptions);
      const {code,data} = await response.json();
      if (code == 200){
          this.setState({productInfo:data})
      }
  }

  componentDidMount() {
    this.getProductInfo()
  }

  render() {
    return (
      <div>
        <div className="product-info">
          <h4 className="product-price">
            <span>
              Amount: {this.state.productInfo.amount} 
              <span className="cur"> {this.state.productInfo.currency}</span>, 
              Credit: 10000</span>
          </h4>
        </div>
        <form onSubmit={this.handleSubmit}>
          <CardSection />
          <button disabled={!this.props.stripe} className="btn-pay">
            Buy Now
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} getCredit={props.getCredit} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
