import { newImageryData } from "../OrderImageData"
import buildingDetection1 from "../../../../images/bulding-detection-1.png"
import carPolIcon from '../../../../images/carPoL.png'
import buildPoL from '../../../../images/BuildPoL.png'

const aoi           = newImageryData.aoi
const aoiTypeValue  = newImageryData.aoiTypeValue

export const patternLifeData = [
    {   
        id:1,
        rasterId:"hassanms.adm_new_24",
        shpfile:"houses.zip",
        footPrint:"Building",
        lat:24.434092514791253,
        log:54.37896460258118,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        aoi:[
            {   
                id:1,
                name:"Option 1",
                rasterId:"hassanms.adm_new_24",
                shpfile:"houses.zip",
                lat:24.432524759418115,
                log:54.36999626110498,
            },
            {   
                id:2,
                name:"Option 2",
                rasterId:"hassanms.new_42",
                shpfile:"shape_42.zip",
                lat:24.433467365288156,
                log:54.469773470989324,
            },
            {   
                id:3,
                name:"Option 3",
                rasterId:"hassanms.new_59",
                shpfile:"shape_59.zip",
                lat:24.345160901118653,
                log:54.5792283787538,
            }
        ],
    },
    {   
        id:2,
        rasterId:"hassanms.new_58",
        shpfile:"car_new58.zip",
        lat:24.341538137472096,
        log:54.47506886971215,
        footPrint:"Car",
        name:"Car Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        aoi:[
            {   
                id:1,
                name:"Option 1",
                rasterId:"hassanms.new_58",
                shpfile:"car_new58.zip",
                lat:24.341538137472096,
                log:54.47506886971215,
            },
            {   
                id:2,
                name:"Option 2",
                rasterId:"hassanms.new_59",
                shpfile:"car_new59.zip",
                lat:24.345160901118653,
                log:54.5792283787538,
            },
        ]
    },
    {   
        id:3,
        rasterId:"hassanms.car_new_60",
        shpfile:"car_shap.zip",
        footPrint:"Car",
        lat:24.425652735078057,
        log:54.53105658255902,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:4,
        rasterId:"hassanms.car_new_60",
        shpfile:"car_shap.zip",
        footPrint:"Car",
        lat:24.425652735078057,
        log:54.53105658255902,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:5,
        rasterId:"hassanms.car_new_60",
        shpfile:"car_shap.zip",
        footPrint:"Car",
        lat:24.425652735078057,
        log:54.53105658255902,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:6,
        rasterId:"hassanms.car_new_60",
        shpfile:"car_shap.zip",
        footPrint:"Car",
        lat:24.425652735078057,
        log:54.53105658255902,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            aoi,
            aoiTypeValue
        }
    }
]

export const lifeData = [
    {
        id:1,
        name:"Car Detection PoL",
        icon:carPolIcon,
        star: "2024-01-05",
        end: "2024-01-20",
        value: "Week",
        days: "2",
        cars: "4539",
        change: "45.56%",
        status: "up",
        selected_cars: "201",
        selected_change: "6.05%",
        selected_change_staus: "down"
    },
    {
        id:2,
        name:"Building Footprint PoL",
        icon:buildPoL,
        star: "2023-12-05",
        end: "2024-01-20",
        value: "Week",
        days: "5",
        cars: "5049",
        change: "50.56%",
        status: "up",
        selected_cars: "504",
        selected_change: "8.05%",
        selected_change_staus: "down"
    }
]

