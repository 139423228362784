import React,{useState,useCallback, useEffect} from "react";

import { Box,CircularProgress } from "@material-ui/core";
import "./aiModel.css";
import ModelDetection from "./ModelDetection";
import {AiModelData} from "./AiModelData.js"

export default function AiModels({orderImageHandler,analyzeOrderHandler_,hideArchive_}) {

  // +++++++++++++++ Force Update State +++++++++++++++++++
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  // +++++++++++++++ Ai Models State +++++++++++++++++++
  const [aiModelData,setAiModelData]  = useState(AiModelData)

  // +++++++++++++++ Ai Models Loader State +++++++++++++++++++
  const [showAiVaultLoader,setAiVaultLoader] = useState(false)

  // +++++++++++++++ Ai Models Model State +++++++++++++++++++
  let showAiModels_ = localStorage.getItem('show_ai_models') || "{}"
  showAiModels_ = JSON.parse(showAiModels_)
  const [showAiModels,setShowAiModels] = useState(showAiModels_)
  let modelActive_  = localStorage.getItem('model_active') || 0
  const [modelActiveId,setModelActive] = useState(parseInt(modelActive_))
  const [aiModelsData,setAiModelsData] = useState({})

  // +++++++++++++++ Ai Models Filter Data State +++++++++++++++++++
  const [filterDataExist,setFilterData] = useState(true)


  // +++++++++++++++ Ai Models Model State +++++++++++++++++++
  let hideAiArchive_ = localStorage.getItem('hide_ai_archive') || 0
  const [hideAiArchive,setHideAiArchive] = useState(hideAiArchive_)

 
  useEffect(()=>{
    forceUpdate()
  },hideArchive_)


  // +++++++++++++++ Ai Model Search Handler +++++++++++++++++++ 
  const searchVaultHandler = (e) =>{
    const {value} = e.target
    if (value != '' ||  value != ' '){
      setAiVaultLoader(true)
      const filteredData  = AiModelData.filter((data,)=>{
        const regex = new RegExp(`${value}`, 'i');
        return (
          regex.test(data.name) ||
          regex.test(data.description) ||
          data.tag.some((tag) => regex.test(tag))
        ); 
      })
      setTimeout(()=>{
        setAiVaultLoader(false)
        setAiModelData(filteredData)
      },600)
      
    } else {
      setAiModelData(AiModelData)
    }
  } 


  // +++++++++++++++ Ai Model Vault +++++++++++++++++++ 
  const aiModelVaultHandler = (modelData) =>{
    let poupname = modelData.name.replace(' Detection','')
    localStorage.setItem('popup_text_name',poupname)
    localStorage.removeItem('ai_contaner_class')
    setAiModelsData(modelData)
    localStorage.setItem('model_active',modelData.id)
    setShowAiModels({id:modelData.id,rasterId:modelData.rasterId})
    localStorage.setItem('show_ai_models',JSON.stringify({id:modelData.id,rasterId:modelData.rasterId}))
    modelData.aoiIsSeletced = false 
    modelData.dataShow = false 
    modelData.showRaster = true 
    modelData.removeRaster = false
    orderImageHandler(modelData)
    localStorage.removeItem('ai_detection_image')
    localStorage.removeItem('ai_detection_aoi')
    forceUpdate()
  }

  const aiModelVault = () =>{
    return(
      <>
        { 
          aiModelData.map((item_,index)=>{
            const vaultTags = item_.tag
            return(
              <Box key={index} className={"vaultContainerBox" + (modelActiveId == item_.id? " model_active":"")} mt={1} onClick={()=>{aiModelVaultHandler(item_)}} >
                <Box key={index+1}>
                  {/* First Layer */}
                  <Box className="imageLayer">
                    {/* Image */}
                    <img
                      src={item_.src}
                      alt="model-image"
                      style={{
                        width: "150px",
                        height: "138px",
                        borderRadius: "5px",
                      }}
                    />
                  </Box>
                  {/* Second Layer */}
                  <Box className="xtetLayer">
                    <span className="subHead">{item_.name}</span>
                    <span className="subContent">{item_.description}</span>
                    <Box className="vaultTags">
                      {
                        vaultTags.map((tag,index)=>{
                          return(
                            <span key={index} className="labelSpan">
                              <span className="labelTxt">{tag}</span>
                            </span>
                          )
                        })
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          })
        }
      </>
    )
  }


  const hideAiArchiveHandler = () =>{
    forceUpdate()
  }



  // +++++++++++++++ Return Jsx +++++++++++++++++++ 
  return (
    <Box display="flex" m={1.25} flexDirection="row">

      { hideAiArchive == 0 &&
        <Box mt={0} ml={0.4}  className="containerBox aiModelContainer">
          <div className="titleContainer">
            <span className="title">AI Models</span>
          </div>
          <Box className="vaultBox" mt={1}>
            <Box display="flex" flexDirection="column">
              <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                <span>Models Vault:</span>
              </Box>
              <div className="searchBox">
                <input
                  className="searchInput"
                  type="text"
                  placeholder="Search"
                  onChange={(e)=>{searchVaultHandler(e)}}
                />
                <button className="searchButton">
                  <svg
                    className="svgImg"
                    width="20"
                    height="20"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.3333 15.5L9.08333 10.25C8.66667 10.5833 8.1875 10.8472 7.64583 11.0417C7.10417 11.2361 6.52778 11.3333 5.91667 11.3333C4.40278 11.3333 3.12153 10.809 2.07292 9.76042C1.02431 8.71181 0.5 7.43056 0.5 5.91667C0.5 4.40278 1.02431 3.12153 2.07292 2.07292C3.12153 1.02431 4.40278 0.5 5.91667 0.5C7.43056 0.5 8.71181 1.02431 9.76042 2.07292C10.809 3.12153 11.3333 4.40278 11.3333 5.91667C11.3333 6.52778 11.2361 7.10417 11.0417 7.64583C10.8472 8.1875 10.5833 8.66667 10.25 9.08333L15.5 14.3333L14.3333 15.5ZM5.91667 9.66667C6.95833 9.66667 7.84375 9.30208 8.57292 8.57292C9.30208 7.84375 9.66667 6.95833 9.66667 5.91667C9.66667 4.875 9.30208 3.98958 8.57292 3.26042C7.84375 2.53125 6.95833 2.16667 5.91667 2.16667C4.875 2.16667 3.98958 2.53125 3.26042 3.26042C2.53125 3.98958 2.16667 4.875 2.16667 5.91667C2.16667 6.95833 2.53125 7.84375 3.26042 8.57292C3.98958 9.30208 4.875 9.66667 5.91667 9.66667Z"
                      fill="#0E62E8"
                    />
                  </svg>
                </button>
              </div>
              <Box className={"vaultContainer" + (showAiVaultLoader ? " vault_loader":"")}>
                { showAiVaultLoader ? 
                    <Box className="loader_container">
                      <CircularProgress className="loaderC"/>
                    </Box> 
                  :
                  <>
                    {aiModelVault()}
                  </>
                }
              </Box>
            </Box>
          </Box>
        </Box>
      }

      <Box>
        {
          !showAiModels.id ? <></>:
          <ModelDetection hideAiArchiveHandler_={hideAiArchiveHandler} analyzeOrderHandler_={analyzeOrderHandler_} orderImageHandler_={orderImageHandler} data={AiModelData} initData={showAiModels}/>}
      </Box>
    </Box>
  );
}
