import React,{useEffect, useState} from 'react'
import { Box,CircularProgress, Typography,Grid,Button,Dialog,DialogActions,DialogContent,DialogContentText,DialogTitle,Divider } from '@material-ui/core'
import polyline from '../../../../images/polyline.png'
import APIs from '../../../../Api'
import shp from 'shpjs';
import * as turf from '@turf/turf';
import { useDispatch } from 'react-redux';
import { analyzeProjectSignal,analyzeProjectPopUpSignal} from '../../../../store/actions/auth';

export default function FootprintModel({orderImageHandler,getAllPatternLife,backHandler,data}) {

    const dispatch = useDispatch()


    useEffect(()=>{
        let showFootprintLayer = localStorage.getItem('show_footprint_layer')
        if (showFootprintLayer == '1'){
            data.aoiIsSeletced  = false
            data.dataShow       = false
            data.showRaster     = true
            orderImageHandler(data)
            localStorage.setItem('show_footprint_layer','0')
        }   
    },[data])



    // +++++++++++++++ Project Name State +++++++++++++++++++
    let  projectName_  = localStorage.getItem('footprint_data') || '{}'
    projectName_ = JSON.parse(projectName_)
    const  [projectName,setProjectName] = useState(projectName_.name)


    // +++++++++++++++ Date State +++++++++++++++++++
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const startDate_ = currentDate.toISOString().slice(0, 10);
    const endDate_ = new Date().toISOString().slice(0, 10);
    const [startDate, setStartDate] = useState(startDate_)
    const [endDate, setEndDate] = useState(endDate_)

    // +++++++++++++++ Selected Days  State +++++++++++++++++++
    let  selectedDays_  = localStorage.getItem('footprint_data') || '{}'
    selectedDays_ = JSON.parse(selectedDays_)
    const  [selectedDays,setSelectedDays] = useState(selectedDays_.days)

    // +++++++++++++++ Frequency Value  State +++++++++++++++++++
    let  frequencyValue_  = localStorage.getItem('footprint_data') || '{}'
    frequencyValue_ = JSON.parse(frequencyValue_)
    const  [frequencyValue,setFrequencyValue] = useState(frequencyValue_.value)

    // +++++++++++++++ Aoi State +++++++++++++++++++
    const  storageAoiType_  = localStorage.getItem('aoi_selected_image') || 'select'
    const  [aoiType_,setAoiType_] = useState(storageAoiType_)
    const  [aoiData_,setAoiData] = useState({})

    // +++++++++++++++ Number of building State +++++++++++++++++++
    const  noOfBuilding  = localStorage.getItem('no_of_building') || '0'
    const  [noOfBuilding_,setNoOfBuilding] = useState(noOfBuilding)

    // +++++++++++++++ Number of building State +++++++++++++++++++
    const  noOfTotalAreaBuilding  = localStorage.getItem('area_of_building') || '0'
    const  [noOfTotalAreaBuilding_,setNoOfTotalAreaBuilding] = useState(noOfTotalAreaBuilding)

    let  aoiArea  = localStorage.getItem('aoi_area') || '0'
    const  [aoiArea_,setAoiArea_] = useState(aoiArea)
   

    // +++++++++++++++ Order State +++++++++++++++++++
    let  footprintData_  = localStorage.getItem('footprint_data') || '{}'
    footprintData_ = JSON.parse(footprintData_)
    const  [footprintData,setFootprintData] = useState(footprintData_)

    // +++++++++++++++ Start Analysis State +++++++++++++++++++
    const  [showAnalysisLoader,setShowAnalysisLoader] = useState(false)

    // +++++++++++++++ Start Analysis State +++++++++++++++++++
    const  [aoiError,setAoiError] = useState('')


     // +++++++++++++++ open popup State +++++++++++++++++++
    const [open, setOpen] = React.useState(false);

    
    // +++++++++++++++  generate Short UUID +++++++++++++++++++
    function generateShortUUID() {
        const array = new Uint8Array(4);
        crypto.getRandomValues(array);
        const shortUUID = Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
        return shortUUID;
    }

    // +++++++++++++++  Project Name Handler  +++++++++++++++++++
    const nameHandler = (e) =>{
        const {value} = e.target
        let  id = generateShortUUID()
        let orderData = {...footprintData,name:value,id}
        setFootprintData(orderData)
        localStorage.setItem('footprint_data',JSON.stringify(orderData))
        setProjectName(value)
    }

    // +++++++++++++++ Date Handler  +++++++++++++++++++
    const handleDateChange = (e, type) => {
        const date_ = e.target.value
        if (type == 'start') {
          setStartDate(date_);
        } else {
          setEndDate(date_);
        }
        let orderData = {...footprintData,[type]:date_}
        setFootprintData(orderData)
        localStorage.setItem('footprint_data',JSON.stringify(orderData))
    };

    // +++++++++++++++ Foot Print Frequency Value +++++++++++++++++++
    const frequencyValueHandler  = (e) =>{
        const {value} = e.target
        let orderData = {...footprintData,value}
        setFootprintData(orderData)
        setFrequencyValue(value)
        localStorage.setItem('footprint_data',JSON.stringify(orderData))
    }

    // +++++++++++++++ Foot Print Frequency Days +++++++++++++++++++
    const footPrintDaysHandler =  (e) =>{
        const {value} = e.target
        if (value != 'select'){
            let orderData = {...footprintData,days:value}
            setFootprintData(orderData)
            localStorage.setItem('footprint_data',JSON.stringify(orderData))
            setSelectedDays(value)
        }
    }

    const footPrintFrequencyDays = () => {
        return(
            <select  type="text" value={selectedDays} onChange={(e)=>footPrintDaysHandler(e)} className='frequency_days'  placeholder="Days">
                <option value='select'>Select</option>
                <option value='hours'>Hours</option>
                <option value='days'>Days</option>
                <option value='week'>Week</option>
                <option value='year'>Year</option>
            </select> 
        )
    }

    // +++++++++++++++ Aoi +++++++++++++++++++
    const aoiHandler = (e) =>{
        localStorage.setItem('aoidraw_enable','1')
        const {value} = e.target
        if (value != 'select'){
            let filterAoi  = data.aoi.filter((item)=>{
                return item.id == parseInt(value)
            })
            if (filterAoi.length > 0){
                let data_ = filterAoi[0]
                data_.aoiIsSeletced = false
                data_.dataShow      = false
                data_.showRaster    = true
                orderImageHandler(data_)
                setAoiData(data)
                setAoiType_(value)
                localStorage.setItem('aoi_selected_image',value)
                let orderData = {...footprintData,aoiType:filterAoi[0].rasterId}
                setFootprintData(orderData)
                localStorage.setItem('footprint_data',JSON.stringify(orderData))
                let  selectFootPrintData    = localStorage.getItem('select_footPrint_data')
                selectFootPrintData         = JSON.parse(selectFootPrintData)
                selectFootPrintData = {...selectFootPrintData,rasterId:filterAoi[0].rasterId,shpfile:filterAoi[0].shpfile}
                localStorage.setItem('select_footPrint_data',JSON.stringify(selectFootPrintData))
            }   
        }
    }

    const footPrintAoi = () =>{
        let aoiType = data.aoi
        return(
          <>
          <select className="selectListStyle" value={aoiType_}  onChange={(e)=>{aoiHandler(e)}}>
            <option value='select'>Choose an area</option>
            {aoiType.map((item_,index)=>{
              return(
                <option key={index} value={item_.id}>{item_.name}</option>
              )
            })}
            
          </select>
          </>
        )
    }

    // +++++++++++++++ Draw Polygon Handler   +++++++++++++++++++
    const drawPolygonHandler = () =>{

        localStorage.removeItem('no_of_building','14')
        localStorage.removeItem('area_of_building','14')
        localStorage.removeItem('aoi_area','14')


        localStorage.setItem('map_zoom','14')
        let aoiInit = localStorage.getItem('aoidraw_enable') || '1'
        let data = {}
        let aoiIsSeletced =  true
        if (aoiInit == '0'){
        aoiInit = '1'
        aoiIsSeletced = false
        } else {
        aoiInit = '0'
        }
        data.aoiIsSeletced = aoiIsSeletced
        data.dataShow      = false
        
        orderImageHandler(data)
        localStorage.setItem('aoidraw_enable',aoiInit)
    }

    const startAnalysis = () =>{
        let footprintData_ = localStorage.getItem('footprint_data') || "{}"
        footprintData_ = JSON.parse(footprintData_)
        if (!footprintData_.start){
            footprintData_.start = startDate
        } 
        if (!footprintData_.end){
            footprintData_.end = endDate
        }
        if (Object.keys(footprintData_).length >= 3){ 
            setShowAnalysisLoader(true)
            footprintData_.change = '45.56%'
            footprintData_.status = 'up'
            footprintData_.selected_change = '6.05%'
            footprintData_.selected_change_staus = 'down'
            placeFootPrint(footprintData_)
        }   
    }

    function generateShortUUID() {
        const array = new Uint8Array(4);
        crypto.getRandomValues(array);
        const shortUUID = Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
        return shortUUID;
    }

    const placeFootPrint = async(data) => {
        let  selectFootPrintData    = localStorage.getItem('select_footPrint_data')
        selectFootPrintData         = JSON.parse(selectFootPrintData)
        const image                 = window.location.origin+"/up42/"+selectFootPrintData.shpfile;
        let selectedCoordinator     = localStorage.getItem("archive_coordinator") || "{}"
        let strSelectedCoordinator  = selectedCoordinator
        selectedCoordinator         = JSON.parse(selectedCoordinator)
        let process  = false
        let  coordinates = []
        if (Object.keys(selectedCoordinator).length > 0){
            process =  true
            coordinates = selectedCoordinator.coordinates
        }
        if (process){
            let geojson                 = await shp(image)

            
            // let totalArea = 0;
            // geojson.features.forEach((feature) => {
            //     if (feature.geometry && feature.geometry.coordinates){
            //         const featurePolygon = turf.polygon(feature.geometry.coordinates);
            //         const areaInSquareMeters = turf.area(featurePolygon);
            //         totalArea += areaInSquareMeters;
            //     }
            // });
            // const totalAreaInKm2 = totalArea / 1e6;
            // localStorage.setItem('area_of_building',totalAreaInKm2)
            // setNoOfTotalAreaBuilding(totalAreaInKm2)
            
            
            let totalObject             = geojson.features.length
            const polygon_  = coordinates
            const drawnPolygon = turf.polygon(polygon_);
            let selectedFeatures = []

            selectedFeatures = geojson.features.filter((feature) => {
                if (feature.geometry && feature.geometry.coordinates){
                    const featurePolygon = turf.polygon(feature.geometry.coordinates);
                    return turf.booleanWithin(featurePolygon, drawnPolygon);
                } else {
                    return false
                }
            });


            let totalArea = 0;
            selectedFeatures.forEach((feature) => {
                if (feature.geometry && feature.geometry.coordinates){
                    const featurePolygon = turf.polygon(feature.geometry.coordinates);
                    const areaInSquareMeters = turf.area(featurePolygon);
                    totalArea += areaInSquareMeters;
                }
            });

            const totalAreaInKm2 = totalArea / 1e6;
            localStorage.setItem('area_of_building',totalAreaInKm2)
            setNoOfTotalAreaBuilding(totalAreaInKm2)

            let selectedObject = selectedFeatures.length
            localStorage.setItem('no_of_building',selectedObject)
            setNoOfBuilding(selectedObject)

            let footPrintData = {
                "name": data.name,
                "startDate": data.start,
                "endDate": data.end,
                "f_value": data.value,
                "f_days": data.days,
                "total_object": totalObject.toString(),
                "change_object": data.change,
                "status": data.status,
                "selected_object":selectedObject.toString(),
                "s_change_object": data.selected_change,
                "s_status_object": data.selected_change_staus,
                "coordinator": strSelectedCoordinator,
                "graph_change": "",
                "geoJson":selectedFeatures,
                "area_of_building":totalAreaInKm2.toFixed(2).toString(),
                "aoi_area":aoiArea_.toString()
            };
            const token = localStorage.getItem('token');
            footPrintData.token = token
            footPrintData.key_url   = APIs.PLACE_FOOTPRINT.toString()
            footPrintData.uuid      = generateShortUUID()
            const headerOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(footPrintData),
            };
            
            try {
                let model_api = `${APIs.MODEL_DOMIN}/analyze_footprint/`
                const response = await fetch(model_api, headerOptions);
                const { data, code, message } = await response.json();
                if (code === 200) {
                    getAllPatternLife()
                    setTimeout(() => {
                        localStorage.setItem('show_life_model_','0')
                        localStorage.setItem('show_footprint_model_','0')
                        setProjectName('')
                        setSelectedDays('')
                        setEndDate()
                        setStartDate()
                        setFrequencyValue('')
                        setAoiType_('select')
                        setFootprintData({})
                        localStorage.removeItem('footprint_data')
                        backHandler()
                        localStorage.setItem('get_all_life',0)
                        dispatch(analyzeProjectSignal(true))
                    }, 4000);
                    
                    localStorage.setItem('popup_signal','1')
                    dispatch(analyzeProjectPopUpSignal(true))

                } else {
                    //console.log('null');
                }
                setShowAnalysisLoader(false)
            } catch (error) {
                setShowAnalysisLoader(false)

            }
        } else {
            setAoiError('Area of intrest required.')
            setShowAnalysisLoader(false)
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // getAllPatternLife()
        // setTimeout(() => {
        //     localStorage.setItem('show_life_model_','0')
        //     localStorage.setItem('show_footprint_model_','0')
        //     setProjectName('')
        //     setSelectedDays('')
        //     setEndDate()
        //     setStartDate()
        //     setFrequencyValue('')
        //     setAoiType_('select')
        //     setFootprintData({})
        //     localStorage.removeItem('footprint_data')
        //     backHandler()
        //     localStorage.setItem('get_all_life',0)
        // }, 2000);
    };


    return (
        <>  
            {/*Project name:*/}
            <Box className="vaultBox" mt={1}>
                <Box display="flex" flexDirection="column">
                    <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                        <span>Project name:</span>
                    </Box>
                    <div className="searchBox">
                        <input
                            className="searchInput"
                            type="text"
                            placeholder="Name"
                            value={projectName}
                            onChange={(e)=>{nameHandler(e)}}
                        />
                    </div>
                </Box>
            </Box>
            
            {/*Period / Frequency: */}
            <Box className='footprint_period'>
                <Box className="vaultBox" mt={1}>
                    <Box width={"100%"}>
                        <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                            <span>Period:</span>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <input type="date" value={startDate} class="dateInputStyle" placeholder="Start" onChange={(event) => handleDateChange(event, 'start')} />
                            <input type="date" value={endDate} class="dateInputStyle" placeholder="End" onChange={(event) => handleDateChange(event, 'end')} />  
                        </Box>
                    </Box>
                </Box>
                <Box className="vaultBox frequency" mt={1}>
                    <Box width={"100%"}>
                        <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                            <span>Frequency:</span>
                        </Box>
                        <Box display="flex" justifyContent="space-between" className="frequency_container">
                            <input type="text" value={frequencyValue} onChange={(e)=>{frequencyValueHandler(e)}} className='frequency_value' placeholder="Value"/>
                            {footPrintFrequencyDays()}
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/*Area Of Interest */}
            <Box className="interestSection">
                <Box className="subTitle" mb={0.5}>
                    <span>Area of interest:</span>
                </Box>
            <Box className="aoi_container">
                <Box className="aoi_box">
                {footPrintAoi()}
                </Box>
                <Box className="aoi_drawBox" onClick={()=>{drawPolygonHandler()}}>
                <Box className="aoi_drawBoxbtn">
                    <img src={polyline}/>
                </Box>
                </Box>
            </Box>
            </Box>

            {/* Analysis section */}
            <Box className="cartSection">
                <Box className="subTitle" mt={1}>
                    <span class="textStyle w100">Price for full period:</span>
                    <span>Total: 65000 Stellars</span>
                </Box>
                {
                    showAnalysisLoader ?
                    <Box className="addToCartButton"><CircularProgress/></Box>
                    :
                    <Box className="addToCartButton" onClick={(e)=>{startAnalysis(e)}}>Start Analysis</Box>
                }
            </Box>
            { aoiError&&
                <Box className="error_msg analyze">
                {aoiError}
                </Box>
            }

            {/* <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid container style={{justifyContent:'center',paddingTop:'38px'}} >
                        <Grid item xs={12} style={{textAlign:"start",padding:2}}>
                            <Grid container>
                                <Grid item xs={4} style={{textAlign:"left"}}>
                                    <Typography component={"span"}>Number of buildings:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign:"left",paddingLeft:"10px"}}>
                                    <Typography component={"span"}>{noOfBuilding_}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"start",padding:2}}>
                            <Grid container>
                                <Grid item xs={4} style={{textAlign:"left"}}>
                                    <Typography component={"span"}>Area of select AOI:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign:"left",paddingLeft:"10px"}}>
                                    <Typography component={"span"}>{aoiArea_}km<sup>2</sup></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"start",padding:2}}>
                            <Grid container>
                                <Grid item xs={4} style={{textAlign:"left"}}>
                                    <Typography component={"span"}>Building area:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{textAlign:"left",paddingLeft:"10px"}}>
                                    <Typography component={"span"}>{noOfTotalAreaBuilding_}km<sup>2</sup></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog> */}
        </>
    )
}