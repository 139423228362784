import React,{useState} from 'react'
import { Box,CircularProgress } from '@material-ui/core'
import {patternLifeData} from './PatternLifeData'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FootprintModel from './FootprintModel'

export default function LifeModel({backToLifeHandler_,orderImageHandler,getAllPatternLife_}) {

    // +++++++++++++++ Pattern Life State +++++++++++++++++++
    const [patternLifeData_,setPatternLifeData_]  = useState(patternLifeData)

    // +++++++++++++++ Loader State +++++++++++++++++++
    const [showLoader,setVaultLoader] = useState(false)

    // +++++++++++++++ Loader State +++++++++++++++++++
    let showFootPrintModel_ = localStorage.getItem('show_footprint_model_') || '0'
    let show_foot_print_mode = true
    if (showFootPrintModel_ == '0'){
        show_foot_print_mode = false
    }
    const [showFootPrintModel,setShowFootPrintModel] = useState(show_foot_print_mode)
    
    let footPrintModel_ = localStorage.getItem('select_footPrint_data') || '{}'
    footPrintModel_ = JSON.parse(footPrintModel_)
    const [selectFootPrintModel,setFootPrintModel] = useState(footPrintModel_)




    // +++++++++++++++ Model Vault Search Handler +++++++++++++++++++ 
    const searchHandler = (e) =>{
        const {value} = e.target
        if (value != '' ||  value != ' '){
            setVaultLoader(true)
            const filteredData  = patternLifeData.filter((data,)=>{
                const regex = new RegExp(`${value}`, 'i');
                return (
                regex.test(data.name) ||
                regex.test(data.description) ||
                data.tag.some((tag) => regex.test(tag))
                ); 
            })
            setTimeout(()=>{
                setVaultLoader(false)
                setPatternLifeData_(filteredData)
            },600)
            
        } else {
            setPatternLifeData_(patternLifeData)
        }
    }

    // +++++++++++++++ Pattren Model Vault +++++++++++++++++++ 
    const vaultHandler = (modelData) =>{
        let poupname = modelData.name.replace(' Detection','')
        localStorage.setItem('popup_text_name',poupname)
        localStorage.removeItem("archive_coordinator")
        setShowFootPrintModel(true)
        localStorage.setItem('show_footprint_model_','1')
        localStorage.setItem('select_footPrint_data',JSON.stringify(modelData))
        setFootPrintModel(modelData)
        localStorage.setItem('map_zoom','12')
        localStorage.setItem('show_footprint_layer','1')
        localStorage.removeItem('aoi_selected_image')
    }

    const pattrenModelVault = () =>{
        return(
        <>
            { 
                patternLifeData_.map((item_,index)=>{
                    const vaultTags = item_.tag
                    return(
                    <Box key={index} className={"vaultContainerBox" + (1 == item_.id? " model_active":"")} mt={1} onClick={()=>{vaultHandler(item_)}} >
                        <Box key={index+1}>
                        {/* First Layer */}
                        <Box className="imageLayer">
                            {/* Image */}
                            <img
                            src={item_.src}
                            alt="model-image"
                            style={{
                                width: "150px",
                                height: "138px",
                                borderRadius: "5px",
                            }}
                            />
                        </Box>
                        {/* Second Layer */}
                        <Box className="xtetLayer">
                            <span className="subHead">{item_.name}</span>
                            <span className="subContent">{item_.description}</span>
                            <Box className="vaultTags">
                            {
                                vaultTags.map((tag,index)=>{
                                return(
                                    <span key={index} className="labelSpan">
                                    <span className="labelTxt">{tag}</span>
                                    </span>
                                )
                                })
                            }
                            </Box>
                        </Box>
                        </Box>
                    </Box>
                    )
                })
            }
        </>
        )
    }

    // +++++++++++++++ Back to Pattren Model Vault +++++++++++++++++++
    const backHandler = () => {
        localStorage.setItem('show_footprint_model_','0')
        setShowFootPrintModel(false)
        orderImageHandler(false)
        localStorage.removeItem('aoi_selected_image')
    }


    return (
        
        <>
            { !showFootPrintModel ? 
                <>
                    <Box display="flex"mt={0} m={1.25} className='analyzeLife' flexDirection="row">
                        <ArrowBackIosIcon onClick={(e)=>{backToLifeHandler_()}} className='backArrow'/>
                        <div className="titleContainer footprint_text">
                            <span className="title">Patten of life Models</span>
                        </div>
                    </Box>
                    <Box className="vaultBox" mt={1}>
                        <Box display="flex" flexDirection="column">
                            <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                                <span>Models Vault:</span>
                            </Box>
                            <div className="searchBox">
                                <input
                                className="searchInput"
                                type="text"
                                placeholder="Search"
                                onChange={(e)=>{searchHandler(e)}}
                                />
                                <button className="searchButton">
                                <svg
                                    className="svgImg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M14.3333 15.5L9.08333 10.25C8.66667 10.5833 8.1875 10.8472 7.64583 11.0417C7.10417 11.2361 6.52778 11.3333 5.91667 11.3333C4.40278 11.3333 3.12153 10.809 2.07292 9.76042C1.02431 8.71181 0.5 7.43056 0.5 5.91667C0.5 4.40278 1.02431 3.12153 2.07292 2.07292C3.12153 1.02431 4.40278 0.5 5.91667 0.5C7.43056 0.5 8.71181 1.02431 9.76042 2.07292C10.809 3.12153 11.3333 4.40278 11.3333 5.91667C11.3333 6.52778 11.2361 7.10417 11.0417 7.64583C10.8472 8.1875 10.5833 8.66667 10.25 9.08333L15.5 14.3333L14.3333 15.5ZM5.91667 9.66667C6.95833 9.66667 7.84375 9.30208 8.57292 8.57292C9.30208 7.84375 9.66667 6.95833 9.66667 5.91667C9.66667 4.875 9.30208 3.98958 8.57292 3.26042C7.84375 2.53125 6.95833 2.16667 5.91667 2.16667C4.875 2.16667 3.98958 2.53125 3.26042 3.26042C2.53125 3.98958 2.16667 4.875 2.16667 5.91667C2.16667 6.95833 2.53125 7.84375 3.26042 8.57292C3.98958 9.30208 4.875 9.66667 5.91667 9.66667Z"
                                    fill="#0E62E8"
                                    />
                                </svg>
                                </button>
                            </div>
                            <Box className={"vaultContainer" + (showLoader ? " vault_loader":"")}>
                                { showLoader ? 
                                    <Box className="loader_container">
                                        <CircularProgress className="loaderC"/>
                                    </Box> 
                                :
                                <>
                                    {pattrenModelVault()}
                                </>
                                }
                            </Box>
                        </Box>
                    </Box>
                </>
            :
            <>  
                <Box display="flex"mt={0} m={1.25} className='analyzeLife' flexDirection="row">
                    <ArrowBackIosIcon onClick={()=>{backHandler()}} className='backArrow'/>
                    <div className="titleContainer footprint_text">
                        <span className="title">{selectFootPrintModel.footPrint} Footprint</span>
                    </div>
                </Box>
                <FootprintModel  backHandler={backHandler} data={selectFootPrintModel} getAllPatternLife={getAllPatternLife_} orderImageHandler={orderImageHandler}/>
            </>
            }
        </>

    )
}
