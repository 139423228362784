export const archiveData = {
    opticalData:[
        {   
            minresolution:0,
            maxresolution:0.50,
            data:[
                {
                    collection:"pneo",
                    name:"Pléiades Neo",
                    hostName: "oneatlas",
                    setting:[
                        {
                            name:"Analytic",
                            productId:"6f722fb5-0323-4c97-9b1e-22870325b8db",
                            collection_:"pneo",
                        },
                        {
                            name:"Display",
                            productId:"17745de8-6e7d-4751-99cd-3f8e9e9d290e",
                            collection_:"pneo",
                        },

                    ]
                },
                {
                    collection:"phr",
                    name:"Pléiades",
                    hostName:"oneatlas",
                    setting:[
                        {
                            name:"Analytic",
                            productId:"4f1b2f62-98df-4c74-81f4-5dce45deee99",
                            collection_:"phr",
                        },
                        {
                            name:"Pansharpened reflectance",
                            productId:"df4ca029-2f6f-432b-98d5-ef2f5d70baee",
                            collection_:"phr",
                        },
                        {
                            name:"Display",
                            productId:"647780db-5a06-4b61-b525-577a8b68bb54",
                            collection_:"phr",
                        },

                    ]
                },
                {
                    collection:"beijing-3a",
                    name:"Beijing-3A",
                    hostName:"21at",
                    setting:[
                        {
                            name:"Mono 16-bit",
                            productId:"e080274b-6cfb-4a89-bc12-64fd4895b3c2",
                            collection_:"beijing-3a",
                        },
                        {
                            name:"Stereo 16-bit",
                            productId:"ab6fbe7d-2eb6-4f84-8337-418db71d8e75",
                            collection_:"beijing-3a",
                        },
                    ]
                },
            ]
        },
        {   
            minresolution:0.50,
            maxresolution:2,
            data:[
                {
                    collection:"spot",
                    name:"SPOT",
                    hostName:"oneatlas",
                    setting:[
                        {
                            name:"Analytic",
                            productId:"b1f8c48e-d16b-44c4-a1bb-5e8a24892e69",
                            collection_:"spot",
                        },
                        {
                            name:"Pansharpened reflectance",
                            productId:"df4ca029-2f6f-432b-98d5-ef2f5d70baee",
                            collection_:"spot",
                        },
                        {
                            name:"Display",
                            productId:"acc3f9a4-b622-49c1-b1e1-c762aa3e7e13",
                            collection_:"spot",
                        },
                
                    ]
                },
                {
                    collection:"triplesat",
                    name:"TripleSat",
                    hostName:"21at",
                    setting:[
                        {
                            name:"Level 2 (8-bit)",
                            productId:"2398d8f5-5f7f-4596-884d-345c0b07af149",
                            collection_:"triplesat",
                        },
                        {
                            name:"Level 2 (16-bit)",
                            productId:"37c26f4d-f6a9-47c9-ae4d-a095569ab8bc",
                            collection_:"triplesat",
                        },
                    ]
                },
            ]
        },
        {   
            minresolution:2,
            maxresolution:20,
            data:[
                {
                    collection:"sentinel-2",
                    name:"Sentinel-2",
                    hostName:"earthsearch-aws",
                    setting:[
                        {
                            name:"Level-2A",
                            productId:"c3de9ed8-f6e5-4bb5-a157-f6430ba756da",
                            collection_:"sentinel-2",
                        },
                    ]
                }
            ]
        },
        {   
            minresolution:20,
            maxresolution:null,
            data:[
                {
                    collection:"landsat-8",
                    name:"Landsat 8",
                    hostName:"earthsearch-aws",
                    setting:[
                        {
                            name:"Level-2SP",
                            productId:"0d6796c4-9e0b-44e8-bb8f-3d0bc9794382",
                            collection_:"landsat-8",
                        },
                    ]
                }
            ]
        }
    ],
    sarData:[
        {   
            minresolution:0,
            maxresolution:0.50,
            data:[
                {
                    collection:"capella-slc",
                    name:"SLC",
                    hostName:"capellaspace",
                    productId:"1f2b0d7f-d3e2-4b3d-96b7-e7c184df7952",
                    collection_:"capella-slc",
                    thumbnail:"sarcover.png",  
                },
                {
                    collection:"capella-gec",
                    name:"GEC",
                    productId:"96072809-d820-4cf9-86dd-d3bff3337c35",
                    hostName: "capellaspace",
                    collection_:"capella-gec",
                    thumbnail:"sarcover.png",
                },
                {
                    collection:"capella-geo",
                    name:"GEO",
                    hostName: "capellaspace",
                    productId:"d66facaa-533f-49a2-849a-c2910ac9dd31",
                    collection_:"capella-geo",
                    thumbnail:"sarcover.png",
                },
                {
                    collection:"capella-sicd",
                    name:"SICD",
                    hostName: "capellaspace",
                    productId:"8b0aed07-c565-4bf9-b719-401e692de4a6",
                    collection_:"capella-sicd",
                    thumbnail:"sarcover.png",
                }
            ]
        }  
    ],
    thermal:[
        {   
            minresolution:10,
            maxresolution:20,
            data:[
                {
                    collection:"capella-slc",
                    setting:[
                        {
                            name:"SLC",
                            productId:"1f2b0d7f-d3e2-4b3d-96b7-e7c184df7952",
                            collection_:"capella-slc",
                        },
                    ]
                },
                {
                    collection:"capella-gec",
                    setting:[
                        {
                            name:"GEC",
                            productId:"96072809-d820-4cf9-86dd-d3bff3337c35",
                            collection_:"capella-gec",
                        },
                    ]
                },
                {
                    collection:"capella-geo",
                    setting:[
                        {
                            name:"GEO",
                            productId:"d66facaa-533f-49a2-849a-c2910ac9dd31",
                            collection_:"capella-geo",
                        },
                    ]
                },
                {
                    collection:"capella-sicd",
                    setting:[
                        {
                            name:"SICD",
                            productId:"8b0aed07-c565-4bf9-b719-401e692de4a6",
                            collection_:"capella-sicd",
                        },
                    ]
                }
            ]
        }  
    ]
}
