import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Spin, Card } from 'antd';
import { NavLink, Redirect } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import logo from '../images/logo.png';



class Login extends React.Component {
  render () {
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = (
        <p>{this.props.error.message}</p>
      );
    }

    const onFinish = (values) => {
      this.props.onAuth(values.email, values.password);
      this.props.history.push('/');
    };
    
    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return (
      this.props.isAuthenticated ?

        <Redirect to='/'/>

      :  

        <div className="container" style={{}}>
          {errorMessage}
          {
            this.props.loading ?
          
            <Spin tip="Loading..."/>

            :
            <Card style={{textAlign: "center", width: "50%", margin: "auto", border: 'none', backgroundColor: '#000f16'}}>
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >

                {/* <Image
                  width={150}
                  src={logo}
                /> */}
                <img width={150} src={logo} alt="main logo"/>
                <br />
                <br />
                
                <Form.Item
                  name="email"
                  rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email" style={{borderRadius: '8px'}}/>
                </Form.Item>
      
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password size="large" placeholder="Password" style={{borderRadius: '8px'}}/>
                </Form.Item>
            
                <Form.Item>
                  <NavLink style={{color: 'white'}} to='/' block>Forgot Password?</NavLink>
                  <br />
                  <br />
                  <Button 
                    type="primary" 
                    htmlType="submit" 
                    size="large" 
                    style={{backgroundColor: '#015553', borderColor: '#015553', borderRadius: '8px'}} 
                    block>Login
                  </Button>
                  <br />
                  <br />
                  <NavLink style={{color: 'white'}} to='/signup' block>Sign up</NavLink>
                </Form.Item>
              </Form>
            </Card>
          }
        </div>  
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.authLogin(email, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);