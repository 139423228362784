import { newImageryData } from "./OrderImageData"
import buildingDetection1 from "../../../images/bulding-detection-1.png"
import buildingDetection2 from "../../../images/bulding-detection-2.png"
import buildingDetection3 from "../../../images/bulding-detection-3.png"
import buildingDetectionModel1 from "../../../images/model-image-1.png"
const aoi           = newImageryData.aoi
const aoiTypeValue  = newImageryData.aoiTypeValue
export const AiModelData = [
    {   
        id:1,
        rasterId:"hassanms.adm_new_24",
        shpfile:"houses.zip",
        lat:24.434092514791253,
        log:54.37896460258118,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection1,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            modelsrc:buildingDetectionModel1,
            modelTag:[
                "Tag",
                "Tag",
                "Tag"
            ],
            content:"Lorem ipsum dolor sit amet consectetur. Ut nibh vestibulum erat elit. Cursus a aliquam odio mi tincidunt. Imperdiet non eleifend at lorem quam justo gravida pulvinar. At ultrices et donec arcu et tellus blandit maecenas. Vestibulum odio ornare amet adipiscing netus nisi sed ut mi. Duis molestie ultrices nunc ornare molestie cursus nunc dictumst. Nulla adipiscing morbi ut nisi vitae pulvinar sed suscipit arcu. Nam enim adipiscing accumsan nulla est cursus cras elementum. Rhoncus scelerisque aliquet posuere platea. Urna augue condimentum libero tincidunt tempus tortor interdum suscipit duis.",
            imageOtion:[
                {   
                    id:1,
                    name:"Option 1",
                    rasterId:"hassanms.adm_new_24",
                    shpfile:"houses.zip",
                    lat:24.432524759418115,
                    log:54.36999626110498,
                },
                {   
                    id:2,
                    name:"Option 2",
                    rasterId:"hassanms.new_42",
                    shpfile:"shape_42.zip",
                    lat:24.433467365288156,
                    log:54.469773470989324,
                },
                {   
                    id:3,
                    name:"Option 3",
                    rasterId:"hassanms.new_43",
                    shpfile:"shape_43.zip",
                    lat:24.43721821580547,
                    log:54.58221167289039,
                },
                {   
                    id:4,
                    name:"Option 4",
                    rasterId:"hassanms.new_58",
                    shpfile:"shape_58.zip",
                    lat:24.33233569672214,
                    log:54.40241716049134,
                },
                {   
                    id:5,
                    name:"Option 5",
                    rasterId:"hassanms.new_59",
                    shpfile:"shape_59.zip",
                    lat:24.327017548251142,
                    log:54.51811692855525,
                },
            ],
            aoi,
            aoiTypeValue,
        }
    },
    {   
        id:2,
        rasterId:"hassanms.new_58",
        shpfile:"car_new58.zip",
        lat:24.341538137472096,
        log:54.47506886971215,
        name:"Car Detection",
        description:"50 Stellars / km2",
        src:buildingDetection2,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            modelsrc:buildingDetectionModel1,
            modelTag:[
                "Tag",
                "Tag",
                "Tag"
            ],
            content:"Lorem ipsum dolor sit amet consectetur. Ut nibh vestibulum erat elit. Cursus a aliquam odio mi tincidunt. Imperdiet non eleifend at lorem quam justo gravida pulvinar. At ultrices et donec arcu et tellus blandit maecenas. Vestibulum odio ornare amet adipiscing netus nisi sed ut mi. Duis molestie ultrices nunc ornare molestie cursus nunc dictumst. Nulla adipiscing morbi ut nisi vitae pulvinar sed suscipit arcu. Nam enim adipiscing accumsan nulla est cursus cras elementum. Rhoncus scelerisque aliquet posuere platea. Urna augue condimentum libero tincidunt tempus tortor interdum suscipit duis.",
            imageOtion:[
                {   
                    id:1,
                    name:"Option 1",
                    rasterId:"hassanms.new_43",
                    shpfile:"car_new43.zip",
                    lat:24.43721821580547,
                    log:54.58221167289039,
                },
                {   
                    id:2,
                    name:"Option 2",
                    rasterId:"hassanms.new_58",
                    shpfile:"car_new58.zip",
                    lat:24.341538137472096,
                    log:54.47506886971215,
                },
                {   
                    id:3,
                    name:"Option 3",
                    rasterId:"hassanms.new_59",
                    shpfile:"car_new59.zip",
                    lat:24.345160901118653,
                    log:54.5792283787538,
                },
            ],
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:3,
        rasterId:"hassanms.palm_tree_80",
        lat:24.09630,
        log:55.20447,
        name:"Palm Detection",
        description:"50 Stellars / km2",
        src:buildingDetection3,
        layerId:"palamdwrtdftgtt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            modelsrc:buildingDetectionModel1,
            modelTag:[
                "Tag",
                "Tag",
                "Tag"
            ],
            content:"Lorem ipsum dolor sit amet consectetur. Ut nibh vestibulum erat elit. Cursus a aliquam odio mi tincidunt. Imperdiet non eleifend at lorem quam justo gravida pulvinar. At ultrices et donec arcu et tellus blandit maecenas. Vestibulum odio ornare amet adipiscing netus nisi sed ut mi. Duis molestie ultrices nunc ornare molestie cursus nunc dictumst. Nulla adipiscing morbi ut nisi vitae pulvinar sed suscipit arcu. Nam enim adipiscing accumsan nulla est cursus cras elementum. Rhoncus scelerisque aliquet posuere platea. Urna augue condimentum libero tincidunt tempus tortor interdum suscipit duis.",
            imageOtion:[
                {   
                    id:1,
                    name:"Option 1",
                    rasterId:"hassanms.palm_tree_80",
                    shpfile:"palm_detection.zip",
                    lat:24.09630,
                    log:55.20447,
                },
            ],
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:4,
        rasterId:"hassanms.adm_new_24",
        lat:24.434092514791253,
        log:54.37896460258118,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection2,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            modelsrc:buildingDetectionModel1,
            modelTag:[
                "Tag",
                "Tag",
                "Tag"
            ],
            content:"Lorem ipsum dolor sit amet consectetur. Ut nibh vestibulum erat elit. Cursus a aliquam odio mi tincidunt. Imperdiet non eleifend at lorem quam justo gravida pulvinar. At ultrices et donec arcu et tellus blandit maecenas. Vestibulum odio ornare amet adipiscing netus nisi sed ut mi. Duis molestie ultrices nunc ornare molestie cursus nunc dictumst. Nulla adipiscing morbi ut nisi vitae pulvinar sed suscipit arcu. Nam enim adipiscing accumsan nulla est cursus cras elementum. Rhoncus scelerisque aliquet posuere platea. Urna augue condimentum libero tincidunt tempus tortor interdum suscipit duis.",
            imageOtion:[],
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:5,
        rasterId:"hassanms.adm_new_24",
        lat:24.434092514791253,
        log:54.37896460258118,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection2,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            modelsrc:buildingDetectionModel1,
            modelTag:[
                "Tag",
                "Tag",
                "Tag"
            ],
            content:"Lorem ipsum dolor sit amet consectetur. Ut nibh vestibulum erat elit. Cursus a aliquam odio mi tincidunt. Imperdiet non eleifend at lorem quam justo gravida pulvinar. At ultrices et donec arcu et tellus blandit maecenas. Vestibulum odio ornare amet adipiscing netus nisi sed ut mi. Duis molestie ultrices nunc ornare molestie cursus nunc dictumst. Nulla adipiscing morbi ut nisi vitae pulvinar sed suscipit arcu. Nam enim adipiscing accumsan nulla est cursus cras elementum. Rhoncus scelerisque aliquet posuere platea. Urna augue condimentum libero tincidunt tempus tortor interdum suscipit duis.",
            imageOtion:[],
            aoi,
            aoiTypeValue
        }
    },
    {   
        id:6,
        rasterId:"hassanms.adm_new_24",
        lat:24.434092514791253,
        log:54.37896460258118,
        name:"Building Detection",
        description:"50 Stellars / km2",
        src:buildingDetection2,
        layerId:"dwrtdftgt",
        tag:[
            "10cm",
            "30cm",
            "OPTICAL"
        ],
        modelData:{
            modelsrc:buildingDetectionModel1,
            modelTag:[
                "Tag",
                "Tag",
                "Tag"
            ],
            content:"Lorem ipsum dolor sit amet consectetur. Ut nibh vestibulum erat elit. Cursus a aliquam odio mi tincidunt. Imperdiet non eleifend at lorem quam justo gravida pulvinar. At ultrices et donec arcu et tellus blandit maecenas. Vestibulum odio ornare amet adipiscing netus nisi sed ut mi. Duis molestie ultrices nunc ornare molestie cursus nunc dictumst. Nulla adipiscing morbi ut nisi vitae pulvinar sed suscipit arcu. Nam enim adipiscing accumsan nulla est cursus cras elementum. Rhoncus scelerisque aliquet posuere platea. Urna augue condimentum libero tincidunt tempus tortor interdum suscipit duis.",
            imageOtion:[],
            aoi,
            aoiTypeValue
        }
    }
]