import React, { useState } from 'react';
import axios from 'axios';
import "../styles/DataSettingForm.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import APIs from '../Api';


function DataSettingsForm({ flagIn, flagOut, extent, isVisible, deleteData, modelClose,changePinType,resetPins,enabelStartProcess }) {

    const [imageDownlaodProcess, setImageDownlaodProcess] = useState(false)
    const [error, setError] = useState('')
    const [selectedOption, setSelectedOption] = useState('green');
    const [downloadProcess,setdownloadProcess] = useState(false)
    const [downloadFileSpinner,setDownloadFileSpinner] = useState(false)
    const [uuid,setUuid] = useState('')
    // const [opacity, setOpacity] = useState(0.6);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        changePinType(event.target.value)
    };


    const downloadCreatedSagment = () => {
        setImageDownlaodProcess(true)
        let flagInOutData = ''
        if (flagOut.length > 0) {
            flagInOutData = JSON.stringify(flagOut.map(item => [item.longitude, item.latitude]))
        }
        let flagInData = JSON.stringify(flagIn.map(item => [item.longitude, item.latitude]))
        let drawerBbox = extent
        const uuid_u = generateUniqueCode()
        setUuid(uuid_u)
        const data = {
            "path_to_tiff": `satellite${uuid_u}.tif`,
            "bbox": drawerBbox,
            "flag_in": flagInData,
            "flag_out": flagInOutData,
            'uuid':uuid_u
        };
        
        axios.post(APIs.PROCESS_IMAGE, data)
            .then(res => {
                if (res.status == 200) {
                    setImageDownlaodProcess(false)
                    setdownloadProcess(true)
                }
                else {
                    console.log(res,'res PROCESS_IMAGE');
                    setError('please try again later!')
                }
            }).catch((err) => {
                console.log(err,'err PROCESS_IMAGE');
                setImageDownlaodProcess(false)
                setError('please try again later!')
            })
    };

    const fileDownloader = (filePath) => {
        try {
            filePath.map((data,index)=>{
                fetch(data.path, { method: 'GET' }).then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                }).then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    setImageDownlaodProcess(false)
    
                }).catch((error) => {
                    console.log(error," fetch fileDownloader");
                    setImageDownlaodProcess(false)
                })
            })
        } catch (error) {
            console.log(error,"fileDownloader");
        }
        deleteData()
        modelClose()
        resetPins()
    };

    const restPinHandler = () =>{
        resetPins()
    }

    const downloadFiles = () =>{
        setDownloadFileSpinner(true)
        const data = {
            'uuid':uuid
        };
        axios.post(APIs.DOWNLOAD_FILES,data)
            .then(res => {
                if (res.status == 200) {
                    const fileData = res.data
                    if (fileData.data.length > 0){
                        const filePath = []
                        fileData.data.map((item,index)=>{
                            const fileUrl   = `${APIs.MODEL_DOMIN}/${item}`
                            const parts     = item.split('\\');
                            const name      = parts[parts.length - 1];
                            filePath.push({name:name,path:fileUrl})
                        })
                        console.log(filePath);
                        fileDownloader(filePath)
                        setDownloadFileSpinner(false)
                        setImageDownlaodProcess(false)
                        setdownloadProcess(false)
                        setDownloadFileSpinner(false)
                        setUuid("")
                    } else{
                        setTimeout(() => {
                            downloadFiles()
                        }, 30000);
                    }
                }
                else {
                    setDownloadFileSpinner(false)
                    setImageDownlaodProcess(false)
                    setdownloadProcess(false)
                    setDownloadFileSpinner(false)
                    setUuid("")
                    console.log(res,'error:- res downloadFiles');
                }
            }).catch((err) => {
                console.log(err,'error:- err downloadFiles');
                setDownloadFileSpinner(false)
                setImageDownlaodProcess(false)
                setdownloadProcess(false)
                setDownloadFileSpinner(false)
                setUuid("")
            })
    }

    const generateUniqueCode = () => {
        const timestamp = new Date().getTime().toString(36);
        const randomString = Math.random().toString(36).substr(2, 6);
        return timestamp + randomString;
    };
    
    return (
        <>
            {
                isVisible &&
                <div className="container dataSettingForm">
                    <div className="formColumn">     
                        <div className="formRow">
                            <label className='custom_label'>Class Type:</label>
                            <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-label="option"
                                name="option"
                                value={selectedOption}
                                onChange={handleChange}
                                color="primary"
                            >
                                <FormControlLabel
                                value="green"
                                control={<Radio />}
                                label="Foreground"
                                />
                                <FormControlLabel
                                value="red"
                                control={<Radio />}
                                label="Background"
                                />
                            </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className='segment_count'>
                        <span>Foreground #:</span>
                        <span><input type="text" value={flagIn.length} readOnly={true} /></span>
                        <span>Background #:</span>
                        <span><input type="text"  value={flagOut.length} readOnly={true} /></span>
                    </div>

                    <div className='segment_setting'>
                        {
                            imageDownlaodProcess ?
                                <button className="processBtn"><CircularProgress/></button>
                                
                            :
                                <button className="submitBtn"  disabled={enabelStartProcess} onClick={()=>{downloadCreatedSagment()}}>Start process</button>
                        }
                        <button className="submitBtn" onClick={restPinHandler}>Reset</button>
                    </div>
                    { downloadProcess && 
                        <div className='download_setting'>
                            <button className="submitBtn" onClick={()=>{!downloadFileSpinner&&downloadFiles()}}>
                                {downloadFileSpinner ? <CircularProgress/>:"Download"} 
                            </button>
                        </div>
                    }
                    <div className='guide'>
                        <h4>Instructions  *</h4>
                        <span>
                            Firstly, you have to select the marker option from the bar. It turns green. Now you can pin the areas. You can change red locator for background from the side modal.  To select the area , you can choose polygon after disabling the marker option. Now you can draw over the area to select it.
                        </span>
                    </div>
                </div>
            }
        </>
    );
}

export default DataSettingsForm;
