import React,{useState,useEffect,useCallback} from "react";
import "./modelDetection.css";
import { Box,CircularProgress } from "@material-ui/core";
import polyline from '../../../images/polyline.png'
import { useSelector, useDispatch } from 'react-redux';

function ModelDetection({data,initData,orderImageHandler_,analyzeOrderHandler_,hideAiArchiveHandler_}) 
{
  // +++++++++++++++ Force Update State +++++++++++++++++++
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const {analyseLoader,analyseError}         = useSelector((state) => state);

  let   modelData_            =  data.filter((item)=>{return item.id == initData.id})
  let filterModelData_        = modelData_
  let   oldShowModelData      = localStorage.getItem('show_ai_models') || "{}"
  if (modelData_.length>0){
    modelData_ = modelData_[0]
    oldShowModelData  = JSON.parse(oldShowModelData)
    let data = modelData_
    data.aoiIsSeletced = false
    data.dataShow      = false
    data.showRaster    = true
    let modelInit = localStorage.getItem('ai_model_init')
    if (modelInit == '1'){
      orderImageHandler_(data)
      localStorage.removeItem('ai_model_init')
    } else {
      if (modelData_.id != oldShowModelData.id){
        orderImageHandler_(data)
      }
    }
  }


  // +++++++++++++++ Model Detection Data +++++++++++++++++++ 
  const modelData =  modelData_.modelData
  const tags      =  modelData.modelTag

  // +++++++++++++++ Model Detection Image State +++++++++++++++++++ 
  const imageData = localStorage.getItem('ai_detection_image') || "select"
  const [imageSelectedData,setImageSelectedData] = useState(imageData)

  // +++++++++++++++ Model Detection Aoi State +++++++++++++++++++ 
  const aoiData = localStorage.getItem('ai_detection_aoi') || "select"
  const [aoiSelectedData,setAoiDataSelectedData] = useState(aoiData)

  // +++++++++++++++ Order Data State +++++++++++++++++++ 
  const [orderData,setOrderData] = useState({})

  // +++++++++++++++ Contaner Class State +++++++++++++++++++ 
  const contanerClass_ = localStorage.getItem('ai_contaner_class') || ""
  const [contanerClass,setContanerClass] = useState(contanerClass_)

  // +++++++++++++++ use Effect +++++++++++++++++++ 
  useEffect(()=>{
    // setImageSelectedData('select')
    // setAoiDataSelectedData('select')
    // setOrderData({})
  },[initData])



  // +++++++++++++++ capitalize First Letter +++++++++++++++++++ 
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }


  // +++++++++++++++ Image +++++++++++++++++++ 
  const imageHandler = (e,imageOption) => {
    const {value} = e.target
    if (value !='select'){
      let imageObject = imageOption.filter((item_)=>{
        return item_.id == value
      })
      imageObject[0].aoiIsSeletced  = true
      imageObject[0].dataShow       = false
      imageObject[0].showRaster     = true
      imageObject[0].removeRaster   = false
      localStorage.setItem('map_zoom','14')
      orderImageHandler_(imageObject[0])
      setImageSelectedData(value)
      localStorage.setItem('ai_detection_image',value)
      localStorage.setItem('ai_detection_aoi','select')
      setAoiDataSelectedData('select')
      localStorage.setItem('hide_ai_archive',1)
      setContanerClass('activate_detection')
      localStorage.setItem('ai_contaner_class','activate_detection')
    }
  }

  const image = () =>{
    const imageOption = modelData.imageOtion
    return(
      <select value={imageSelectedData} className="selectListStyle" onChange={(e)=>{imageHandler(e,imageOption)}}>
        <option value='select'>Choose an Image</option>
        {
          imageOption.map((option,index)=>{
            return(
              <option key={index} value={option.id}>{option.name}</option>
            )
          })
        }
      </select>
    )
  }


  // +++++++++++++++ Area Interest +++++++++++++++++++ 
  const aoiHandler = (e) => {
    const {value} = e.target
    if (value !='select'){
      localStorage.setItem('map_zoom','14')
      // setAoiDataSelectedData(value)
      localStorage.setItem('ai_detection_aoi',value)
      let data = modelData.aoiTypeValue[value]
      setOrderData({...orderData,...data})
      data.aoiIsSeletced = true
      data.dataShow = true
      orderImageHandler_(data)
      localStorage.setItem('ai_detection_image','select')
      setImageSelectedData('select')
      localStorage.setItem('hide_ai_archive',1)
      setContanerClass('activate_detection')
      localStorage.setItem('ai_contaner_class','activate_detection')
    }
  }

  const areaInterest = () => {
    const aoiOption = modelData.aoi
    return(
      <select className="selectListStyle" value={aoiSelectedData} onChange={(e)=>{aoiHandler(e)}}>
        <option value='select'>Choose an area</option>
        {
          aoiOption.map((option,index)=>{
            return(
              <option key={index} value={option.value}>{option.name}</option>
            )
          })
        }
      </select>
    )
  }


  // +++++++++++++++ Add To Cart Handler+++++++++++++++++++ 
  const addToCartHandler = () => {
    analyzeOrderHandler_()
  }


  const drawPolygonHandler = () => {
    localStorage.setItem('map_zoom','14')
    localStorage.setItem('hide_ai_archive',1)
    setContanerClass('activate_detection')
    localStorage.setItem('ai_contaner_class','activate_detection')
    hideAiArchiveHandler_()
    let aoiInit = localStorage.getItem('aoidraw_enable') || '1'
    let data = {}
    let aoiIsSeletced = true
    if (aoiInit == '0') {
      aoiInit = '1'
      aoiIsSeletced = false
    } else {
      aoiInit = '0'
    }
    data.aoiIsSeletced = aoiIsSeletced
    data.dataShow = false
    localStorage.setItem('aoidraw_enable', aoiInit)
    orderImageHandler_(data)
  }


  // +++++++++++++++ Return Jsx +++++++++++++++++++ 
  return (
    <div className={"modelDetailContainer " + (analyseError!=''? " errorModel ":"") + contanerClass } style={{marginLeft:"15px"}}>
      <div className="bodyLayout">
        <img
          src={modelData.modelsrc}
          alt="Background"
          className="backgroundImage"
        />
        <div class="modelTitleContainer">
          <span className="modelTitle">{capitalizeFirstLetter(modelData_.name)}</span>
          <span className="modelTitle-right">{data.description}</span>
        </div>
        <div className="tagContainer">
          {
            tags.map((tag,index)=>{
              return(
                <div className="tag">
                  <p className="tag-text">{tag}</p>
                </div>
              )
            })
          }
        </div>
        <div className="tag-description">
          <p className="dec_text">
            {modelData.content}
          </p>
        </div>
        
        <Box className="model_detection_section" >
          {/*Image*/}
          <Box className="interestSection">
            <Box className="subTitle" mb={0.5}>
              <span>Image:</span>
            </Box>
            <Box className="aoi_container">
              <Box className="aoi_box">
                {image()}
              </Box>
            </Box>
          </Box>

          {/*Area Of Interest */}
          <Box className="interestSection">
              <Box className="subTitle" mb={0.5}>
                <span>Area of interest:</span>
              </Box>
              <Box className="aoi_container aoiBox_">
                <Box className="aoi_box">
                  {areaInterest()}
                </Box>
                <Box className="aoi_drawBox" onClick={() => { drawPolygonHandler() }}>
                <Box className="aoi_drawBoxbtn">
                  <img src={polyline} />
                </Box>
              </Box>
              </Box>
            </Box>
        </Box>

        {/* Analyze section */}
        <Box className="">
          <Box className="cartSection">
          <Box className="subTitle" mt={1}>
            <span class="textStyle w100">Price per km2: 10 Stellars:</span>
            <span>Total: 2000 Stellars</span>
          </Box>
          <Box className="addToCartButton" onClick={()=>{addToCartHandler()}}>
            { analyseLoader
              ?
              <Box className="loader_container aiLoder">
                <CircularProgress className="loaderC"/>
              </Box>
            :
              "Analyze"
            }
          </Box>
          </Box>
          { analyseError&&
            <Box className="error_msg analyze">
              {analyseError}
            </Box>
          }
        </Box>
      </div>
    </div>
  );
}

export default ModelDetection;
