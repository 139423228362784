import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import BaseRouter from './routes';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import * as actions from './store/actions/auth';
import './App.css';
import Login from './containers/Login';
import Signup from './containers/Signup';
import Home from './components/Home';
import 'mapbox-gl/dist/mapbox-gl.css';

class App extends Component{

  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render () {
    return (
      <div>
        <Router>
          {/* <CustomLayout {...this.props}>
            <BaseRouter />
          </CustomLayout> */}
          <Route 
            exact path='/login' 
            render={() => (
              <Login {...this.props} />
            )}
          />
          {/* <Route 
            exact path='/login' 
            render={() => (
              <Login {...this.props} />
            )}
          /> */}
          <Route 
            exact path='/signup' 
            render={() => (
              <Signup {...this.props} />
            )}
            component={Signup} />
          <Route 
            exact path='/' 
            render={() => (
              <Home {...this.props} />
            )}
          />
          {/* <Route exact path='/search' component={SearchableMap} /> */}
        </Router>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
