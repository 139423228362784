import React from 'react';
import mapboxgl from 'mapbox-gl';
import * as config from '../config';
import MapboxCompare from 'mapbox-gl-compare';
import axios from 'axios';
import html2canvas from 'html2canvas';
import './SwipeStyle.css';
import { Slider, InputNumber, Row, Col, Button, Image } from 'antd';

mapboxgl.accessToken = config.REACT_APP_TOKEN;

const mapContainer = {
    position: "absolute",
    top: 64,
    right: 0,
    left: 100,
    bottom: 0
}

const map = {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%"
}

class Swipe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: 5,
            lat: 34,
            zoom: 2,
            threshold: 255,
            hideEdge: true,
            edgeFilename: null,
            edgeProcessing: false,
            hideChange: true,
            changeFilename: null,
            changeProcessing: false,
            hideMask: true,
            maskFileName: null,
            maskProcessing: false,
        };
    }

    onChangeValue = value => {
        this.setState({
          threshold: value,
        });
      };

    updateHideChange = () => {
        this.setState({hideChange: false})
        this.setState({changeProcessing: false})
    }

    updateChangeFilename = () => {
        var hev = document.getElementById('changeFileName').value
        this.setState({changeFilename: hev})
    }  
    
    updateHideEdge = () => {
        // var hev = document.getElementById('hideEdge').value
        // this.setState({hideEdge: hev})
        this.setState({hideEdge: false})
        this.setState({edgeProcessing: false})
    }  

    updateFilename = () => {
        var hev = document.getElementById('fileName').value
        // this.state.edgeFilename = hev
        this.setState({edgeFilename: hev})
    }

    updateHideMask = () => {
        this.setState({hideMask: false})
        this.setState({maskProcessing: false})
    }

    updateMaskFilename = () => {
        var hev = document.getElementById('maskFileName').value
        this.setState({maskFilename: hev})
    }  

    maskProcess = () => {
        this.setState({maskProcessing: true});

        html2canvas(document.getElementById('before')).then(function (canvas) { 
            var beforeData = canvas.toDataURL()

            html2canvas(document.getElementById('after')).then(function (canvas) {
                var afterData = canvas.toDataURL()
                
                axios.post('http://localhost:8000/maskchange/', {
                    beforeData, afterData
                })
                .then(res => {
                    alert("Mask Change Detection Processing Successful")

                    var hideMask = document.getElementById('hideMask')
                    hideMask.value = false
                    var event = new MouseEvent('click', {
                        'view': window, 
                        'bubbles': true, 
                        'cancelable': false
                    })
                    hideMask.dispatchEvent(event)

                    var fileName = document.getElementById('maskFileName')
                    fileName.value = res.data
                    fileName.dispatchEvent(event)
                })
                .catch((err) => console.log(err))
            })
        })
    }

    changeProcess = () => {
        this.setState({changeProcessing: true})

        html2canvas(document.getElementById('before')).then(function (canvas) { 
            var beforeData = canvas.toDataURL()

            html2canvas(document.getElementById('after')).then(function (canvas) {
                var afterData = canvas.toDataURL()

                var threshold = document.getElementById('value').innerHTML
                
                axios.post('http://localhost:8000/change/', {
                    beforeData, afterData, threshold
                })
                .then(res => {
                    alert("Change Detection Processing Successful")

                    var hideChange = document.getElementById('hideChange')
                    hideChange.value = false
                    var event = new MouseEvent('click', {
                        'view': window, 
                        'bubbles': true, 
                        'cancelable': false
                    })
                    hideChange.dispatchEvent(event)

                    var fileName = document.getElementById('changeFileName')
                    fileName.value = res.data
                    fileName.dispatchEvent(event)
                })
                .catch((err) => console.log(err))
            })
        })
    }
    
    process = () => {
        // const renderFunction = (canvas) => {
        //     var a = document.createElement('a');
        //     // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        //     a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        //     a.download = 'somefilename.jpg';
        //     a.click();
        // }
        this.setState({edgeProcessing: true})

        html2canvas(document.getElementById('before')).then(function (canvas) { 
            var beforeData = canvas.toDataURL()

            html2canvas(document.getElementById('after')).then(function (canvas) {
                var afterData = canvas.toDataURL()

                var threshold = document.getElementById('value').innerHTML
                
                axios.post('http://localhost:8000/edge/', {
                    beforeData, afterData, threshold
                })
                .then(res => {
                    alert("Edge Detection Processing Successful")

                    var hideEdge = document.getElementById('hideEdge')
                    hideEdge.value = false
                    var event = new MouseEvent('click', {
                        'view': window, 
                        'bubbles': true, 
                        'cancelable': false
                    })
                    hideEdge.dispatchEvent(event)

                    var fileName = document.getElementById('fileName')
                    fileName.value = res.data
                    fileName.dispatchEvent(event)
                })
                .catch((err) => console.log(err))
            })
        })
    }

    componentDidMount() {
        const beforeMap = new mapboxgl.Map({
            container: 'before',
            style: 'mapbox://styles/hassanms/cki7c0ix53iqr19rxljjfazjz',
            center: [0, 0],
            zoom: 0,
            preserveDrawingBuffer: true,
            attributionControl: false
        });

        const afterMap = new mapboxgl.Map({
            container: 'after',
            style: 'mapbox://styles/hassanms/cki7cdfvp89fh19lrm5s90are',
            center: [0, 0],
            zoom: 0,
            preserveDrawingBuffer: true,
            attributionControl: false
        });
        
        const container = '#comparison-container';
 
        const map = new MapboxCompare(beforeMap, afterMap, container, {
        // Set this to enable comparing two maps by mouse movement:
            // mousemove: true,
            // orientation: 'vertical'
        });
    }

    render () {
        const { threshold } = this.state;

        return (
            <div style={mapContainer}>
                {/* <div ref={el => this.mapContainer = el} style={mapContainer} /> */}
                <div id="comparison-container">
                    <div id="before" style={map}>
                    </div>
                    <div id="after" style={map}>
                    </div>
                </div>
                <Row>
                    <Col span={4}>
                    <Slider
                        min={0}
                        max={255}
                        style={{ marginLeft: '2px'}}
                        onChange={this.onChangeValue}
                        value={typeof threshold === 'number' ? threshold : 0}
                    />
                    </Col>
                    <Col span={4}>
                    <InputNumber
                        min={0}
                        max={255}
                        style={{ margin: '0 4px' }}
                        value={threshold}
                        onChange={this.onChangeValue}
                    />
                    </Col>
                </Row><br />
                <Row>
                <Button type="primary" onClick={this.process} disabled={this.state.edgeProcessing}>
                    Edge
                </Button>
                <Button type="primary" style={{ margin: '0 4px' }} onClick={this.changeProcess} disabled={this.state.changeProcessing}>
                    Change
                </Button>
                <Button type="primary" style={{ margin: '0 4px' }} onClick={this.maskProcess} disabled={this.state.maskProcessing}>
                    Masked Change
                </Button>
                <p id="value" style={{visibility: 'hidden'}}>{this.state.threshold}</p>
                
                <input id="hideEdge" type="text" style={{visibility: 'hidden'}} onClick={this.updateHideEdge} value="false" />
                <input id="fileName" type="text" style={{visibility: 'hidden'}} onClick={this.updateFilename} value="placeholder"/>
                
                <input id="hideChange" type="text" style={{visibility: 'hidden'}} onClick={this.updateHideChange} value="false" />
                <input id="changeFileName" type="text" style={{visibility: 'hidden'}} onClick={this.updateChangeFilename} value="placeholder"/>
                
                <input id="hideMask" type="text" style={{visibility: 'hidden'}} onClick={this.updateHideMask} value="false" />
                <input id="maskFileName" type="text" style={{visibility: 'hidden'}} onClick={this.updateMaskFilename} value="placeholder"/>

                </Row>

                {!this.state.hideEdge && <Image
                style={{float: 'right'}}
                width={100}
                src={"http://localhost:8000/media/edge/" + this.state.edgeFilename}
                />}

                {!this.state.hideChange && <Image
                style={{float: 'right'}}
                width={100}
                src={"http://localhost:8000/media/change/" + this.state.changeFilename}
                />}

                {!this.state.hideMask && <Image
                style={{float: 'right'}}
                width={100}
                src={"http://localhost:8000/media/maskchange/" + this.state.maskFilename}
                />}        

            </div>
        )
    }

}

export default Swipe;