let ENV = process.env.REACT_APP_MODE;
let DOMAIN = 'https://api.farmin-app.com'
let MODEL_DOMIN = 'https://model.farmin-app.com'
if(ENV == "LOCAL") {
    DOMAIN = "http://127.0.0.1:8000";
    MODEL_DOMIN = 'http://127.0.0.1:8000'
}
const APIs = {
    DOMAIN : DOMAIN,
    MODEL_DOMIN:MODEL_DOMIN,
    LOGIN_API:DOMAIN+'/rest-auth/login/',
    SIGNUP_API:DOMAIN+'/rest-auth/registration/',
    RASTER_LIST:DOMAIN+'/raster/',
    GET_JOB_LIST:DOMAIN+'/order/',
    PROCESS_IMAGE:MODEL_DOMIN+'/process_image/',
    DOWNLOAD_FILES:MODEL_DOMIN+'/download-file/',
    GET_IMAGE:MODEL_DOMIN+'/get_image/:id',
    GET_COLLECTIONS:DOMAIN+'/ai/tasking-collection-all/',
    GET_SCENE_IDS:DOMAIN+'/ai/product-ids/',
    GET_PRODUCT_PARAMS:DOMAIN+'/ai/tasking-collection/',
    GET_ARCHIVE_CATALOG:DOMAIN+'/ai/archive-catalog/',
    PLACE_ORDER:DOMAIN+'/ai/order/',
    GET_CREDIT_UP42:DOMAIN+'/ai/credit/',
    GET_CREDIT_STRIPE:DOMAIN+'/stripe/credit/',
    GET_PLANS:DOMAIN+'/stripe/plan/',
    STRIPE_CHECKOUT:DOMAIN+'/stripe/checkout/',
    GET_ASSET:DOMAIN+'/ai/asset/',
    DOWNLOAD_ASSET:DOMAIN+'/ai/download-asset/',
    UPDATE_TAGS:DOMAIN+'/ai/update-tags/',
    GET_ORDER:DOMAIN+'/ai/all-order/',
    ESTIMATE_ORDER:DOMAIN+'/ai/estimate/',
    STRIPE_PAYMENT_INTENT:DOMAIN+'/stripe/payment-intent/',
    STRIPE_PAYMENT_SUCCESS:DOMAIN+'/stripe/success/',
    STRIPE_SINGLE_PRODUCT_INFO:DOMAIN+'/stripe/product-info',
    PLACE_FOOTPRINT:DOMAIN+'/ai/footprint/',
}

export default APIs;