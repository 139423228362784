import React, { useState, useRef, useCallback } from 'react';
import MapGL, { GeolocateControl, FullscreenControl, NavigationControl, ScaleControl } from 'react-map-gl';
import LayersControl  from './Layers-control';
import * as config from '../config';
import 'mapbox-gl/dist/mapbox-gl.css';
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Geocoder from 'react-map-gl-geocoder'
import { Layout, Menu, } from 'antd';

const { Header } = Layout;

const TOKEN = config.REACT_APP_TOKEN

const geolocateStyle = {
    position: 'absolute',
    top: 50,
    left: 15,
    padding: '10px'
};

const fullscreenControlStyle = {
    position: 'absolute',
    top: 90,
    left: 15,
    padding: '10px'
};

const navStyle = {
    position: 'absolute',
    top: 130,
    left: 15,
    padding: '10px'
};

const layersStyle = {
    position: 'absolute',
    top: 230,
    left: 15,
    padding: '10px'
};

const scaleControlStyle = {
    position: 'absolute',
    bottom: 36,
    left: 15,
    padding: '10px'
};

const Explore = (props) => {
    
    const [viewport, setViewport] = useState({
        width: "100%",
        height: "90vh",
        latitude: 24.40029,
        longitude: 54.55253,
        zoom: 16,
    });

    const togglableLyrs = [
        {
            id:"adm-q4-final-tiles-43-detect6-aajucn",
            name:"Buildings",
            color:"#ef1ae1"
        },
        {
            id:"image-stritched-detectobject2-1fxine",
            name:"Cars",
            color:"#fa1493"
        },
        {
            id:"khalifa-roads-cgey9u",
            name:"Roads",
            color:"#cecfce"
        },
        {
            id:"kc-ndvi-rendered-vector-7tyx47",
            name:"Vegetation",
            color:"#46e637"
        },
        {
            id:"undeveloped-areas-93jcpq",
            name:"Undeveloped areas",
            color:"#f2f1ee"
        },
        {
            id:"before_change-detection_16-11-3h0ohi",
            name:"detection",
            color:"#000"
        }
    ];

    const mapRef = useRef();

    const handleViewportChange = useCallback(
        (newViewport) => setViewport(newViewport),
        []
    );

    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    const handleGeocoderViewportChange = useCallback(
        (newViewport) => {
        const geocoderDefaultOverrides = { transitionDuration: 1000 };

        return handleViewportChange({
            ...newViewport,
            ...geocoderDefaultOverrides
        });
        },
        [handleViewportChange]
    );

    return (
        <div>
            <MapGL
                ref={mapRef}
                {...viewport}
                mapboxApiAccessToken={TOKEN}
                mapStyle="mapbox://styles/hassanms/cki7gc1211emo19qlnz1o6jih"
                onViewportChange={handleViewportChange}
            >
                <Geocoder
                    mapRef={mapRef}
                    onViewportChange={handleGeocoderViewportChange}
                    mapboxApiAccessToken={TOKEN}
                    position="top-left"
                />

                <div style={geolocateStyle}>
                    <GeolocateControl />
                </div>
                <div style={fullscreenControlStyle}>
                    <FullscreenControl />
                </div>
                <div style={navStyle}>
                    <NavigationControl />
                </div>
                <div style={layersStyle}>
                    <LayersControl map={mapRef.current} layers={togglableLyrs}/>
                </div>
                <div style={scaleControlStyle}>
                    <ScaleControl />
                </div>
            </MapGL>
            <Header style={{marginLeft: "80px", backgroundColor: "#000f16" }}>
                <Menu mode="horizontal" style={{ backgroundColor: "#000f16", borderBottom: "1px solid #000f16" }}>
                    <Menu.Item style={{fontSize: "20px"}}>
                        <span style={{border: "5px solid green", padding: "2px 2px", marginRight: "8px", color: "#00ffff"}}>45</span>
                        <span style={{color: "#00ffff", fontWeight: "bold"}}>Extension</span>
                    </Menu.Item>
                    <Menu.Item style={{fontSize: "20px"}}>
                        <span style={{border: "5px solid purple", padding: "2px 2px", marginRight: "8px", color: "#00ffff"}}>30</span>
                        <span style={{color: "#00ffff", fontWeight: "bold"}}>New House</span>
                    </Menu.Item>
                    <Menu.Item style={{fontSize: "20px"}}>
                        <span style={{border: "5px solid green", padding: "2px 2px", marginRight: "8px", color: "#00ffff"}}>11</span>
                        <span style={{color: "#00ffff", fontWeight: "bold"}}>Removed</span>
                    </Menu.Item>
                    <Menu.Item style={{fontSize: "20px"}}>
                        <span style={{border: "5px solid red", padding: "2px 2px", marginRight: "8px", color: "#00ffff"}}>60</span>
                        <span style={{color: "#00ffff", fontWeight: "bold"}}>Cars</span>
                    </Menu.Item>
                </Menu>
            </Header>
        </div>


    )
}

export default Explore;
