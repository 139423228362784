import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import downloadIcon from '../images/download-button.svg';
import "../styles/DataExtractionForm.css";
import APIs from '../Api';

function DataExtractionForm({ extent, drawPolygonHandler, isVisible, processTarget, jobsList, submitHandler, orderClickHandler}) {
    
    const [isDrawing, setIsDrawing] = useState(false);

    const submitForm = () => {
        const token = localStorage.getItem("token");
        axios.post(APIs.GET_JOB_LIST, {
            extent: extent,
            process_target: processTarget,
            token: token
        })
        .then(res => {
            submitHandler();
        })
        .catch((err) => console.log(err))
    }

    const drawMode = () => {
        setIsDrawing(true);
        drawPolygonHandler();
    }

    useEffect(() => {
        setIsDrawing(false);
    }, [extent]);

    return (
        <>
            {
                isVisible &&
                <div className="container dataExtractionForm">
                    <div>
                        <h1 className="title">Detect {processTarget}</h1>
                        <label htmlFor="areaOfInterest">
                            <div className="inputGroup">
                                <input className="formInput" type="text" id="areaOfInterest" value={extent} placeholder="Select an Area Of Interest" readOnly />
                                <button className="getExtBtn" style={{ background: isDrawing ? "rgb(24 158 209)" : "#585858" }} type="button" onClick={drawMode}>
                                    Draw it!
                                </button>
                            </div>
                        </label>
                        {
                            jobsList && jobsList.length == 0 &&
                            <h1 style={{marginTop:"10px"}}>Select an Area of Interest and Create your first Data Extraction Job.</h1>
                        }
                        <button className="submitBtn" onClick={submitForm}>Submit Job</button>
                    </div>
                    {
                        jobsList && jobsList.length != 0 &&
                        <div style={{marginTop:"20px"}}>
                            <h1 className="title">Previous Detection Jobs</h1>
                            <ul className="jobsListStyle">
                                {
                                    jobsList.map((job) => {
                                        return (
                                            job.process_target == processTarget &&
                                            <li>
                                                <h4 onClick={() => orderClickHandler(job.mb_tileset_id, 'vector')}>
                                                    {`Order${job.id} of ${job.date}-${job.status}`}
                                                </h4>
                                                <a href={`${APIs.DOMAIN}/${job.download_url}`} target="_blank">
                                                    <button className="downloadBtn" type="button">
                                                        <img className="downloadIcon" src={downloadIcon} />
                                                    </button>
                                                </a>
                                                
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default DataExtractionForm;
