import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: '90%',
    boxShadow:'0px 10px 30px rgba(0, 0, 0, 0.4)',
    borderRadius:10,
    background: '#f4f4f4'
  },
  media: {
    height: 140,
  },
  buttonDiv:{
    marginTop:26,
    padding:0
  },
  button:{
    padding:13,
    borderRadius:0,
    background: 'linear-gradient( 135deg, rgb(49, 0, 62) 0%, rgb(195, 40, 110) 100% )'
  }
});

export default function Plans({planDetails,onClick,purchased}) {
  const classes = useStyles();
  let amount = planDetails.details.unit_amount.toString().slice(0, -2)
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {planDetails.name} plan
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            Credit {planDetails.credits}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {amount}  {planDetails.details.currency.toUpperCase()}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.buttonDiv}>
        {
          purchased ? 
            <Button className={classes.button} size="medium" style={{width:"100%",opacity:0.6}} variant="contained" color="secondary">Subscribed</Button>
            :
          <Button className={classes.button}  size="medium" style={{width:"100%"}} onClick={()=>onClick(planDetails.live_plan_id)} variant="contained" color="secondary">subscribe</Button>
        }
       
      </CardActions>
    </Card>
  );
}
