import React from 'react';
import { NavLink } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { connect } from 'react-redux';
import logo from '../images/logo-green.png';
import { Form, Input, Button, Card, Row, Col, Select, } from 'antd';

const { Option } = Select;

class Signup extends React.Component {

  render () {

    const onFinish = (values) => {
        this.props.onAuth(
            values.email,
            values.password,
            values.confirm,
            values.firstname,
            values.lastname,
            values.companyname,
            values.country
        );

        this.props.history.push('/');
        console.log('Received values of form: ', values);

    };

    return (
        <div>
          <img width={150} src={logo} style={{padding: '25px', float: 'left'}} alt="side logo" />
          
          <Card style={{textAlign: "center", width: "50%", margin: "auto", border: 'none', backgroundColor: '#000f16'}}>
            
            <Form
              name="register"
              onSubmit={this.handleSubmit}
              onFinish={onFinish}
              scrollToFirstError
              style={{marginTop: '50px'}}
            >
                {/* <Form.Item
                    name="username"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                    ]}
                >
                    <Input style={{borderRadius: '8px'}} placeholder="Username"/>
                </Form.Item> */}

                <Form.Item
                    name="email"
                    rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!',
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!',
                    },
                    ]}
                >
                    <Input style={{borderRadius: '8px'}} placeholder="Email Address"/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    ]}
                    hasFeedback
                >
                    <Input.Password style={{borderRadius: '8px'}} placeholder="Password"/>
                </Form.Item>
            
                <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
            
                        return Promise.reject('The two passwords that you entered do not match!');
                        },
                    }),
                    ]}
                >
                    <Input.Password style={{borderRadius: '8px'}} placeholder="Confirm password"/>
                </Form.Item>

                <Row gutter={32}>
                  <Col span={12}>
                    <Form.Item name="firstname">
                      <Input style={{borderRadius: '8px'}} placeholder="First Name"/>
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item name="lastname">
                      <Input style={{borderRadius: '8px'}} placeholder="Last Name"/>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                    name="companyname"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your companyname!',
                    },
                    ]}
                >
                    <Input style={{borderRadius: '8px'}} placeholder="Companyname"/>
                </Form.Item>

                <Form.Item name="country">
                  <Select
                    showSearch
                    placeholder="Select your country of residence"
                    optionFilterProp="children"
                    // onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    block
                  >
                    <Option value="UAE">United Arab Emirates</Option>
                    <Option value="Saudi Arabia">Saudi Arabia</Option>
                    <Option value="Qatar">Qatar</Option>
                  </Select>
                </Form.Item>
                
                <p style={{color: 'white', fontFamily: 'times'}}>By continuing, you agree to Farmin's terms of use and privacy policy.</p>

                <Form.Item>
                    <Button 
                      type="primary"
                      size="large"  
                      htmlType="submit" 
                      style={{backgroundColor: '#015553', borderColor: '#015553', borderRadius: '8px'}} 
                      block>Ready? Click here
                    </Button>
                    <br />
                    <br />
                    <NavLink style={{color: 'white'}} to="/login" block>Login</NavLink>
                </Form.Item>
            </Form>
          </Card>  
        </div>  
      );
  } 
}

const mapStateToProps = (state) => {
    return {
      loading: state.loading,
      error: state.error
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      onAuth: (
        email, password, confirm, firstname, lastname, companyname, country
      ) => dispatch(actions.authSignup(email, password, confirm, firstname, lastname, companyname, country))
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
