import React, { useState,useEffect  } from "react";
import { Box } from "@material-ui/core";
import "./orderImageComponent.css";
import APIs from '../../../Api.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from "@material-ui/core/Slider";
import { useSelector, useDispatch } from 'react-redux';
import BrowseArchive from "./BrowseArchive.js";
import {useHistory,useLocation} from "react-router-dom";
import {newImageryData} from  "./OrderImageData.js"
import IconButton from '@material-ui/core/IconButton';
import polyline  from '../../../images/polyline.png'

const OrderImageComponent = ({orderImageHandler,analyzeOrderHandler_,polygonCoordinates,updateMapHeight}) => {
  const estimatedcoordinatesData = useSelector((state) => state.coordinates);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 25);
  const startDates = currentDate.toISOString().slice(0, 10);
  const endDates = new Date().toISOString().slice(0, 10);
  const [endDate, setEndDate] = useState(endDates);
  const [startDate, setStartDate] = useState(startDates);
  const  activeTab_       = localStorage.getItem('active_tab') || 'newImagery'
  const [activeTab, setActiveTab] = useState(activeTab_);
  const [collectionData, setCollectionData] = useState([]);
  const [selectedCollectionData,setSelectedCollectionData] = useState({})
  const [showLoader,setShowLoader] = useState(false)
  const [productType,setProductType] = useState('select')
  const [productID,setProductID] = useState('')
  const [collectionName_,setCollectionName] = useState('')
  const [imagesIDs,setImagesIDs] = useState([])
  const [orderData,setOrderData] = useState({})
  const [productCollectionData,setProductCollectionData] = useState('')
  const [linkID,setLinkID] = useState('')
  const [cloudCoverages, setCloudCoverages] = useState([0, 100]);
  const [sceneData, setSceneData] = useState([]);
  const [fieldData,setFieldData] = useState([]);
  const [aoiType,setAoiType] = useState('select')
  const [imageIdLoader,setImageIdLoader] = useState(false)
  const [showImageIdsContainer,setImageIdsContainer]  = useState(false)
  const [allError,setError]  = useState({aoi:"",cart:""})
  const [estimateCredit,setEstimateCredit]  = useState(0)
  const [addToCart,setAddToCart]            = useState(false) 
  const [estimateOrderLoader,setEstimateOrderLoader] = useState(false)
  const  rid_  = localStorage.getItem('rid') || 1
  const [resolutionId, setResolutionId] = useState(rid_);
  // const  imageryResolution_  = localStorage.getItem('imagery_resolution') || '~10CM'
  // const [imageryResolution,setImageryResolution] = useState(imageryResolution_)
  const [allOrderParms,setAllOrderParms] = useState({})
  const history = useHistory();


  const currentDate_ = new Date();
  currentDate.setDate(currentDate.getDate() - 25);
  const startDates_ = currentDate.toISOString().slice(0, 10);
  const endDates_ = new Date().toISOString().slice(0, 10);
  const  [dummyData, setDummyData] = useState(newImageryData)
  const  typeValue_  = localStorage.getItem('type_value') || 'normalrgb'
  const  [typeValue, setTypeValue] = useState(typeValue_)
  const  [typeImage, setTypeImage] = useState('normalrgb.png')
  const  [resolutionId_,setResolutionId_] = useState(1)
  const  imageryResolution_  = localStorage.getItem('imagery_resolution') || '~10CM'
  const  [resolutionValue,setResolutionValue] = useState(imageryResolution_)


  const  storageAoiType_  = localStorage.getItem('aoi_selected') || 'select'
  const  [aoiType_,setAoiType_] = useState(storageAoiType_)
  const  [aoiData_,setAoiData] = useState({})
  const  [startDate_,setStartDate_] = useState(startDates_)
  const  [endDate_,setEndDate_] = useState(endDates_)
  const  [cloudCoverage_,setCloudCoverages_] = useState([30,70])
  const  [angle_,setAngle_] = useState([30,70])


  const  captureMethod_  = localStorage.getItem('capture_method') || 'SLC'
  const  [captureMethodId,setCaptureMethodId_] = useState(5)
  const  [captureMethodValue_,setCaptureMethodValue_] = useState(captureMethod_)

  const  [containerWidth,setContainerWidth] = useState('578px')




  // useEffect(()=>{
  //   let catalogData = localStorage.getItem('catalog_data') || "[]"
  //   catalogData = JSON.parse(catalogData)
  //   setCollectionData(catalogData)
  //   if (catalogData.length == 0){
  //     getAllTaskingCollection()
  //   }    
    
  //   const  productType    = localStorage.getItem('product_type') || false
  //   const collectionName  = localStorage.getItem("collection_name") || false
  //   const productId_      = localStorage.getItem("product_id") || false
  //   const  startDate_     = localStorage.getItem('start_date') || false
  //   const  endDate_       = localStorage.getItem('end_date') || false
  //   const  aoi_           = localStorage.getItem('aoi_') || "[]"
  //   const  aoi_options    = JSON.parse(aoi_)
  //   const  aoiValue       = localStorage.getItem('aoi_value') || "select"
  //   const  cloudCoverages_ = localStorage.getItem('cloud') || "[0, 100]"
  //   const  cloud          = JSON.parse(cloudCoverages_)


  //   setAoiType(aoiValue)

  //   setCloudCoverages(cloud)
  //   setFieldData(aoi_options)

  //   if (startDate_){
  //     setStartDate(startDate_)
  //   }

  //   if (endDate_){
  //     setEndDate(endDate_)
  //   }

  //   if (collectionName){
  //     setCollectionName(collectionName)
  //   }

  //   if (productId_){
  //     setProductID(productId_)
  //   }

  //   if (productType){
  //     setProductType(productType)
  //   }

  // },[])

  // const getAllTaskingCollection = async ()=> {
  //   setShowLoader(true)
  //   const token = localStorage.getItem('token');
  //   const headerOptions = {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json',
  //       'Authorization': `Token ${token}`,
  //     },
  //   };
  //   let collectionType =  "catalog";
  //   const response = await fetch(APIs.GET_COLLECTIONS+collectionType, headerOptions);
  //   const {data,code } = await response.json();
  //   if (code == 200){
  //     localStorage.setItem('catalog_data',JSON.stringify(data))
  //     data.map((item_,index)=>{
  //       if (item_.section == 'Optical satellite imagery'){
  //         localStorage.setItem("selected_catalog",JSON.stringify(item_.products[0]))
  //         setSelectedCollectionData(item_.products[0])
  //       }
  //     })
  //     setCollectionData(data)
  //     setShowLoader(false)
  //   } else {
  //     setShowLoader(false)
  //   }

  // }

  const handleTabClick = (tab) => {
    localStorage.setItem('ai_model_init',1)
    updateMapHeight(tab)
    setActiveTab(tab);
    localStorage.setItem('active_tab',tab)
    localStorage.setItem('aoi_selected','select')
    orderImageHandler(false)

    if (tab=='browseArchive'){
      localStorage.removeItem('aoi_value',)
      localStorage.removeItem('order_data',)
      localStorage.removeItem('aoi_',)
      localStorage.removeItem('product_type')
      localStorage.removeItem('product_id')
      localStorage.removeItem('start_date')
      localStorage.removeItem('end_date')
      localStorage.removeItem('imagery_resolution')
      localStorage.removeItem('rid')
      setProductType('select')
      setAoiType('select')
      setProductID('')
    } else {
      localStorage.removeItem('optical_satellite')
      localStorage.removeItem('aoi_selected')
      localStorage.removeItem('optical_satellite_setting')
      localStorage.removeItem('optical_resolution')
    }
  };

  // const newImageryTypeOptionHandler = (e) =>{
  //   localStorage.removeItem('collection_name')
  //   localStorage.removeItem('imagery_resolution')
  //   localStorage.removeItem('rid')
  //   localStorage.removeItem('product_id')
  //   localStorage.removeItem('aoi_')
  //   localStorage.removeItem('aoi_value')
  //   localStorage.removeItem('product_type')
  //   setError({aoi:"",cart:""})
  //   setResolutionId(1)
  //   setImageryResolution('~10CM')
  //   setProductID('')
  //   setCollectionName('')
  //   setAoiType('select')
  //   setFieldData([])
  //   setSceneData([])
  //   const {value} =  e.target
  //   let catalogData = localStorage.getItem('catalog_data') || "[]"
  //   catalogData = JSON.parse(catalogData)
  //   let optionData_ = []
  //   catalogData.map((item_,index)=>{optionData_.push(...item_.products)})
  //   const filterData = optionData_.filter((optionItem)=>{return optionItem.name == value;})
  //   if (filterData.length>0){
  //     localStorage.setItem("selected_catalog",JSON.stringify(filterData[0]))
  //     setSelectedCollectionData(filterData[0])
  //   }
  // }

  const newImageryTypeOptionHandler = (e) =>{
    localStorage.removeItem('aoi_selected')
    localStorage.removeItem('aoidraw_enable')
    localStorage.removeItem('imagery_resolution')
    localStorage.removeItem('capture_method')
    orderImageHandler(false)
    setAoiType_('select')
    const {value} = e.target
    setTypeValue(value)
    setTypeImage(dummyData.images[value])
    localStorage.setItem('type_value',value)
  }

  // const newImageryTypeSectionOption = () => {
  //   let catalogData = localStorage.getItem('catalog_data') || "[]"
  //   catalogData = JSON.parse(catalogData)
  //   let optionData = []
  //   catalogData.map((item_,index)=>{
  //     optionData.push(...item_.products)
  //   })
    
  //   let selectedCatalogData = localStorage.getItem('selected_catalog') || "[]"
  //   selectedCatalogData = JSON.parse(selectedCatalogData)
  //   return(
  //     <select className="leftSectionSelectList" onChange={(e)=>{newImageryTypeOptionHandler(e)}}>
  //       { 
  //         optionData.map((optionItem,index)=>{

  //           return (
  //             <option key={index} value={optionItem.name} selected={selectedCatalogData.name == optionItem.name} >{optionItem.title}</option>
  //           )
  //         })
  //       }
  //     </select>
  //   )
  // }


  const newImageryTypeSectionOption = () => {
    let typeData =  dummyData.type
    return(
      <select className="leftSectionSelectList" defaultValue={typeValue} value={typeValue} onChange={(e)=>{newImageryTypeOptionHandler(e)}}>
        {
          typeData.map((item_,index)=>{
              return(
                <option  key={index} value={item_.value}>{item_.name}</option>
              )
          })
        }
      </select>
    )
  }

  // const rightSectionImage  = () =>{
  //   let selectedCatalogData = localStorage.getItem('selected_catalog') || "[]"
  //   selectedCatalogData = JSON.parse(selectedCatalogData)
  //   const imageUrl = `/up42/${selectedCatalogData.thumbnail}`;
  //   return(
  //     <img className="rightSectionImage" src={imageUrl} />
  //   )
  // }

  const rightSectionImage  = () =>{
    const imageUrl = `/up42/${dummyData.images[typeValue]}`;
    return(
      <img className="rightSectionImage" src={imageUrl} />
    )
  }

  // const resolutionHandler = (rid,value)=>{
  //   setError({aoi:"",cart:""})
  //   setResolutionId(rid)
  //   setImageryResolution(value)
  //   localStorage.setItem('imagery_resolution',value)
  //   localStorage.setItem('rid',rid)  
  //   if (showImageIdsContainer){
  //     let productIds      = localStorage.getItem('product_id') 
  //     let collection_name_ = localStorage.getItem('collection_name') 
  //     let  selectedCatalogData = localStorage.getItem('selected_catalog') || "{}"
  //     selectedCatalogData = JSON.parse(selectedCatalogData)
  //     let host_name_ = ''
  //     if (selectedCatalogData?.hostName){
  //       host_name_ = selectedCatalogData?.hostName
  //     }

  //     let cloudData_   = localStorage.getItem('cloud') || "[0,100]"
  //     cloudData_ = JSON.parse(cloudData_)
  //     let cloudCoveragesData_ = 100
  //     if (cloudData_){
  //       cloudCoveragesData_ = cloudData_[1] - cloudData_[0]
  //     }

  //     productParamsHandler(productIds,collection_name_,host_name_,false,cloudCoveragesData_)
  //   }
  // }

  const resolutionHandler = (rid,value)=>{
    setResolutionId_(rid)
    setResolutionValue(value)
    localStorage.setItem('imagery_resolution',value)
  }

  // const resolutionSectionImage  = () =>{
  //   let selectedCatalogData = localStorage.getItem('selected_catalog') || "[]"
  //   selectedCatalogData = JSON.parse(selectedCatalogData)
  //   const imageUrl = `/up42/${selectedCatalogData.thumbnail}`;
  //   const imageUrlArray = [
  //     {rid:1,src:imageUrl,value:'~10CM'},
  //     {rid:2,src:imageUrl,value:'~30CM'},
  //     {rid:3,src:imageUrl,value:'~50CM'},
  //     {rid:4,src:imageUrl,value:'~1M'},
  //     {rid:5,src:imageUrl,value:'~10M'}
  //   ];

  //   return(
  //     <>
  //       {imageUrlArray.map((item_, index) => (
  //         <Box
  //           key={index}
  //           className="imageBox"
  //           style={{ backgroundImage: `url("${item_.src}")` }}
  //           onClick={()=>{resolutionHandler(item_.rid,item_.value)}}
  //         >
  //           <Box className="textBox">
  //             <input readOnly={true} value={item_.value} type="text" className={"inputStyle" + (resolutionId==item_.rid?" active":"" )}/>
  //           </Box>
  //           <Box className="radioBox">
  //             <input checked={(resolutionId==item_.rid?true:false)}  className="inputRadio"  name="resolution" type="radio" />
  //           </Box>
  //         </Box>
  //       ))}
  //     </>
  //   )
  // }

  const resolutionSectionImage  = () =>{
    const imageUrl = `/up42/${dummyData.images[typeValue]}`;

    let imageUrlArray = [
      {rid:1,src:imageUrl,value:'~10CM'},
      {rid:2,src:imageUrl,value:'~30CM'},
      {rid:3,src:imageUrl,value:'~50CM'},
      {rid:4,src:imageUrl,value:'~1M'},
      {rid:5,src:imageUrl,value:'~10M'}
    ]
    let clasName_ = 'normalgrid'
    switch (typeValue) {
      case 'sar':
        imageUrlArray = [
          {rid:1,src:imageUrl,value:'~10CM'},
          {rid:2,src:imageUrl,value:'~30CM'},
          {rid:3,src:imageUrl,value:'~50CM'},
          {rid:4,src:imageUrl,value:'~1M'},
        ]
        clasName_ = 'sargrid'
        break;
      case 'thermal':
        imageUrlArray =  [
          {rid:1,src:imageUrl,value:'~3M'},
          {rid:2,src:imageUrl,value:'~30M'},
        ]
        clasName_ = 'thermalgrid'
        break;
      default:
        imageUrlArray =  imageUrlArray
        clasName_ = clasName_
        break;
    }


    return(
      <>
        {
          imageUrlArray.map((item_, index) => {
            return(
              <>
                <Box
                  key={index}
                  className={"imageBox " + clasName_}
                  style={{ backgroundImage: `url("${item_.src}")` }}
                  onClick={()=>{resolutionHandler(item_.rid,item_.value)}}
                >
                <Box className="textBox">
                  <input readOnly={true} value={item_.value} type="text" className="inputStyle active"/>
                </Box>
                  <Box className="radioBox">
                    <input checked={(resolutionValue==item_.value?true:false)}  className="inputRadio"  name="resolution" type="radio" />
                  </Box>
                </Box>
              </>
            )
          })
        }
      </>
    )
  }

  // const newImageryleftSectionParagraph = () => {
  //   let selectedCatalogData = localStorage.getItem('selected_catalog') || "[]"
  //   selectedCatalogData = JSON.parse(selectedCatalogData)
  //   return(
  //     <p className="leftSectionParagraph">{selectedCatalogData.description}</p>
  //   )
  // }

  const newImageryleftSectionParagraph = () => {
    return(
      <p className="leftSectionParagraph">
        Lorem ipsum dolor sit amet consectetur. Amet feugiat vulputate hendrerit accumsan blandit sem felis fames tellus. Ac netus in amet dui nulla ac diam posuere. Vel sed eros fringilla facilisis adipiscing porttitor vel. Mauris volutpat turpis.
      </p>
    )
  }

  // const collectionForm = (params)=>{
  //   let properties = params.properties;
  //   let definitions = params.definitions;
  //   let fieldData = []
  //   Object.keys(properties).map((item,index)=>{
  //     let  data = properties[item]
  //     data['key']      = item
  //     if (data.allOf){
  //         let coordinatesData = []
  //         let allOfData =  data.allOf
  //         allOfData.map((allOfItem,index)=>{
  //             let allOfDef = allOfItem.$ref.replace('#/definitions/','');
  //             const definitionsData = definitions[allOfDef]
  //             if (definitionsData.anyOf){
  //                 coordinatesData.push(definitionsData.anyOf)
  //             } else{
  //                 coordinatesData.push([definitionsData])   
  //             }
                                
  //         })
  //         data.input_type   = 'select'
  //         data['data']      = coordinatesData
          

  //     } else if (data.anyOf){
  //         let  anyOfData =  data.anyOf
  //         let coordinatesData = []
  //         let coordinatesSelection = []
  //         anyOfData.map((anyOfItem,index)=>{
  //             let anyOfDef = anyOfItem.$ref.replace('#/definitions/','');
  //             const definitionsData = definitions[anyOfDef]
  //             if (definitionsData.properties){
  //                 coordinatesData.push(definitionsData)
  //                 coordinatesSelection.push(anyOfDef)
  //             }
  //         })
  //         data.input_type   = 'coordinates'
  //         data['data']      = coordinatesData
  //         data['selection'] = coordinatesSelection

  //     } else if (data.$ref){
  //         let refDef = data.$ref.replace('#/definitions/','');
  //         const definitionsData = definitions[refDef]
  //         let coordinatesData = [definitionsData]
  //         let coordinatesSelection = [refDef]
  //         data.input_type   = 'coordinates'
  //         data['data']      = coordinatesData
  //         data['selection'] = coordinatesSelection
  //         data['title']   = item.toUpperCase()
  //     }
  //     else { 
  //         if (data.format){
  //             if (data.format == 'date-time'){
  //                 data['input_type'] = 'date-time'
  //                 data['value'] = ''
  //             } else{

  //             }
  //         } else if (data.type == 'string'){
  //             data['input_type'] = 'text'
  //             data['value'] = ''
  //         } else if (data.type == 'integer'  || data.type == 'number'){
  //             data['input_type'] = 'integer'
  //             data['value'] = 0
  //         } 
  //     }
  //     fieldData.push(data)
  //   }) 
  //   localStorage.removeItem('aoi_','aoi_value')
  //   setFieldData(fieldData)
  // }

  // const productParamsHandler = async (productId,collection_name,hostName_,object,cloudCoverage=100,fetchType='self') => {
  //   setError({aoi:"",cart:""})
  //   localStorage.removeItem('aoi_')
  //   localStorage.removeItem('aoi_value')
  //   setAoiType('select')
  //   setImageIdLoader(true)
  //   let type = 'catalog'
  //   let  productId_ =  productID 
  //   if (productId && productId !=""){
  //       productId_ = productId
  //   } 

  //   let startDate_ = startDate
  //   let endDate_   = endDate
  //   if (object){
  //       if (object.type == 'start'){
  //         startDate_ = object.date
  //       } else {
  //         endDate_ = object.date
  //       }
  //   }

  //   const token = localStorage.getItem('token');
  //   const headerOptions = {
  //   method: 'GET',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Token ${token}`,
  //     },
  //   };

  //   let resolution_ = localStorage.getItem('imagery_resolution') || '~10CM'
  //   resolution_ = parseInt(resolution_.match(/\d+/)[0], 10);
    
  //   let payload = {
  //     hostName:hostName_,
  //     collectionName:collection_name,
  //     start:startDate_,
  //     end:endDate_,
  //     date:true,
  //     query:true,
  //     cloudCoverage,
  //     resolution_,
  //   };


  //   if (type == 'tasking'){
  //       payload.hostName = false
  //       payload.collectionName = false
  //       payload.date = false
  //   }
    
  //   try {
  //       const url = new URL(APIs.GET_PRODUCT_PARAMS+productId_);
  //       url.search = new URLSearchParams(payload).toString();
  //       const response = await fetch(url, headerOptions);
  //       const {code,data } = await response.json();
  //       if (code == 200){
  //           collectionForm(data)
  //           setSceneData([data])
  //           let scene_data = data.scene_data 
  //           if (scene_data.length > 0){
  //               let imagesData = []
  //               scene_data.map((item,index)=>{
  //                   let ids = item.properties.id
  //                   imagesData.push({value:ids,const:ids})
  //               })
  //               setImagesIDs(imagesData)
  //               setLinkID(data.linkid)
  //           } else{
  //               if (fetchType!='self'){
  //                   //setError("Ids not found this date range")
  //               }
  //           }
  //           let properties = data.properties
  //           let allKeys = {type:'',dataProduct:productId_,'geokey':''}
  //           Object.keys(properties).map((item,index)=>{
  //               allKeys[item] = ''
  //           })
  //           setOrderData(allKeys)
  //           localStorage.setItem('order_data',JSON.stringify(allKeys))
  //           setProductCollectionData(data);
  //           setImageIdLoader(false)
            
  //       } else {
  //         setImageIdLoader(false)
  //       }
        
  //   } catch (error) {
  //       //setBackDrop(false)  
  //       //setLoader(false)
  //       setImageIdLoader(false)
  //   }
  // }

  // const productTypeHandler = (event)=>{
  //   setError({aoi:"",cart:""})
  //   let value = event.target.value
  //   localStorage.setItem("product_type",value)
  //   if (value != 'select'){
  //       setImageIdsContainer(true)
  //       setFieldData([])
        
  //       let dataType = []
  //       if (Object.keys(selectedCollectionData).length>0){
  //         dataType = selectedCollectionData
  //       } else{
  //         dataType = localStorage.getItem('selected_catalog') || "{}"
  //         dataType = JSON.parse(dataType)
  //         if (Object.keys(dataType).length>0){
  //           dataType = dataType
  //         }
  //       }
  //       let dataProductIds = dataType.data_ids
  //       let collectionData = dataType.collection_name
  //       let hostName = dataType.hostName
  //       setImagesIDs([])
  //       setLinkID('')

  //       let cloudCoverage_ = cloudCoverages[1] - cloudCoverages[0]
  //       productParamsHandler(dataProductIds[value],collectionData[value],hostName,false,cloudCoverage_)

  //       localStorage.setItem("product_type",value)
  //       localStorage.setItem("collection_name",collectionData[value])
  //       localStorage.setItem("product_id",dataProductIds[value])

  //       setProductType(value)
  //       setCollectionName(collectionData[value])
  //       setProductID(dataProductIds[value])
  //   }
  // }

//   const newImageryProductTypes = () => {
//     let dataType = []
//     if (Object.keys(selectedCollectionData).length>0){
//       dataType = selectedCollectionData.data_type
//     } else{
//       dataType = localStorage.getItem('selected_catalog') || "[]"
//       dataType = JSON.parse(dataType)
//       if (Object.keys(dataType).length>0){
//         dataType = dataType.data_type
//       }
//     }
//     return(
//       <select className="selectListStyle" value={productType} onChange={(event)=> productTypeHandler(event)}>
//         <option value="select">Select</option>
//         {
//           dataType.map((item,index)=>{
//             return(
//               <option value={item.value}>{item.name}</option>
//             )
//           })
//         }
//       </select>
// )
//   }

  // const handleDateChange = (e,type) => {
  //   setError({aoi:"",cart:""})
  //   let dataType = []
  //   if (Object.keys(selectedCollectionData).length>0){
  //     dataType = selectedCollectionData
  //   } else{
  //     dataType = localStorage.getItem('selected_catalog') || "{}"
  //     dataType = JSON.parse(dataType)
  //     if (Object.keys(dataType).length>0){
  //       dataType = dataType
  //     }
  //   }
  //   let hostName = dataType.hostName
  //   let date_ = e.target.value
  //   if (type == 'start'){
  //     setStartDate(date_);
  //     localStorage.setItem('start_date',date_)
  //   } else {
  //     localStorage.setItem('end_date',date_)
  //     setEndDate(date_);
  //   }
  //   let cloudCoverage_ = cloudCoverages[1] - cloudCoverages[0]
  //   productParamsHandler(productID,collectionName_,hostName,{date:date_,type},cloudCoverage_)
  //   if (!showImageIdsContainer){
  //     setImageIdsContainer(true)
  //   }
  // };

  const handleDateChange = (e,type) => {
    const {value} = e.target
    if (type == 'start'){
      setStartDate_(value);
    } else {
      setEndDate_(value);
    }
  };

  const handleChangeAngle = (e)=>{
    //console.log();
  }

  const handleChange = (event, newValue) => {
      //console.log();
  };  

  const valuetext = (value) => {
    return `${value}°C`;
  }

  // const newImageryAreaInterestSelect = (e,key) =>{
  //   const {value} = e.target
  //   if (value != 'select'){
  //     setError({...allError,aoi:""})
  //     localStorage.setItem('aoi_value',value)
  //     setAoiType(value)
  //     setOrderData({...orderData,type:value,geokey:key})
  //     let orderData_ = localStorage.getItem('order_data') || "{}"
  //     orderData_ = JSON.parse(orderData_)
  //     localStorage.setItem('order_data',JSON.stringify({...orderData_,type:value,geokey:key}))
  //   }
  // }

  const newImageryAreaInterestSelect = (e) =>{
    localStorage.setItem('aoidraw_enable','1')
    const {value} = e.target
    if (value != 'select'){
      let aoiData = dummyData.aoiTypeValue
      let data = aoiData[value]
      data.aoiIsSeletced = false
      data.dataShow      = true
      orderImageHandler(data)
      setAoiData(data)
      setAoiType_(value)
      localStorage.setItem('aoi_selected',value)
    }
  }
  
  // const newImageryAreaInterest = () =>{
  //   let fieldData_  = fieldData
  //   let aoiOptions = []
  //   if (fieldData_.length > 0){
  //     fieldData_.map((object_,index)=>{
  //         if (object_.input_type == 'coordinates'){
  //           aoiOptions.push(object_.selection)
  //         }
  //     })
  //   }

  //   if (aoiOptions.length>0){
  //     const aioData = [{input_type:"coordinates",selection:aoiOptions}]
  //     localStorage.removeItem('aoi_','aoi_value')
  //     localStorage.setItem('aoi_',JSON.stringify(aioData))
  //   }

  //   return(
  //     <>
  //     <select disabled={aoiOptions.length > 0?false:true} value={aoiType}  onChange={(e)=>{newImageryAreaInterestSelect(e,'aoi')}} className="selectListStyle">
  //       <option value='select'>Select</option>
  //       { aoiOptions.length > 0 &&
  //         aoiOptions[0].map((option,item)=>{
  //           return(
  //             <option value={option}>{option}</option>
  //           )
  //         })
  //       }
  //     </select>
  //     </>
  //   )
  // }


  const newImageryAreaInterest = () =>{
    let aoiType = dummyData.aoi
    return(
      <>
      <select className="selectListStyle" value={aoiType_}  onChange={(e)=>{newImageryAreaInterestSelect(e)}}>
        <option value='select'>Choose an area</option>
        {aoiType.map((item_,index)=>{
          return(
            <option key={index} value={item_.value}>{item_.name}</option>
          )
        })}
        
      </select>
      </>
    )
  }

  const imageHandler = (data) =>{
    let fieldData_  = fieldData
    let aoiIsSeletced = true
    let dataShow      = true
    let showError_    = false
    if (fieldData_.length > 0){
      for (let index=0;index < fieldData_.length;index++){
          if (fieldData_[index].input_type == 'coordinates'){
            if (aoiType =='select'){
              aoiIsSeletced = false
              dataShow      = false
              showError_ = true
              break;
            } else {
              aoiIsSeletced = true
            }
          } else {
            aoiIsSeletced = false
            dataShow      = true
          }
      }
    }
    let orderData = localStorage.getItem('order_data') || "{}"
    orderData       = JSON.parse(orderData)
    let cloudCoverage = data.properties.cloudCoverage
    let resolution    = data.properties.resolution

    let originalDate = new Date(data.properties.acquisitionDate);
    let startDate = `${originalDate.getFullYear()}-${originalDate.getMonth()+ 1}-${originalDate.getDate()}`;

    let  orderDatanew = {
      ...orderData,
      id:data.properties.id,
      cloudCoverage,
      startDate,
      resolution,
    } 
    localStorage.setItem('order_data',JSON.stringify(orderDatanew))

    data.aoiIsSeletced = aoiIsSeletced
    data.dataShow      = dataShow
    orderImageHandler(data)
    if (showError_){
      setError({...allError,aoi:"Please select the aoi"})
    }
    
  }

  const newImageryImagesIds = () =>{
    let sceneData_  = sceneData
    if (sceneData_.length > 0){
        sceneData_ = sceneData_[0].scene_data
    }
    return(
      <>
        { showImageIdsContainer &&
          <Box m={1} mt={1.2} className="containerBox imageContainer_">
              {imageIdLoader ? 
                <Box className="loader_container">
                  <CircularProgress className="loaderC"/>
                </Box> 
              :
              <> 
                <div className="titleContainer">
                  <span className="title">Select an image:</span>
                </div>
                <Box className="archiveSection">
                  { sceneData_.length > 0 ?
                    <>
                      {  
                        sceneData_.map((item_,index)=>{
                          const properties_ = item_.properties
                          const originalDate = new Date(properties_.acquisitionDate);
                          const formattedDate = `${originalDate.getDate()} - ${originalDate.getMonth()+1} - ${originalDate.getFullYear()}`;
                          return(
                            <div className="archiveItem" onClick={()=>{imageHandler(item_)}}>
                              <div className="imageContainer">
                                <img  src={`data:image/jpeg;base64,${item_.image_src.file[1]}`} alt="Image" />
                              </div>
                              <div className="contentContainer"><div className="textSpan">Resolution: {properties_.resolution} cm</div>
                              <div className="textSpan">Captured: {formattedDate}</div>
                              <div className="textSpan">Cloud: {properties_.cloudCoverage}% </div></div>
                            </div>
                          )
                        })
                      }
                    </>
                    :
                    <div> Not Data Found </div>
                  }
                </Box>
              </>
            }
          </Box>
        }
      </>
    )
  }

  // const estimateOrderHandler = async(event)=>{
  //   setEstimateOrderLoader(true)
  //   setError({...allError,cart:"",aoi:""})
  //   analyzeOrderHandler_()
  //   let  polygonCoordinates_ = polygonCoordinates
  //   if (estimatedcoordinatesData.length > 0){
  //     polygonCoordinates_ = estimatedcoordinatesData
  //   }

  //   let orderData_    = localStorage.getItem("order_data") || "{}"
  //   orderData_        = JSON.parse(orderData_)
  //   const product_id  = orderData_.dataProduct
  //   let key           = orderData_.geokey
  //   if (key){
  //     orderData_[key]    = polygonCoordinates_
  //   }
  //   let  selectedCatalogData = localStorage.getItem('selected_catalog') || "{}"
  //   selectedCatalogData = JSON.parse(selectedCatalogData)
  //   let host_name_ = ''
  //   if (selectedCatalogData?.hostName){
  //     host_name_ = selectedCatalogData?.hostName
  //   }

    
  //   let collection_name  =  localStorage.getItem('collection_name') || ""
  //   let product_type  =  localStorage.getItem('product_type') || ""
    
  //   let cloudCoverage = orderData_.cloudCoverage
  //   let resolution    = orderData_.resolution
  //   let startDate     = orderData_.startDate

  //   delete orderData_['cloudCoverage'];
  //   delete orderData_['resolution'];
  //   delete orderData_['startDate'];


  //   let params = {
  //     collection_name,
  //     cloudCoverage,
  //     resolution,
  //     startDate,
  //     product_type,
  //     host_name_,
  //     order_type:"catalog",
  //     aoi:"Polygon",
  //     params:{
  //       dataProduct:product_id,
  //       params:orderData_
  //     }
  //   }

  //   const token = localStorage.getItem('token');
  //   const headerOptions = {
  //       method: 'POST',
  //       headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Token ${token}`,
  //       },
  //       body:JSON.stringify(params)
  //   };
  //   try {
  //       const response = await fetch(APIs.ESTIMATE_ORDER, headerOptions);
  //       const {data,code,message } = await response.json();
  //       if (code == 200){
  //         setAllOrderParms(params)
  //         setEstimateCredit(data.cost)
  //         setAddToCart(true)
  //         setEstimateOrderLoader(false)
  //       } else {
  //           if (data){
  //             setEstimateCredit(data.cost)
  //             setError({...allError,cart:message})
  //           } else {
  //             setError({...allError,cart:message})
  //           }
  //           localStorage.removeItem('aoi_','aoi_value','product_type')
  //           setEstimateOrderLoader(false)
  //       }
  //   } catch (error) {
  //     setEstimateOrderLoader(false)
  //   }

  // }

  // const addToCartHandler = async()=>{
  //   setError({aoi:"",cart:""})
  //   const token = localStorage.getItem('token');
  //   const headerOptions = {
  //       method: 'POST',
  //       headers: {
  //           'Content-Type': 'application/json',
  //           'Authorization': `Token ${token}`,
  //       },
  //       body:JSON.stringify(allOrderParms)
  //   };
  //   try {
  //     const response = await fetch(APIs.PLACE_ORDER, headerOptions);
  //     const {data,code,message } = await response.json();
  //         if (code == 200){
  //           history.push('/data-collections');
  //           localStorage.removeItem("product_id")
  //           localStorage.removeItem("collection_name")
  //           localStorage.removeItem("product_type")
  //           localStorage.removeItem("aoi_")
  //           localStorage.removeItem("aoi_value")
  //         } else {
  //           setError({aoi:"",cart:message})
  //         }
  //   } catch (error) {
       
  //   }
  // }

  // const onChangeCommittedHanlder = (event,newValue) =>{
  //   setCloudCoverages(newValue);
  //   let dataType = []
  //   if (Object.keys(selectedCollectionData).length>0){
  //     dataType = selectedCollectionData
  //   } else{
  //     dataType = localStorage.getItem('selected_catalog') || "{}"
  //     dataType = JSON.parse(dataType)
  //     if (Object.keys(dataType).length>0){
  //       dataType = dataType
  //     }
  //   }
  //   let hostName_ = dataType.hostName
  //   let cloudCoverage_ = newValue[1] - newValue[0]
  //   productParamsHandler(false,collectionName_,hostName_,false,cloudCoverage_)
  //   localStorage.setItem('cloud',JSON.stringify(newValue))
  //   if (!showImageIdsContainer){
  //     setImageIdsContainer(true)
  //   }
  // }

  const onChangeCommittedHanlder = (event,newValue) =>{
    setCloudCoverages_(newValue);
  }

  const angleCommittedHanlder = (event,newValue) =>{
    setAngle_(newValue);
  }

  const drawPolygonHandler = () =>{
    let aoiInit = localStorage.getItem('aoidraw_enable') || '1'
    let data = {}
    let aoiIsSeletced =  true
    if (aoiInit == '0'){
      aoiInit = '1'
      aoiIsSeletced = false
    } else {
      aoiInit = '0'
    }
    data.aoiIsSeletced = aoiIsSeletced
    data.dataShow      = false
    orderImageHandler(data)
    localStorage.setItem('aoidraw_enable',aoiInit)
    // localStorage.setItem('aoi_selected','select')
  }


  const captureMethodHandler = (rid,value)=>{
    setCaptureMethodId_(rid)
    setCaptureMethodValue_(value)
    localStorage.setItem('capture_method',value)
  }

  const captureMethodImage_ = () =>{
    const imageUrl = `/up42/${dummyData.images[typeValue]}`;
    let imageUrlArray_ = [
      {rid:5,src:imageUrl,value:'SLC'},
      {rid:6,src:imageUrl,value:'GEC'},
      {rid:7,src:imageUrl,value:'GEO'},
      {rid:8,src:imageUrl,value:'SICD'},
    ]

    return(
      <Box className="captureMethod">
        {imageUrlArray_.map((item, index) => (
          <Box
            key={index}
            className="imageBox"
            style={{ backgroundImage: `url("${item.src}")` }}
            onClick={()=>{captureMethodHandler(item.rid,item.value)}}
          >
            <Box className="textBox">
              <input readOnly={true} value={item.value} type="text" className={"inputStyle" + (captureMethodValue_==item.value?" active":"" )}/>
            </Box>
            <Box className="radioBox">
              <input checked={(captureMethodValue_==item.value?true:false)}  className="inputRadio"  name="capture-method" type="radio" />
            </Box>
          </Box>
        ))}
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="row">
      <Box m={1} ml={1.7} mt={0} className={"containerBox"} style={{width:containerWidth}} >
        <div className="titleContainer">
          <span className="title">Order Image</span>
        </div>
        { showLoader ? 
          <Box className="loader_container">
            <CircularProgress className="loaderC"/>
          </Box> 
        :
          <>
            <div className="containerTabs">
              <div
                className={`screenTab ${activeTab === "newImagery" ? "active" : ""}`}
                onClick={() => handleTabClick("newImagery")}
              >
                New Imagery
              </div>
              <div
                style={{marginLeft:'10px'}}
                className={`screenTab ${
                  activeTab === "browseArchive" ? "active" : ""
                }`}
                onClick={() => handleTabClick("browseArchive")}
              >
                Browse Archive
              </div>
            </div>

            <Box className="panel_container">
              { activeTab == 'newImagery' ? 
                <>
                  {/* 'Type' section */}
                  <Box className="typeSection">

                    {/* Left Section */}
                    <Box className="leftSection">
                      <Box className="subTitle">
                        <span>Type:</span>
                      </Box>
                      {newImageryTypeSectionOption()}
                      {newImageryleftSectionParagraph()}
                    </Box>
                
                    {/* Right Section */}
                    <Box className="rightSection">
                      {rightSectionImage()}
                    </Box>
                  </Box>

                    
                  {/* Resolution */}
                  <Box className="resolutionSection">
                    <Box className="subTitle" mb={1}>
                      <span>Resolution:</span>
                    </Box>
                    {resolutionSectionImage()}
                  </Box>

                  {/*Select ID */}
                  {/* <Box className="interestSection">
                    <Box className="subTitle" mb={0.5}>
                      <span>Select Product Type:</span>
                      {newImageryProductTypes()}
                    </Box>
                  </Box> */}


                  {/*Area Of Interest */}
                  <Box className="interestSection">
                    <Box className="subTitle" mb={0.5}>
                      <span>Area of interest:</span>
                    </Box>
                    <Box className="aoi_container">
                      <Box className="aoi_box">
                        {newImageryAreaInterest()}
                      </Box>
                      <Box className="aoi_drawBox" onClick={()=>{drawPolygonHandler()}}>
                        <Box className="aoi_drawBoxbtn">
                          <img src={polyline}/>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  
                  {/* Date and Setting sections in one row */}
                  <Box display="flex" justifyContent="space-between">
                      {/* Date Filter */}

                      <Box className="dateFilter">
                        <Box className="subTitle">
                          <span>Date:</span>
                          <br />
                        </Box>
                        <span class="textStyle">Please select image date:</span>
                        <input type="date"  value={startDate_} class="dateInputStyle" placeholder="Start"  onChange={(event)=>handleDateChange(event,'start')}/>
                        <input type="date" value={endDate_} class="dateInputStyle" placeholder="End" onChange={(event)=>handleDateChange(event,'end')} />
                      </Box>

                      {/* Setting Section */}
                      <Box className="settingSection">

                       { typeValue != 'sar' ? 
                        <Box>
                          <Box className="subTitle">
                            <span>Settings:</span>
                            <br />
                            <span className=""></span>
                          </Box>
                          <Box className="slideContainer">
                            <p id="angle-slider" className="title3" gutterBottom>
                              Angle:
                            </p>
                            <Box className="imegarySlider">
                              <span className="silderValue sliderR">{angle_[0]}</span>
                                <Slider
                                  value={angle_}
                                  onChange={angleCommittedHanlder}
                                  // onChangeCommitted={angleCommittedHanlder}
                                  aria-labelledby="angle-slider"
                                  getAriaValueText={handleChangeAngle}
                                  valueLabelDisplay={false}
                                />
                              <span className="silderValue sliderL">{angle_[1]}</span>
                            </Box>
                          </Box>
                          <Box className="slideContainer" style={{marginTop:'5px'}}>
                            <p id="cloud-slider" className="title3" gutterBottom>
                              Cloud %:
                            </p>
                            <Box className="imegarySlider">
                              <span className="silderValue sliderR">{cloudCoverage_[0]}</span>
                              <Slider
                                value={cloudCoverage_}
                                onChange={onChangeCommittedHanlder}
                                // onChangeCommitted={onChangeCommittedHanlder}
                                valueLabelDisplay={false}
                                aria-labelledby="cloud-slider"
                                getAriaValueText={valuetext}
                              />
                              <span className="silderValue sliderL">{cloudCoverage_[1]}</span>
                            </Box>
                          </Box>
                          <Box>
                            <div className="checkboxContainer">
                              <input type="checkbox" id="customCheckbox" />
                              <label
                                htmlFor="customCheckbox"
                                className="squareCheckbox orderSquareCheckbox"
                              ></label>
                              <div className="checkboxText">Super Resolution</div>
                            </div>
                          </Box>
                        </Box>
                        :
                        <Box>
                          <Box className="subTitle">
                            <span>Capture method:</span>
                          </Box>
                          <Box>
                            {captureMethodImage_()}
                          </Box>
                        </Box>
                        }
                      </Box>
                  </Box>
                  
                  {/* CART section */}
                  <Box className="cartSection">
                    <Box className="subTitle" mt={1}>
                      <span class="textStyle w100">Price per km2: 10 Stellars:</span>
                      {/* <span>Total: {estimateCredit} Stellars</span> */}
                      <span>Total: 2000 Stellars</span>
                      { allError.cart && 
                        <div className="error_msg">{allError.cart}</div>
                      }
                    </Box>
                    <Box className="addToCartButton">
                      Add To Cart
                    </Box>
                    
                    {/* {
                      addToCart ?
                      <Box className="addToCartButton" onClick={()=>{addToCartHandler()}}>Add To Cart</Box>
                      :
                        <>
                          { estimateOrderLoader ?
                              <Box className="addToCartButton orderLoaderbtn">
                                <CircularProgress className="orderLoader"/>
                              </Box>
                            :
                            <Box className="addToCartButton" onClick={()=>{estimateOrderHandler()}}>Estimate Order</Box>
                          }
                        </>
                    } */}
                  </Box>
                </>
                :
                <Box>
                  <BrowseArchive setContainerWidth_={()=>{setContainerWidth('570px')}}  orderImageHandler_={orderImageHandler}/>
                </Box>
              }
            </Box>
          </>
        }
      </Box>
      { activeTab == 'newImagery' ?  newImageryImagesIds() : ''}
    </Box>
  );

};

export default OrderImageComponent;