import React, { useState, useCallback, useEffect } from 'react'
import { Box, Slider, Typography,CircularProgress } from '@material-ui/core'
import '../../../styles/BrowseArchive.css'
import { archiveData } from './BrowseArchiveDate.js'
import { newImageryData } from './OrderImageData.js'
import polyline from '../../../images/polyline.png'
import opticalResolutionImage from '../../../images/normalrgb.png'
import sarResolutionImage from '../../../images/sarcover.png'
import thermalResolutionImage from '../../../images/thermalcover.png'
import APIs from '../../../Api.js'



export default function BrowseArchive({ orderImageHandler_,setContainerWidth_}) {

  // +++++++++++++++ Force Update State +++++++++++++++++++
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);


  // +++++++++++++++ Archive Collection Data +++++++++++++++++++
  const [archiveCollectionData_, setArchiveCollectionData_] = useState(archiveData)


  // +++++++++++++++ Date State +++++++++++++++++++
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 25);
  const startDate_ = currentDate.toISOString().slice(0, 10);
  const endDate_ = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(startDate_)
  const [endDate, setEndDate] = useState(endDate_)


  // +++++++++++++++ Area Interest State +++++++++++++++++++
  const [aoiData_, setAoiData_] = useState(newImageryData)
  const storageAoiType_ = localStorage.getItem('aoi_selected') || 'select'
  const [aoiType_, setAoiType_] = useState(storageAoiType_)


  // +++++++++++++++ Angle State +++++++++++++++++++
  let archiveAngle_ = localStorage.getItem('archive_angle') || '[30,70]'
  archiveAngle_ = JSON.parse(archiveAngle_)
  const [angle_, setAngle_] = useState(archiveAngle_)


  // +++++++++++++++ Cloud State +++++++++++++++++++
  let cloudCoverages_ = localStorage.getItem('archive_cloud') || '[30,70]'
  cloudCoverages_ = JSON.parse(cloudCoverages_)
  const [cloudCoverages, setCloudCoverages] = useState(cloudCoverages_);


  // +++++++++++++++ Filter Date +++++++++++++++++++
  let filterData_ = localStorage.getItem('filter_date') || '{}'
  filterData_ = JSON.parse(filterData_)
  const [allfilterData_, setFilterData_] = useState(filterData_);


  // +++++++++++++++ Optical Resolution State +++++++++++++++++++
  let opticalResolutionData_ = localStorage.getItem('optical_resolution') || "[]"
  opticalResolutionData_ = JSON.parse(opticalResolutionData_)
  if (opticalResolutionData_.length == 0){
    opticalResolutionData_ = [{min:0,max:0.5,unit:"m"}]
  }
  const [opticalResolutionValue, setOpticalResolutionValue] = useState(opticalResolutionData_);


  // +++++++++++++++ Optical Satellite State +++++++++++++++++++
  let opticalSateliteData_  = localStorage.getItem('optical_satellite') || "select"
  const [opticalSatelliteValue, setOpticalSateliteValue] = useState(opticalSateliteData_);


  // +++++++++++++++ Optical Satellite Setting +++++++++++++++++++
  let opticalSateliteSettingData_  = localStorage.getItem('optical_satellite_setting') || "{}"
  opticalSateliteSettingData_ = JSON.parse(opticalSateliteSettingData_)
  const [opticalSatelliteSettingValue, setOpticalSateliteSettingValue] = useState(opticalSateliteSettingData_);

  let opticalSateliteSettingSelected_  = localStorage.getItem('optical_setting_selected') || "[]"
  opticalSateliteSettingSelected_ = JSON.parse(opticalSateliteSettingSelected_)
  const [opticalSelectedSettingValue_, setOpticalSelectedSettingValue_] = useState(opticalSateliteSettingSelected_);


  // +++++++++++++++ Sar Satellite State +++++++++++++++++++
  let sarSateliteMethod_  = localStorage.getItem('sar_satelite_method') || "[]"
  sarSateliteMethod_      = JSON.parse(sarSateliteMethod_)
  const [sarSateliteSMethodValue, setSateliteSMethodValue] = useState(sarSateliteMethod_);

  let sarSateliteResolution_  = localStorage.getItem('sar_satelite_resolution') || "~0.50m"
  const [sarSateliteResolution, setSarSateliteResolutione] = useState(sarSateliteResolution_);


  // +++++++++++++++ Thermal Satellite State +++++++++++++++++++
  let thermalSateliteResolution_  = localStorage.getItem('sar_thermal_resolution') || "~10cm"
  const [thermalSateliteResolution, setThermalSateliteResolutione] = useState(thermalSateliteResolution_);


  // +++++++++++++++ Archive Model State +++++++++++++++++++
  const [showArchiveModel, setShowArchiveModel] = useState(false);
  const [archiveModelData, setArchiveModelData] = useState([]);
  const [showArchiveModelLoader, setShowArchiveModelLoader] = useState(false);


  // +++++++++++++++ use Effect  +++++++++++++++++++
  useEffect(()=>{
    let filterData_ = allfilterData_
    if (Object.keys(filterData_).length == 0) {
      if (!filterData_.end){
        let cloudCoverages_ = cloudCoverages[1] - cloudCoverages[0]
        let angle           = angle_[1] - angle_[0]
        let filterInitData  =  {...allfilterData_,end:endDate_,start:startDate_,cloudCoverages:cloudCoverages_,angle}
        localStorageOperations("filter_date",'set','', filterInitData)
      } 
    }
 
  },[])




  // +++++++++++++++ local Storage Operations  +++++++++++++++++++
  const localStorageOperations = (key_ = "filter_date", type = 'get', returnType = "object", data_) => {
    if (type == 'get') {
      let data;
      if (returnType == 'object') {
        data = localStorage.getItem(key_) || '{}'
      } else {
        data = localStorage.getItem(key_) || '[]'
      }
      data = JSON.parse(data)
      return data
    } else if (type == 'set') {
      localStorage.setItem(key_, JSON.stringify(data_))
    } else if (type == 'remove'){
      localStorage.removeItem(key_)
    } else {
      //console.log();
    }
  }


  // +++++++++++++++ Date  +++++++++++++++++++
  const handleDateChange = (e, type) => {
    const date_ = e.target.value
    if (type == 'start') {
      setStartDate(date_);
    } else {
      setEndDate(date_);
    }
    const filterData_ = { ...allfilterData_, [type]: date_ }
    setFilterData_(filterData_)
    localStorageOperations('filter_date', 'set', '', filterData_)
    localStorageOperations('optical_satellite','remove')
    localStorageOperations('optical_satellite_setting','remove')
    setOpticalSateliteSettingValue({})
    setOpticalSateliteValue('select')
    localStorageOperations('optical_resolution','remove')
    setOpticalResolutionValue([{min:0,max:0.5,unit:"m"}])
    setShowArchiveModel(false)
  };


  // +++++++++++++++ Angle  +++++++++++++++++++
  const handleChangeAngle = (e) => {
    //console.log()
  };

  const angleCommittedHanlder = (event, newValue) => {
    setAngle_(newValue);
    localStorage.setItem('archive_angle', JSON.stringify(newValue))
    const angleValue = newValue[1] - newValue[0]
    const filterData_ = { ...allfilterData_, angle: angleValue }
    setFilterData_(filterData_)
    localStorageOperations('filter_date', 'set', '', filterData_)
    localStorageOperations('optical_satellite','remove')
    localStorageOperations('optical_satellite_setting','remove')
    setOpticalSateliteSettingValue({})
    setOpticalSateliteValue('select')
    localStorageOperations('optical_resolution','remove')
    setOpticalResolutionValue([{min:0,max:0.5,unit:"m"}])
    setShowArchiveModel(false)
  }


  // +++++++++++++++ Cloud  +++++++++++++++++++
  const handleChangeCloud = (e) => {
    //console.log()
  };

  const onChangeCommittedHanlder = (e, newValue) => {
    setCloudCoverages(newValue)
    localStorage.setItem('archive_cloud', JSON.stringify(newValue))
    const cloudValue = newValue[1] - newValue[0]
    const filterData_ = { ...allfilterData_, cloudCoverages: cloudValue }
    setFilterData_(filterData_)
    localStorageOperations('filter_date', 'set', '', filterData_)
    localStorageOperations('optical_satellite','remove')
    localStorageOperations('optical_satellite_setting','remove') 
    setOpticalSateliteSettingValue({})
    setOpticalSateliteValue('select')
    localStorageOperations('optical_resolution','remove')
    setOpticalResolutionValue([{min:0,max:0.5,unit:"m"}])
    setShowArchiveModel(false)
  };

  const valuetext = (value) => {
    return `${value}°C`;
  }


  // +++++++++++++++ Draw Polygon ++++++++++++++++++  
  const drawPolygonHandler = () => {
    let aoiInit = localStorage.getItem('aoidraw_enable') || '1'
    let data = {}
    let aoiIsSeletced = true
    if (aoiInit == '0') {
      aoiInit = '1'
      aoiIsSeletced = false
    } else {
      aoiInit = '0'
    }
    data.aoiIsSeletced = aoiIsSeletced
    data.dataShow = false
    orderImageHandler_(data)
    localStorage.setItem('aoidraw_enable', aoiInit)
    // localStorage.setItem('aoi_selected', 'select')
    localStorageOperations('optical_satellite','remove')
    localStorageOperations('optical_satellite_setting','remove')
    setOpticalSateliteSettingValue({})
    setOpticalSateliteValue('select')
    localStorageOperations('optical_resolution','remove')
    setOpticalResolutionValue([{min:0,max:0.5,unit:"m"}])
    setShowArchiveModel(false)
  }


  // +++++++++++++++ Area Interest +++++++++++++++++++  
  const archiveAreaInterestSelectHandler = (e) => {
    localStorage.setItem('aoidraw_enable', '1')
    const { value } = e.target
    if (value != 'select') {
      let aoiData = aoiData_.aoiTypeValue
      let data = aoiData[value]
      data.aoiIsSeletced = false
      data.dataShow = true
      orderImageHandler_(data)
      setAoiType_(value)
      localStorage.setItem('aoi_selected', value)
      localStorageOperations('optical_satellite','remove')
      localStorageOperations('optical_satellite_setting','remove')
      setOpticalSateliteSettingValue({})
      setOpticalSateliteValue('select')
      localStorageOperations('optical_resolution','remove')
      setOpticalResolutionValue([{min:0,max:0.5,unit:"m"}]) 
      setShowArchiveModel(false)
      localStorage.setItem('archive_coordinator',JSON.stringify(data.geometry))
    }
  }

  const archiveAreaInterest = () => {
    let aoiType = aoiData_.aoi
    return (
      <>
        <select className="selectListStyle" value={aoiType_} onChange={(e) => { archiveAreaInterestSelectHandler(e) }}>
          <option value='select'>Choose an area</option>
          {aoiType.map((item_, index) => {
            return (
              <option key={index} value={item_.value}>{item_.name}</option>
            )
          })}

        </select>
      </>
    )
  }

  
  // +++++++++++++++ Optical Filter +++++++++++++++++++ 
  const opticalResolutionHandler = (data) => {
    localStorageOperations('optical_satellite','remove')
    localStorageOperations('optical_satellite_setting','remove')
    setOpticalSateliteValue('select')
    // setShowArchiveModel(false)
    setOpticalSateliteSettingValue({})
    let opticalResolutionLocalData = opticalResolutionValue
    if (opticalResolutionLocalData.length > 0){
        let existData = opticalResolutionLocalData.filter((data_)=>{
          return data_.min == data.min && data_.max == data.max
        })
        existData.map((existItem,index)=>{
          let itemIndex = opticalResolutionLocalData.indexOf(existItem)
          opticalResolutionLocalData.splice(itemIndex,1)
        })
        if (existData.length > 0){
          setOpticalResolutionValue(opticalResolutionLocalData)
          localStorageOperations('optical_resolution', 'set', '', opticalResolutionLocalData)
        } else {
          let newFilterData = [...opticalResolutionLocalData,{ min: data.min, max: data.max, unit: data.unit }]
          setOpticalResolutionValue(newFilterData)
          localStorageOperations('optical_resolution', 'set', '', newFilterData)
        }
    } else {
      let opticalResolutionData = [{ min: data.min, max: data.max, unit: data.unit }]
      setOpticalResolutionValue(opticalResolutionData)
      localStorageOperations('optical_resolution', 'set', '', opticalResolutionData)
    }  
    forceUpdate()  
  }

  const opticalResolutionSectionImage = (data) => {
    const imageUrl = opticalResolutionImage;
    const imageUrlArray = [
      { rid: 1, src: imageUrl, value: '~0.50m', min: 0, max: 0.50, unit: "m" },
      { rid: 2, src: imageUrl, value: '~2m', min: 0.5, max: 2, unit: "m" },
      { rid: 3, src: imageUrl, value: '~20m', min: 2, max: 20, unit: "m" },
      { rid: 4, src: imageUrl, value: '~100m', min: 20, max: null, unit: "m" },
    ];

    return (
      <>
        {
          imageUrlArray.map((item_, index) => {
            let selectedOption = opticalResolutionValue.filter((selectedItem)=>{return item_.min ==selectedItem.min;})
            let isSelected = selectedOption.length>0?true:false
            return (
              <Box
                key={index}
                className="imageBox sargrid"
                style={{ backgroundImage: `url("${item_.src}")` }}
                onClick={() => { opticalResolutionHandler(item_) }}
              >
                <Box className="textBox">
                  <input readOnly={true} value={item_.value} type="text" className="inputStyle active" />
                </Box>
                <Box className="radioBox">
                  <input checked={isSelected} className="inputRadio" name="resolution" type="checkbox" />
                  <label checked={isSelected}></label>
                </Box>
              </Box>
            )
          })
        }
      </>
    )
  }

  const opticalOptionsHandler = (e,opticalProduct) => {
    const { value } = e.target
    if (value != 'select') {
      let filterOpticalData = opticalProduct.filter((product)=>{return product.collection==value})
      if (filterOpticalData.length > 0){
        localStorageOperations('optical_satellite_setting','set','',filterOpticalData[0])
        setOpticalSateliteSettingValue(filterOpticalData[0])
        let updateFilterDate = {...allfilterData_,collection:[{name:value,hostName:filterOpticalData[0].hostName}]}
        setFilterData_(updateFilterDate)
        let oldFilterData = localStorageOperations('filter_date','get','object',)
        if (oldFilterData.collection && oldFilterData.collection.length> 0){
          let filterCollectionData = [...oldFilterData.collection,{name:value,hostName:filterOpticalData[0].hostName}]
          updateFilterDate = {...allfilterData_,collection:filterCollectionData}
        }
        localStorageOperations('filter_date','set','',updateFilterDate)
      }
      localStorage.setItem('optical_satellite',value)
      setOpticalSateliteValue(value)
      setTimeout(() => {
        getArchiveCollection()
      }, 1000);
    }
  }

  const opticalOptions = () => {
    let opticalData = archiveCollectionData_.opticalData
    if (opticalResolutionValue.length > 0) {
      let filterData_ = []
      opticalResolutionValue.map((dateItem_)=>{
        let opticalFilterData_ =  opticalData.filter((item_)=>{return item_.maxresolution==dateItem_.max})
        filterData_.push(opticalFilterData_[0])
      })
      opticalData = filterData_
    } else {
      opticalData = []
    }

    let opticalProduct = []
    opticalData.map((item_, index) => {
      item_.data.map((collectionData, index) => {
        opticalProduct.push(collectionData)
      })
    })

    return (
      <select value={opticalSatelliteValue} onChange={(e)=>{opticalOptionsHandler(e,opticalProduct)}} className="archive_satellite">
        <option value="select">Choose Satellite</option>
        {
          opticalProduct.map((item_, index) => {
            return (
              <option key={index} value={item_.collection}>{item_.name}</option>
            )
          })
        }
      </select>
    )
  }


  // +++++++++++++++ Optical Filter  Setting +++++++++++++++++++ 
  const opticalSettingHandler = (data) =>{
    let opticalSelectedSettingValue = opticalSelectedSettingValue_
    if (opticalSelectedSettingValue.length > 0){
      let filterSetting = opticalSelectedSettingValue_.filter((item_)=>{
        return item_.productId == data.productId
      })
      if (filterSetting.length > 0){
        let itemIndex = -1
        itemIndex = opticalSelectedSettingValue.indexOf(filterSetting[0])
        if (itemIndex > -1){
          opticalSelectedSettingValue.splice(itemIndex,1)
          setOpticalSelectedSettingValue_(opticalSelectedSettingValue)
          localStorageOperations('optical_setting_selected','set','',opticalSelectedSettingValue)
        } else {
          const settingData =  [...opticalSelectedSettingValue,{...data}]
          setOpticalSelectedSettingValue_(settingData)
          localStorageOperations('optical_setting_selected','set','',settingData)
        }
      } else {
        const settingData =  [...opticalSelectedSettingValue,{...data}]
        setOpticalSelectedSettingValue_(settingData)
        localStorageOperations('optical_setting_selected','set','',settingData)
      }
    } else {
      setOpticalSelectedSettingValue_([data])
      localStorageOperations('optical_setting_selected','set','',[data])
    }
    forceUpdate()
  }
  
  const opticalFilterSetting = () =>{
    let opticalSetting = opticalSatelliteSettingValue.setting
    let isExistopticalSetting = true
    if (!opticalSetting){
      opticalSetting = []
      isExistopticalSetting = false
    }

    return(
      <>
        { isExistopticalSetting ? 
            opticalSetting.map((item,index)=>{
              const filterSetting = opticalSelectedSettingValue_.filter((settingItem,)=>{
                return settingItem.productId == item.productId
              })  
              let isSelected = false
              if (filterSetting.length>0){
                isSelected = true
              }

              return(
                <Box className='setting_option' onClick={()=>opticalSettingHandler(item)}>
                  <Box className='radioBox_'>
                    <input checked={isSelected} type='checkbox' className="inputRadio_" />
                    <label checked={isSelected}></label>
                  </Box>
                  <Typography className='setting_text'>{item.name}</Typography>
                </Box>
              )
            })
          :
          <>
            <Box className='setting_option'>
              <Box className='radioBox_'>
                <input checked={false} readOnly={true} type='checkbox' className="inputRadio_" />
                <label checked={false} readOnly={true}></label>
              </Box>
              <Typography className='setting_text'>Setting 1</Typography>
            </Box>
            <Box className='setting_option'>
              <Box className='radioBox_'>
                <input checked={false} readOnly={true} type='checkbox' className="inputRadio_" />
                <label checked={false} readOnly={true}></label>
              </Box>
            <Typography className='setting_text'>Setting 2</Typography>
            </Box>
            <Box className='setting_option'>
              <Box className='radioBox_'>
                <input checked={false}  readOnly={true} type='checkbox' className="inputRadio_" />
                <label checked={false}  readOnly={true}></label>
              </Box>
            <Typography className='setting_text'>Setting 3</Typography>
            </Box>
          </>
        }
      </>
    )
  }


  // +++++++++++++++ Sar Filter +++++++++++++++++++ 
  const captureMethodHandler = (data) => {
    let selectedCaptureMethod = sarSateliteSMethodValue
    let filterData = sarSateliteSMethodValue.filter((item_)=>{
      return data.collection == item_.collection
    })
    let itemIndex = -1
    if (filterData.length > 0){
      itemIndex = sarSateliteSMethodValue.indexOf(filterData[0])
    }
    let filterData_ = localStorageOperations('filter_date','get','object')
    let storageCollection = filterData_.collection
    if (!storageCollection){
      storageCollection = []
    }

    if (itemIndex > -1){
      selectedCaptureMethod.splice(itemIndex, 1); 
      setSateliteSMethodValue(selectedCaptureMethod)
      localStorageOperations('sar_satelite_method','set','',selectedCaptureMethod)
      
      let storagefilterCollection = storageCollection.filter((storageItem_)=>{return data.collection == storageItem_.name})

      let storageCollectionItemIndex = -1
      if (storagefilterCollection.length > 0){
        storageCollectionItemIndex = storageCollection.indexOf(storagefilterCollection[0])
      } 
      if (storageCollectionItemIndex > -1){
        storageCollection.splice(storageCollectionItemIndex, 1);
        let newCollectionData = {...filterData_,collection:storageCollection}
        localStorageOperations('filter_date','set','',newCollectionData)
      }   
      
    } else {
      let selectedCapturedMethod = [...selectedCaptureMethod,{collection:data.collection,hostName:data.hostName}]
      setSateliteSMethodValue(selectedCapturedMethod)
      localStorageOperations('sar_satelite_method','set','',selectedCapturedMethod)

      let newCollectionData_ = [...storageCollection,{name:data.collection,hostName:data.hostName}]
      let updatedFilterData = {...filterData_,collection:newCollectionData_}
      localStorageOperations('filter_date','set','',updatedFilterData)      
    }
    forceUpdate()
    setTimeout(() => {
      getArchiveCollection()
    }, 3000);
    
  }

  const captureMethodImage = () => {
    let sarData = archiveCollectionData_.sarData[0];
    return (
      <Box className='captureMethod archive_sargrid'>
        {
          sarData.data.map((item_, index) => {
            const imageUrl = `/up42/${item_.thumbnail}`;
            let sarSelectedMethod = sarSateliteSMethodValue.filter((seletedMethod,index)=>{
              return (seletedMethod.collection == item_.collection)
            })
            let isSelected = sarSelectedMethod.length > 0 ? true : false
            return (
              <Box
                key={index + new Date().getTime()}
                className="imageBox sargrid"
                style={{ backgroundImage: `url("${imageUrl}")` }}
                onClick={() => { captureMethodHandler(item_) }}
              >
                <Box className="textBox">
                  <input readOnly={true} value={item_.name} type="text" className="inputStyle active" />
                </Box>
                <Box className="radioBox">
                  <input checked={isSelected} className="inputRadio" name="resolution" type="checkbox" />
                  <label checked={isSelected}></label>
                </Box>
              </Box>
            )
          })
        }
      </Box>
    )
  }

  const sarResolutionHandler = (rid) => {
    setSarSateliteResolutione(rid)
    localStorageOperations('sar_satelite_resolution','set','',rid)
    getArchiveCollection()
  }

  const sarResolutionSectionImage = (data) => {
    const imageUrl = sarResolutionImage;
    const imageUrlArray = [
      { rid: 5, src: imageUrl, value: '~0.25m',min: 0, max: 0.50, unit: "m"  },
      { rid: 6, src: imageUrl, value: '~0.35m',min: 0, max: 0.50, unit: "m"  },
      { rid: 7, src: imageUrl, value: '~0.50m',min: 0, max: 0.50, unit: "m"  },
      { rid: 8, src: imageUrl, value: '~1.00m',min: 0.50, max: 1000, unit: "m"  },
    ];

    return (
      <>
        {imageUrlArray.map((item_, index) => (
          <Box
            key={index}
            className="imageBox sargrid"
            style={{ backgroundImage: `url("${item_.src}")` }}
            onClick={() => { sarResolutionHandler(item_.value) }}
          >
            <Box className="textBox">
              <input readOnly={true} value={item_.value} type="text" className="inputStyle active" />
            </Box>
            <Box className="radioBox">
              <input checked={sarSateliteResolution==item_.value?true:false} className="inputRadio" name="resolution" type="checkbox" />
              <label  checked={sarSateliteResolution==item_.value?true:false}></label>
            </Box>
          </Box>
        ))}
      </>
    )
  }


  // +++++++++++++++ Thermal Filter +++++++++++++++++++ 
  const thermalResolutionHandler = (value) => {
    localStorageOperations('sar_thermal_resolution','set','',value)
    setThermalSateliteResolutione(value)
  }

  const thermalResolutionSectionImage = () => {
    const imageUrl = thermalResolutionImage;
    const imageUrlArray = [
      { rid: 1, src: imageUrl, value:'~3m', value_:'~3m', min: 0, max: 0.50, unit: "m"   },
      { rid: 2, src: imageUrl, value:'~30m', value_:'~30m', min: 0, max: 0.50, unit: "m"   },
    ];

    return (
      <>
        {imageUrlArray.map((item_, index) => (
          <Box
            key={index}
            className="imageBox thermalgrid"
            style={{ backgroundImage: `url("${item_.src}")` }}
            onClick={() => { thermalResolutionHandler(item_.value_) }}
          >
            <Box className="textBox">
              <input readOnly={true} value={item_.value} type="text" className={"inputStyle active"} />
            </Box>
            <Box className="radioBox">
              <input checked={thermalSateliteResolution == item_.value_ ? true : false} className="inputRadio" name="resolution" type="checkbox" />
              <label checked={thermalSateliteResolution == item_.value_ ? true : false}></label>
            </Box>
          </Box>
        ))}
      </>
    )
  }


  // +++++++++++++++ Check filter data   +++++++++++++++++++
  const  filterDataCheck = () =>{
    let filterData_ = localStorageOperations('filter_date', 'get', 'object');
    let callAPi = true;
    if (Object.keys(filterData_).length > 0) {
      if (!filterData_.collection){
        callAPi = false;
      } else {
        if (filterData_.collection.length == 0 || !filterData_.start || !filterData_.end || !filterData_.cloudCoverages) {
          callAPi = false;
        }
      }
    
      if (!filterData_.cloudCoverages){
        localStorageOperations("filter_date",'set','', {...allfilterData_,cloudCoverages})
        callAPi =  true
      }

    } else {
      callAPi = false;
    }

    return callAPi
  }


  // +++++++++++++++ GET Archive Collections  +++++++++++++++++++
  const getArchiveCollection = async () => {
    setContainerWidth_()
    let callAPi = filterDataCheck()
    let coordinatorFilter = false
    let filterData_ = localStorageOperations('filter_date','get','object')
    if (!filterData_.collection){
      callAPi = false
    } else {
      if (filterData_.collection.length == 0){
        callAPi = false
      } 
    }
    let archiveCoordinator  = localStorage.getItem('archive_coordinator') || "{}"
    archiveCoordinator = JSON.parse(archiveCoordinator)
    if (Object.keys(archiveCoordinator).length > 0){
      coordinatorFilter  = true
      filterData_.intersects = archiveCoordinator 
    }

    filterData_.coordinator_filter = coordinatorFilter
    if (callAPi) {
      setShowArchiveModel(true)
      setShowArchiveModelLoader(true)
      const token = localStorage.getItem('token');
      const headerOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(filterData_),
      };
      try {
        const response = await fetch(APIs.GET_ARCHIVE_CATALOG, headerOptions);
        const { data, code, message } = await response.json();
        if (code === 200) {
          // setArchiveModelData([...archiveModelData,data])
          setArchiveModelData(data)
          setShowArchiveModelLoader(false)
        } else {
          setShowArchiveModelLoader(false)
          console.log(message, 'Error Messgae');
        }
      } catch (error) {
        setShowArchiveModelLoader(false)
        // Handle error
      }
    }
  }

  const roundToDecimalPlaces = (num, decimalPlaces) => {
    const factor = 10 ** decimalPlaces;
    return Math.round(num * factor) / factor;
  }
  

  // +++++++++++++++  Archive Collections Model  +++++++++++++++++++
  const imageHandler = (data) =>{
    data.aoiIsSeletced = false
    data.dataShow      = true
    data.zoomIn        = false
    localStorageOperations('optical_satellite_setting','remove')
    localStorageOperations('optical_satellite','remove')
    setOpticalSateliteValue('select')
    setOpticalSateliteSettingValue({})
    localStorageOperations('sar_satelite_method','remove')
    localStorageOperations('aoi_selected','remove')
    setAoiType_('select')
    localStorage.setItem('aoidraw_enable', '1')
    setSateliteSMethodValue([])
    orderImageHandler_(data)
  }

  const browseArchiveIds = () =>{
    
    return(
      <Box m={1} mt={1.2} className="containerBox imageContainer_">
        {
          showArchiveModelLoader ? 
            <Box className="loader_container">
              <CircularProgress className="loaderC"/>
            </Box> 
          :

          <> 
            <div className="titleContainer">
              <span className="title">Archive</span>
            </div>
            <Box className="archiveSection">
              <span className="image_title">Select an image:</span>
              {
                archiveModelData.length >0 ? 
                  archiveModelData.map((item_,index)=>{
                    const properties_ = item_.properties
                    const angle = roundToDecimalPlaces(properties_.providerProperties.incidenceAngle, 2);
                    const originalDate = new Date(properties_.acquisitionDate);
                    const formattedDate = `${originalDate.getDate()} - ${originalDate.getMonth()+1} - ${originalDate.getFullYear()}`;
                    return(
                      <div className="archiveItem" key={index} onClick={()=>{imageHandler(item_)}}>
                        <div className="imageContainer">
                          <img  src={`data:image/jpeg;base64,${item_.image_src.file[1]}`} alt="Image" />  
                        </div>
                        <div className="contentContainer"><div className="textSpan">Resolution: {properties_.resolution} cm</div>
                        <div className="textSpan">Captured: {formattedDate}</div>
                        <div className="textSpan">Angle: {angle}<sup className='angle_degree'>o</sup></div>
                        <div className="textSpan">Cloud: {properties_.cloudCoverage}%  </div></div>
                      </div>
                    )
                  })
              : 
              <div> Not Data Found </div>}
            </Box>
          </>
        }
      </Box>      
    )
  }




  // +++++++++++++++ Return  Jsx +++++++++++++++++++ 
  return (
    <Box className={"browse_archivecontainer"+(showArchiveModel?" active_archiveModel":"")}>
      <Box className="browse_archivetab">

        {/* General Filter*/}
        <Box className="general_filter">
          <Typography className='filter_text'><span></span>General Filter</Typography>
          {/* Date and Setting sections in one row */}
          <Box display="flex" justifyContent="space-between">
            {/* Date Filter */}
            <Box className="dateFilter">
              <Box className="subTitle">
                <span>Date:</span>
                <br />
              </Box>
              <span class="textStyle">Please select image date:</span>
              <input type="date" value={startDate} class="dateInputStyle" placeholder="Start" onChange={(event) => handleDateChange(event, 'start')} />
              <input type="date" value={endDate} class="dateInputStyle" placeholder="End" onChange={(event) => handleDateChange(event, 'end')} />
            </Box>

            {/* Setting Section */}
            <Box className="settingSection">
              <Box className="subTitle">
                <span>Settings:</span>
                <br />
                <span className=""></span>
              </Box>
              <Box className="slideContainer">
                <p id="angle-slider" className="title3" gutterBottom>
                  Angle:
                </p>
                <Box className="imegarySlider">
                  <span className="silderValue sliderR">{angle_[0]}</span>
                  <Slider
                    value={angle_}
                    onChange={angleCommittedHanlder}
                    // onChangeCommitted={angleCommittedHanlder}
                    valueLabelDisplay="auto"
                    aria-labelledby="angle-slider"
                    getAriaValueText={handleChangeAngle}
                  />
                  <span className="silderValue sliderL">{angle_[1]}</span>
                </Box>
              </Box>
              <Box className="slideContainer" style={{ marginTop: '5px' }}>
                <p id="cloud-slider" className="title3" gutterBottom>
                  Cloud %:
                </p>
                <Box className="imegarySlider">
                  <span className="silderValue sliderR">{cloudCoverages[0]}</span>
                  <Slider
                    value={cloudCoverages}
                    onChange={onChangeCommittedHanlder}
                    // onChangeCommitted={onChangeCommittedHanlder}
                    valueLabelDisplay="auto"
                    aria-labelledby="cloud-slider"
                    getAriaValueText={valuetext}
                  />
                  <span className="silderValue sliderL">{cloudCoverages[1]}</span>
                </Box>
              </Box>
              <Box>
                <div className="checkboxContainer">
                  <input type="checkbox" id="customCheckbox" />
                  <label htmlFor="customCheckbox" className="squareCheckbox"></label>
                  <div className="checkboxText">Super Resolution</div>
                </div>
              </Box>
            </Box>
          </Box>

          {/*Area Of Interest */}
          <Box className="interestSection">
            <Box className="subTitle" mb={0.5}>
              <span>Area of interest:</span>
            </Box>
            <Box className="aoi_container">
              <Box className="aoi_box">
                {archiveAreaInterest()}
              </Box>
              <Box className="aoi_drawBox" onClick={() => { drawPolygonHandler() }}>
                <Box className="aoi_drawBoxbtn">
                  <img src={polyline} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Collection Filter*/}
        <Box className="collection_filter">

          {/* Optical Filter */}
          <Box className='optical_filter'>
            {/* Resolution */}
            <Typography className='filter_text'><span></span>Optical Filter</Typography>
            <Box className="resolutionSection">
              <Box className="subTitle" mb={1}>
                <span>Resolution:</span>
              </Box>
              {opticalResolutionSectionImage()}
              <Box className="archive_satellite_selection" mt={1}>
                <Box className="subTitle" mb={0.5}>
                  <span>Satellite:</span>
                  <Box mt={1}>
                    {opticalOptions()}
                  </Box>
                </Box>
              </Box>
              <Box className="archive_satellite_selection" mt={1}>
                <Box className="subTitle" mb={0.5}>
                  <span>Setting:</span>
                  <Box mt={1} className='archive_filter_setting'>
                    <Box className='archive_setting_container'>
                      {opticalFilterSetting()}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Sar Filter */}
          <Box className='optical_filter archive_sar_filter'>
            <Typography className='filter_text'><span></span>SAR Filter</Typography>
            <Box className="resolutionSection">
              <Box className="subTitle">
                <span>Capture method:</span>
              </Box>
              {captureMethodImage()}
            </Box>
            <Box className="resolutionSection">
              <Box className="subTitle" mb={1}>
                <span>Resolution:</span>
              </Box>
              {sarResolutionSectionImage()}
            </Box>
          </Box>

          {/* Thermal Filter */}
          <Box className='optical_filter thermal_sar_filter'>
            <Typography className='filter_text'><span></span>Thermal Filter</Typography>
            <Box className="resolutionSection">
              <Box className="subTitle" mb={1}>
                <span>Resolution:</span>
              </Box>
              {thermalResolutionSectionImage()}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className='browse_archivemodel'>
        { showArchiveModel && 
          browseArchiveIds()
        }
      </Box>
    </Box>
  )
}
