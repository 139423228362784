import React, { useState  } from 'react';
import axios from 'axios';
import deleteIcon from '../images/delete-button.svg';
import "../styles/RasterUploadForm.css";
import APIs from '../Api';

function RastersUploadForm({ isVisible, rastersList, submitHandler, rasterClickHandler}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleUpload = (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append('raster_file', selectedFile);
        formData.append('token', token);
        setUploading(true);
        axios.post(APIs.RASTER_LIST, formData)
        .then(res => {
            submitHandler();
            setSelectedFile(null);
        })
        .catch((err) => console.log(err))
        .finally(() => setUploading(false));
    }

    const deleteRaster = (raster) => {
        var result = window.confirm(`This action will delete ${raster.file_name}, are you sure ?`);
        if (result) {
            axios.delete(APIs.RASTER_LIST+raster.id)
            .then(res => {
                submitHandler();
            })
            .catch((err) => console.log(err))
        }
    }

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleCancel = () => {
        setSelectedFile(null);
    };

    return (
        <>
            {
                isVisible &&
                <div className="container rasterUploadForm">
                    <div>
                        <h1 className="title">Upload a new satellite image</h1>
                        <div className="file-input-container">
                            {selectedFile ? (
                                <div className="file-input-preview">
                                    <span>{selectedFile.name}</span>
                                    <button className="file-input-button" onClick={handleCancel} disabled={uploading}>
                                        Cancel
                                    </button>
                                    <button className="file-input-button" onClick={handleUpload} disabled={uploading}>
                                        {uploading ? 'Uploading...' : 'Upload'}
                                    </button>
                                </div>
                            ) : (
                                <div className="file-input-preview">
                                    <span>Select a file</span>
                                    <label htmlFor="image" className="file-input-button" disabled={uploading}>
                                        Browse
                                    </label>
                                    <input
                                        type="file"
                                        id="image"
                                        name="raster_file"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        disabled={uploading}
                                    />
                                </div>
                            )}
                        </div>
                        {
                            rastersList && rastersList.length == 0 &&
                            <h1 style={{marginTop:"10px"}}>Upload your own geotiff raster file to apply detection on it.</h1>
                        }
                    </div>
                    {
                        rastersList && rastersList.length != 0 &&
                        <div style={{marginTop:"10px"}}>
                            <h1 className="title">Uploaded images</h1>
                            <ul className="rastersListStyle">
                                {
                                    rastersList.map((raster) => {
                                        return (
                                            <li>
                                                <h4 onClick={() => rasterClickHandler(raster.mb_tileset_id, "raster")}>
                                                    {`${raster.date} - ${raster.file_name} (${raster.status})`}
                                                </h4>
                                                <button type="button" className="deleteBtn" href="#" target="_blank" onClick={() => deleteRaster(raster)}>
                                                    <img className="deleteIcon" src={deleteIcon} />
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    }
                </div>
            }
        </>
    );
}

export default RastersUploadForm;
