import axios from 'axios';
import * as actionTypes from './actionTypes';
import APIs from '../../Api'



export const analyzeOrderLoader = (loderState) => {
    return {
        type: actionTypes.ANALYZE_ORDER_LODEAR,
        analyseLoader:loderState
    }
}

export const analyzeProjectSignal = (status) => {
    return {
        type: actionTypes.ANALYZE_PROJECT_SIGNAL,
        status:status
    }
}

export const analyzeProjectPopUpSignal = (popup_status) => {
    return {
        type: actionTypes.ANALYZE_POPUP_SHOW_SIGNAL,
        popup_status:popup_status
    }
}


export const analyzeOrderError = (msg) => {
    return {
        type: actionTypes.ANALYZE_ORDER_ERROR,
        msg
    }
}

export const analyzeOrder = (coordinates_) => {
    return {
        type: actionTypes.ANALYZE_ORDER,
        coordinates:coordinates_
    }
}

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = token => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('email');
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = expirationDate => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationDate * 1000)
    }
}

export const authLogin = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(APIs.LOGIN_API, {
            email: email,
            password: password
        })
        .then(res => {
          const token  = res.data.key;
          const expirationData = new Date(new Date().getTime() + 3600 * 1000)
          localStorage.setItem('token', token);
          localStorage.setItem('expirationDate', expirationData);
          localStorage.setItem('email', email);
          dispatch(authSuccess(token));
          dispatch(checkAuthTimeout(3600));
        })
        .catch(err => {
            dispatch(authFail(err))
        })
    }
}

export const authSignup = (
    email, 
    password1, 
    password2, 
    firstname,
    lastname,
    companyname,
    country
    ) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(APIs.SIGNUP_API, {
            email: email,
            password1: password1,
            password2: password2,
            firstname: firstname,
            lastname: lastname,
            companyname: companyname,
            country: country
        })
        .then(res => {
          const token  = res.data.key;
          const expirationData = new Date(new Date().getTime() + 3600 * 1000)
          localStorage.setItem('token', token);
          localStorage.setItem('expirationDate', expirationData);
          localStorage.setItem('email', email);
          dispatch(authSuccess(token));
          dispatch(checkAuthTimeout(3600));
        })
        .catch(err => {
            dispatch(authFail(err))
        })
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (token === undefined) {
            dispatch(logout());
        } else {
            const expirationData = new Date(localStorage.getItem('expirationDate'));
            if (expirationData <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout( (expirationData.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    }
}
