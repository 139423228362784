export const newImageryData = {
    type:[
        {name:"Normal RGB",value:"normalrgb"},
        {name:"SAR",value:"sar"},
        {name:"Thermal",value:"thermal"},
    ],
    images:{
        normalrgb:"normalrgb.png",
        sar:"sarcover.png",
        thermal:"thermalcover.png",
    },
    content:{
        normalrgb:"Lorem ipsum dolor sit amet consectetur. Amet feugiat vulputate hendrerit accumsan blandit sem felis fames tellus. Ac netus in amet dui nulla ac diam posuere. Vel sed eros fringilla facilisis adipiscing porttitor vel. Mauris volutpat turpis.",
        optical:"Lorem ipsum dolor sit amet consectetur. Amet feugiat vulputate hendrerit accumsan blandit sem felis fames tellus. Ac netus in amet dui nulla ac diam posuere. Vel sed eros fringilla facilisis adipiscing porttitor vel. Mauris volutpat turpis.",
        sar:"Lorem ipsum dolor sit amet consectetur. Amet feugiat vulputate hendrerit accumsan blandit sem felis fames tellus. Ac netus in amet dui nulla ac diam posuere. Vel sed eros fringilla facilisis adipiscing porttitor vel. Mauris volutpat turpis.",
        thermal:"Lorem ipsum dolor sit amet consectetur. Amet feugiat vulputate hendrerit accumsan blandit sem felis fames tellus. Ac netus in amet dui nulla ac diam posuere. Vel sed eros fringilla facilisis adipiscing porttitor vel. Mauris volutpat turpis.",
    },
    aoi:[
        {name:"Dubai",value:"dubai"},
        {name:"Riyadh",value:"riyadh"},
        {name:"Paris",value:"paris"},
        {name:"New York",value:"newyork"}
    ],
    aoiTypeValue:{
        dubai:{
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            54.74463718179653,
                            24.891856377755563
                        ],
                        [
                            55.73415415089634,
                            24.873309641327108
                        ],
                        [
                            55.75868762946919,
                            25.77879640172932
                        ],
                        [
                            54.75281500798682,
                            25.775114361040988
                        ],
                        [
                            54.74463718179653,
                            24.891856377755563
                        ]
                    ]
                ]
            },
            "properties": {
                "id": "4c90faa9-507b-41b2-9d0f-5ab4b6bb25c0",
                "constellation": "spot",
                "collection": "spot",
                "providerName": "oneatlas",
                "up42:usageType": [
                    "DATA"
                ],
                "providerProperties": {
                    "qualified": false,
                    "sourceIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "acquisitionStation": "SV1",
                    "archivingCenter": "FR1",
                    "lastUpdateDate": "2023-11-27T09:26:14.346557219Z",
                    "processingDate": "2023-11-26T13:57:11.523Z",
                    "parentIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "azimuthAngle": 180.01634984157405,
                    "title": "",
                    "workspaceTitle": "Public",
                    "productCategory": "image",
                    "spectralRange": "VISIBLE",
                    "activityId": "2871d2c6-72d1-4b16-aa62-a684ab078b44",
                    "processingLevel": "ALBUM",
                    "acquisitionIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "sensorType": "OPTICAL",
                    "productionStatus": "ARCHIVED",
                    "geometryCentroid": {
                        "lat": 25.211346019233744,
                        "lon": 53.6929655754223
                    },
                    "correlationId": "e41374d8-d0dc-4620-896d-98d683f60287",
                    "workspaceName": "public",
                    "processorName": "MMCAT",
                    "publicationDate": "2023-11-27T09:26:14.346557219Z",
                    "productType": "mono",
                    "illuminationElevationAngle": 16.219458656832728,
                    "workspaceId": "0e33eb50-3404-48ad-b835-b0b4b72a5625",
                    "expirationDate": "2223-10-10T09:22:29.468992418Z",
                    "incidenceAngleAcrossTrack": -14.258450470658444,
                    "format": "image/tiff",
                    "illuminationAzimuthAngle": 167.19044820267305,
                    "incidenceAngleAlongTrack": -9.229143296356984,
                    "incidenceAngle": 16.539283326759,
                    "archiveIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "processingCenter": "MOC",
                    "organisationName": "AIRBUS DS GEO",
                    "snowCover": 0
                },
                "sceneId": "4c90faa9-507b-41b2-9d0f-5ab4b6bb25c0",
                "producer": "airbus",
                "acquisitionDate": "2023-11-26T10:01:08.249Z",
                "start_datetime": null,
                "end_datetime": null,
                "cloudCoverage": 31.2,
                "resolution": 1.5,
                "deliveryTime": "HOURS"
            },
            "type": "Feature",
            "bbox": [
                12.813546555504491,
                51.906473484962824,
                13.766187370220909,
                52.95088361510117
            ]
        },
        riyadh:{
            "geometry": { 
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            46.016268798079146,
                            25.099471945449533
                        ],
                        [
                            46.01077563401696,
                            24.275954391514308
                        ],
                        [
                            47.449984618391646,
                            24.265939124867028
                        ],
                        [
                            47.43899829026728,
                            25.094497384843642
                        ],
                        [
                            46.016268798079146,
                            25.099471945449533
                        ]
                    ]
                ]
            },
            "properties": {
                "id": "4c90faa9-507b-41b2-9d0f-5ab4b6bb25c0",
                "constellation": "spot",
                "collection": "spot",
                "providerName": "oneatlas",
                "up42:usageType": [
                    "DATA"
                ],
                "providerProperties": {
                    "qualified": false,
                    "sourceIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "acquisitionStation": "SV1",
                    "archivingCenter": "FR1",
                    "lastUpdateDate": "2023-11-27T09:26:14.346557219Z",
                    "processingDate": "2023-11-26T13:57:11.523Z",
                    "parentIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "azimuthAngle": 180.01634984157405,
                    "title": "",
                    "workspaceTitle": "Public",
                    "productCategory": "image",
                    "spectralRange": "VISIBLE",
                    "activityId": "2871d2c6-72d1-4b16-aa62-a684ab078b44",
                    "processingLevel": "ALBUM",
                    "acquisitionIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "sensorType": "OPTICAL",
                    "productionStatus": "ARCHIVED",
                    "geometryCentroid": {
                        "lat": 24.39144479268129,
                        "lon": 45.523519742783954
                    },
                    "correlationId": "e41374d8-d0dc-4620-896d-98d683f60287",
                    "workspaceName": "public",
                    "processorName": "MMCAT",
                    "publicationDate": "2023-11-27T09:26:14.346557219Z",
                    "productType": "mono",
                    "illuminationElevationAngle": 16.219458656832728,
                    "workspaceId": "0e33eb50-3404-48ad-b835-b0b4b72a5625",
                    "expirationDate": "2223-10-10T09:22:29.468992418Z",
                    "incidenceAngleAcrossTrack": -14.258450470658444,
                    "format": "image/tiff",
                    "illuminationAzimuthAngle": 167.19044820267305,
                    "incidenceAngleAlongTrack": -9.229143296356984,
                    "incidenceAngle": 16.539283326759,
                    "archiveIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "processingCenter": "MOC",
                    "organisationName": "AIRBUS DS GEO",
                    "snowCover": 0
                },
                "sceneId": "4c90faa9-507b-41b2-9d0f-5ab4b6bb25c0",
                "producer": "airbus",
                "acquisitionDate": "2023-11-26T10:01:08.249Z",
                "start_datetime": null,
                "end_datetime": null,
                "cloudCoverage": 31.2,
                "resolution": 1.5,
                "deliveryTime": "HOURS"
            },
            "type": "Feature",
            "bbox": [
                12.813546555504491,
                51.906473484962824,
                13.766187370220909,
                52.95088361510117
            ]
        },
        paris:{
            "geometry": {   
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            1.432067571096702,
                            49.10333945101257
                        ],
                        [
                            1.4375607351589383,
                            48.21805915874694
                        ],
                        [
                            3.332702336721399,
                            48.214398946600106
                        ],
                        [
                            3.3491818289088977,
                            49.10333945101257
                        ],
                        [
                            1.432067571096702,
                            49.10333945101257
                        ]
                    ]
                ]
            },
            "properties": {
                "id": "07b-41b2-9d0f-5ab4b6bb25c0",
                "constellation": "spot",
                "collection": "spot",
                "providerName": "oneatlas",
                "up42:usageType": [
                    "DATA"
                ],
                "providerProperties": {
                    "qualified": false,
                    "sourceIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "acquisitionStation": "SV1",
                    "archivingCenter": "FR1",
                    "lastUpdateDate": "2023-11-27T09:26:14.346557219Z",
                    "processingDate": "2023-11-26T13:57:11.523Z",
                    "parentIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "azimuthAngle": 180.01634984157405,
                    "title": "",
                    "workspaceTitle": "Public",
                    "productCategory": "image",
                    "spectralRange": "VISIBLE",
                    "activityId": "2871d2c6-72d1-4b16-aa62-a684ab078b44",
                    "processingLevel": "ALBUM",
                    "acquisitionIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "sensorType": "OPTICAL",
                    "productionStatus": "ARCHIVED",
                    "geometryCentroid": {
                        "lat":48.61363861036861,
                        "lon":0.7426754812512015
                    },
                    "correlationId": "e41374d8-d0dc-4620-896d-98d683f60287",
                    "workspaceName": "public",
                    "processorName": "MMCAT",
                    "publicationDate": "2023-11-27T09:26:14.346557219Z",
                    "productType": "mono",
                    "illuminationElevationAngle": 16.219458656832728,
                    "workspaceId": "0e33eb50-3404-48ad-b835-b0b4b72a5625",
                    "expirationDate": "2223-10-10T09:22:29.468992418Z",
                    "incidenceAngleAcrossTrack": -14.258450470658444,
                    "format": "image/tiff",
                    "illuminationAzimuthAngle": 167.19044820267305,
                    "incidenceAngleAlongTrack": -9.229143296356984,
                    "incidenceAngle": 16.539283326759,
                    "archiveIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "processingCenter": "MOC",
                    "organisationName": "AIRBUS DS GEO",
                    "snowCover": 0
                },
                "sceneId": "4c90faa9-507b-41b2-9d0f-5ab4b6bb25c0",
                "producer": "airbus",
                "acquisitionDate": "2023-11-26T10:01:08.249Z",
                "start_datetime": null,
                "end_datetime": null,
                "cloudCoverage": 31.2,
                "resolution": 1.5,
                "deliveryTime": "HOURS"
            },
            "type": "Feature",
            "bbox": [
                12.813546555504491,
                51.906473484962824,
                13.766187370220909,
                52.95088361510117
            ]
        },
        newyork:{
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.69989389922024,
                            41.132848376535975
                        ],
                        [
                            -74.71088022734511,
                            40.22890349148231
                        ],
                        [
                            -72.71136850859541,
                            40.22890349148231
                        ],
                        [
                            -72.71136850859541,
                            41.12457310189462
                        ],
                        [
                            -74.69989389922024,
                            41.132848376535975
                        ]
                    ]
                ]
            },
            "properties": {
                "id": "9d0f-5ab4b6bb25c0",
                "constellation": "spot",
                "collection": "spot",
                "providerName": "oneatlas",
                "up42:usageType": [
                    "DATA"
                ],
                "providerProperties": {
                    "qualified": false,
                    "sourceIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "acquisitionStation": "SV1",
                    "archivingCenter": "FR1",
                    "lastUpdateDate": "2023-11-27T09:26:14.346557219Z",
                    "processingDate": "2023-11-26T13:57:11.523Z",
                    "parentIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "azimuthAngle": 180.01634984157405,
                    "title": "",
                    "workspaceTitle": "Public",
                    "productCategory": "image",
                    "spectralRange": "VISIBLE",
                    "activityId": "2871d2c6-72d1-4b16-aa62-a684ab078b44",
                    "processingLevel": "ALBUM",
                    "acquisitionIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "sensorType": "OPTICAL",
                    "productionStatus": "ARCHIVED",
                    "geometryCentroid": {
                        "lat":40.23938734931336,
                        "lon": -75.25745005156492
                    },
                    "correlationId": "e41374d8-d0dc-4620-896d-98d683f60287",
                    "workspaceName": "public",
                    "processorName": "MMCAT",
                    "publicationDate": "2023-11-27T09:26:14.346557219Z",
                    "productType": "mono",
                    "illuminationElevationAngle": 16.219458656832728,
                    "workspaceId": "0e33eb50-3404-48ad-b835-b0b4b72a5625",
                    "expirationDate": "2223-10-10T09:22:29.468992418Z",
                    "incidenceAngleAcrossTrack": -14.258450470658444,
                    "format": "image/tiff",
                    "illuminationAzimuthAngle": 167.19044820267305,
                    "incidenceAngleAlongTrack": -9.229143296356984,
                    "incidenceAngle": 16.539283326759,
                    "archiveIdentifier": "DS_SPOT6_202311261001082_FR1_FR1_SV1_SV1_E013N52_03251",
                    "processingCenter": "MOC",
                    "organisationName": "AIRBUS DS GEO",
                    "snowCover": 0
                },
                "sceneId": "4c90faa9-507b-41b2-9d0f-5ab4b6bb25c0",
                "producer": "airbus",
                "acquisitionDate": "2023-11-26T10:01:08.249Z",
                "start_datetime": null,
                "end_datetime": null,
                "cloudCoverage": 31.2,
                "resolution": 1.5,
                "deliveryTime": "HOURS"
            },
            "type": "Feature",
            "bbox": [
                12.813546555504491,
                51.906473484962824,
                13.766187370220909,
                52.95088361510117
            ]
        }
    }
}
