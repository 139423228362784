import React, { useState, useRef,useCallback, useEffect } from 'react';
import MapGL, { GeolocateControl, FullscreenControl, NavigationControl, ScaleControl } from 'react-map-gl';
import { Editor, DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import * as config from '../../config';
import 'mapbox-gl/dist/mapbox-gl.css';
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Geocoder from 'react-map-gl-geocoder'
import {Box,Grid,Typography} from '@material-ui/core';
import TopBar from './TopBar';
import '../../styles/Stellaria.css'
import { getFeatureStyle, getEditHandleStyle } from '../DrawStyle';
import { useDispatch,useSelector } from 'react-redux';
import { analyzeOrder ,analyzeOrderLoader,analyzeOrderError,analyzeProjectPopUpSignal} from '../../store/actions/auth';
import latlongIcon  from '../../images/nearIcon.png'
import shp from 'shpjs';
import * as turf from '@turf/turf';


const TOKEN = config.REACT_APP_TOKEN

export default function Index(props){
  const dispatch = useDispatch()
  const selector = useSelector((state=>state))
  const mapRef = useRef();
  const _editorRef = useRef();
  const [features, setFeatures] = useState([])
  const [polygonCoordinates, setCoordinates] = useState([])
  const [mode,setMode] = useState(null);
  const [selectedFeatureIndex,setSelectedFeatureIndex] = useState(null);
  const [aoiExtent, setaoiExtent] = useState('')
  const [drawerBbox, setDrawerBbox] = useState([])
  const [isShowPolygon,setShowPolygon] = useState(false)
  const [shapeFile,setShapeFile] = useState('')
  const [viewport, setViewport] = useState({
      width: "100%",
      height: "945px",
      latitude: 25.12,
      longitude: 55.13,
      zoom: 11,
  });

  // +++++++++++++++ Number of building State +++++++++++++++++++
  const  noOfBuilding  = localStorage.getItem('no_of_building') || '0'
  const  [noOfBuilding_,setNoOfBuilding] = useState(noOfBuilding)

  // +++++++++++++++ Number of building State +++++++++++++++++++
  const  noOfTotalAreaBuilding  = localStorage.getItem('area_of_building') || '0.0'
  const  [noOfTotalAreaBuilding_,setNoOfTotalAreaBuilding] = useState(noOfTotalAreaBuilding)

  let  aoiArea  = localStorage.getItem('aoi_area') || '0'
  const  [aoiArea_,setAoiArea_] = useState(aoiArea)

  let  popupSignal_  = localStorage.getItem('popup_signal') || '0'
  const  [popupSignal,setPopUpSignal_] = useState(popupSignal_)



  // useEffect(()=>{
  //   const map_  = mapRef.current.getMap()
  //   if (map_) {
  //     const polygonFeature = {
  //       type: "Feature",
  //       geometry: {
  //           type: "Polygon",
  //           coordinates: [
  //             [
  //               [
  //                   54.413451844939345,
  //                   24.432964560895357
  //               ],
  //               [
  //                   54.4101566787559,
  //                   24.424564084248633
  //               ],
  //               [
  //                   54.4170765277409,
  //                   24.42291392488739
  //               ],
  //               [
  //                   54.41954790237989,
  //                   24.430764490133605
  //               ],
  //               [
  //                   54.413451844939345,
  //                   24.432964560895357
  //               ]
  //             ]
  //           ]
  //       }
  //     }
  //     map_.on('load', () => {
  //       map_.addSource('selected-polygon', {
  //         type: 'geojson',
  //         data: {
  //           type: 'FeatureCollection',
  //           features: [polygonFeature]
  //         }
  //       });
       
  //       map_.addLayer({
  //         id: 'selected-polygon-layer',
  //         type: 'fill',
  //         source: 'selected-polygon',
  //         paint: {
  //           'fill-color': '#FF0000', // Red color for the polygon
  //           'fill-opacity': 1
  //         }
  //       });
  //     })    
  //   }
  // },[])

  useEffect(()=>{
      const  noOfBuilding_  = localStorage.getItem('no_of_building') || '0'
      setNoOfBuilding(noOfBuilding_)
      const  noOfTotalAreaBuilding_  = localStorage.getItem('area_of_building') || '0.0'
      setNoOfTotalAreaBuilding(noOfTotalAreaBuilding_)
      setPopUpSignal_(selector.popup_status)

      console.log(selector.popup_status,'selector.popup_status');
      
      if (selector.popup_status){
        localStorage.setItem('popup_signal','1')
      }
     

  },[selector])

  const maphandler = (data) =>{
    if (data){
      if (data.aoiIsSeletced){
        setShowPolygon(true)
      } else {
        setShowPolygon(false)
      }

      if (data.dataShow){
        let properties_ =  data.properties
        let imageCoordinates_ =  data.geometry.coordinates
        let geometry = properties_.providerProperties.geometryCentroid      
        let imageIdCoordinates = {...viewport,latitude:imageCoordinates_[0][0][1],longitude:imageCoordinates_[0][0][0]}
        if (properties_.providerProperties.geometryCentroid){
          imageIdCoordinates = {...viewport,latitude:geometry.lat,longitude:geometry.lon,zoom:7}
        }
        setViewport(imageIdCoordinates)
        showLayerHandler(imageCoordinates_[0],data)
      }

      if (data.showRaster){
        let imageIdCoordinates = {...viewport,latitude:data.lat,longitude:data.log,zoom:11}
        setViewport(imageIdCoordinates)
        showRaster_(data)
        setShapeFile(data.shpfile)
      }

      if (data.removeRaster){
        setFeatures([])
        setSelectedFeatureIndex(null)
        setDrawerBbox([])
        setCoordinates([])
        setViewport({...viewport,latitude:25.12,longitude:55.13,zoom:11})
        const map_ = mapRef.current.getMap()
        if (map_) { 

          // Remove Old Layer
          let oldRaterAiId_ = localStorage.getItem('ai_layer_id') || false
          
          if (map_.getLayer(oldRaterAiId_)) {
            map_.removeLayer(oldRaterAiId_);
            localStorage.removeItem('ai_layer_id')
          }

          let oldRaterId_ = localStorage.getItem('raster_id') || false
          if (map_.getLayer(oldRaterId_)) {
            map_.removeLayer(oldRaterId_);
            localStorage.removeItem('raster_id')
          }

        }
      }

    } else {
      const map_              = mapRef.current.getMap()
      if (map_) { 
        // Remove Old Layer
        let oldRaterId_ = localStorage.getItem('ai_layer_id') || false
        if (map_.getLayer(oldRaterId_)) {
          map_.removeLayer(oldRaterId_);
        }
         // Remove Old Layer
         let oldLayerId_ = localStorage.getItem('layer_id') || false
         if (map_.getLayer(oldLayerId_)) {
           map_.removeLayer(oldLayerId_);
         }
      }
    
      setShowPolygon(false)
      setFeatures([])
      setViewport({...viewport,latitude:25.12,longitude:55.13,zoom:11})
    }
  }

  const handleViewportChange = (newViewport) => {
    setViewport(newViewport)
  }

  const showLayerHandler = (imageCoordinates_,data_) =>{
    let uuId_ = new Date().getSeconds()
    const cityCoordinates_  = imageCoordinates_
    const newLayerId_       = `square-border-${uuId_}`
    const map_              = mapRef.current.getMap()

    if (map_) { 
      // Remove Old Layer
      let OldlayerId_ = localStorage.getItem('layer_id') || false
      if (OldlayerId_){
        map_.removeLayer(OldlayerId_)
      }
      
      localStorage.setItem('layer_id',newLayerId_)

      // // Draw Square
      map_.addLayer({
          id: newLayerId_,
          type: 'fill',
          source: {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [cityCoordinates_],
                },
            },
          },
          layout: {},
          paint: {
            'fill-color': 'rgba(14, 98, 232, 0.25)',
            'fill-opacity': 1,
            'fill-outline-color': '#0E62E8', 
          },
      });
      
      for (const [index, coordinate] of cityCoordinates_.entries()) {
        map_.jumpTo({ center: coordinate, zoom: 7 });
      }
      
    }
  }
  
  function generateShortUUID() {
    const array = new Uint8Array(4);
    crypto.getRandomValues(array);
    const shortUUID = Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
    return shortUUID;
  }
  
  const  analyzeAiModel = (data_) =>{
    dispatch(analyzeOrder(data_));
  }
  
  const  showRaster_ = (data) => {
    let uuId_  = generateShortUUID();
    const newRasterId_      = `raster-layer-${uuId_}-${data.id}`
    const map_              = mapRef.current.getMap()
    if (map_) { 
      // Remove Old Raster
      let oldRaterId_ = localStorage.getItem('raster_id') || false
      if (oldRaterId_){
        map_.removeLayer(oldRaterId_)
      }
      localStorage.setItem('raster_id',newRasterId_)

      map_.addSource(newRasterId_, {
        'type': 'raster',
        'url': `mapbox://${data.rasterId}`
      });
      map_.addLayer({
        'id': newRasterId_,
        'source': newRasterId_,
        'type': 'raster',
      });
    }
  }

  const _onSelect = (options) =>{
    setSelectedFeatureIndex(options.selectedFeatureIndex)
  }

  const _onUpdate = ({ data, editType }) =>{
    if (editType === 'addFeature') {
      localStorage.removeItem('aoi_area')
      setCoordinates(data[0].geometry.coordinates)
      const extent = data[0].geometry.coordinates[0].reduce(
          (acc, [x, y]) => {
              return {
                  xmin: Math.min(acc.xmin, x),
                  ymin: Math.min(acc.ymin, y),
                  xmax: Math.max(acc.xmax, x),
                  ymax: Math.max(acc.ymax, y),
              };
          },
          {
              xmin: Infinity,
              ymin: Infinity,
              xmax: -Infinity,
              ymax: -Infinity,
          }
      );
        
      const aoiExtent_  = `${extent.xmin.toFixed(4)} ${extent.ymin.toFixed(4)} ${extent.xmax.toFixed(4)} ${extent.ymax.toFixed(4)}`
      const drawerBbox_ = [parseFloat(extent.xmin.toFixed(4)), parseFloat(extent.ymin.toFixed(4)), parseFloat(extent.xmax.toFixed(4)), parseFloat(extent.ymax.toFixed(4))]
      setMode(new EditingMode())
      setFeatures(data)
      setaoiExtent(aoiExtent_)
      setDrawerBbox(drawerBbox_)
      analyzeAiModel(drawerBbox_)
      let mapZoom = localStorage.getItem('map_zoom') || '10'
      setViewport({...viewport,zoom:parseInt(mapZoom)})
      localStorage.setItem('archive_coordinator',JSON.stringify(data[0].geometry))

      const polygonFeature = turf.polygon(data[0].geometry.coordinates);
      const areaInSquareMeters = turf.area(polygonFeature);
      const areaInSquareKilometers = areaInSquareMeters / 1e6;
      localStorage.setItem('aoi_area',areaInSquareKilometers.toFixed(2))
      setAoiArea_(areaInSquareKilometers.toFixed(2))
    
    }
  }

  const _onDelete = () => {
    const selectedIndex = selectedFeatureIndex || 0;
    if (selectedIndex !== null && selectedIndex >= 0) {
      _editorRef.current.deleteFeatures(selectedIndex);
      setMode(null)
      setDrawerBbox([])
      setaoiExtent('')
      setFeatures([])
      setSelectedFeatureIndex(null)
    }
  };

  const _renderDrawTools = () => {
    return (
        <div className="mapboxgl-ctrl-top-right">
            <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
                <button
                    className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
                    title="Polygon tool (p)"
                    onClick={drawPolygonHandler}
                />
                <button
                    className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
                    title="Delete"
                    onClick={_onDelete}
                />
            </div>
        </div>
    );
  };

  const drawPolygonHandler = () =>{
    if (_editorRef && features.length > 0) {
      _editorRef.current.deleteFeatures(0);
      setMode(null)
      setDrawerBbox([])
      setaoiExtent('')
      setFeatures([])
      setSelectedFeatureIndex(null)
    }
    setMode(new DrawPolygonMode())
  }

  const handleGeocoderViewportChange = useCallback((newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };
      return handleViewportChange({ ...newViewport,...geocoderDefaultOverrides});
    },[handleViewportChange]
  );

  const analyzeOrder_ = () =>{
    dispatch(analyzeOrder(polygonCoordinates));
    if (features.length > 0){
      aiModelanalyze(features)
    }
  }

  const aiModelanalyze = async(polygonFeature) =>{
    localStorage.setItem('popup_signal','0')
    localStorage.setItem('no_of_building','0')
    localStorage.setItem('area_of_building','0')
    setNoOfTotalAreaBuilding(0)
    setPopUpSignal_(false)
    setNoOfBuilding(0)
    dispatch(analyzeProjectPopUpSignal(false))
    dispatch(analyzeOrderLoader(true))
    dispatch(analyzeOrderError(''))
    let uuId_  = generateShortUUID();
    const newRasterId_      = `ai-layer-${uuId_}`
    const map_              = mapRef.current.getMap()
    if (map_) { 
      // Remove Old Layer
      let oldRaterId_ = localStorage.getItem('ai_layer_id') || false
      if (map_.getLayer(oldRaterId_)) {
        map_.removeLayer(oldRaterId_);
      }
      localStorage.setItem('ai_layer_id',newRasterId_)
      const image = window.location.origin+"/up42/"+shapeFile; 
      try {
        await shp(image).then((geojson) => {
          setTimeout(async()=>{ 
            try {
              const polygon_ = features[0].geometry.coordinates;
              const drawnPolygon = turf.polygon(polygon_);
              let selectedFeatures = []

              selectedFeatures = geojson.features.filter((feature) => {
                if (feature.geometry && feature.geometry.coordinates){
                  if (feature.geometry.coordinates[0].length > 3){
                    const featurePolygon = turf.polygon(feature.geometry.coordinates);
                    return turf.booleanWithin(featurePolygon, drawnPolygon);
                  }
                }  
                return false
              });
              
              if (selectedFeatures.length > 0){

                let selectedObject = selectedFeatures.length
                localStorage.setItem('no_of_building',selectedObject)
                setNoOfBuilding(selectedObject)

                let totalArea = 0;
                selectedFeatures.forEach((feature) => {
                    if (feature.geometry && feature.geometry.coordinates){
                        const featurePolygon = turf.polygon(feature.geometry.coordinates);
                        const areaInSquareMeters = turf.area(featurePolygon);
                        totalArea += areaInSquareMeters;
                    }
                });
                const totalAreaInKm2 = totalArea / 1e6;
                localStorage.setItem('area_of_building',totalAreaInKm2)

                try {
                  setNoOfTotalAreaBuilding(totalAreaInKm2.split('.')[0])
                } catch (error) {
                  setNoOfTotalAreaBuilding(totalAreaInKm2)
                }
              

                let selectedFeatures_ = []
                selectedFeatures.map((item_,index)=>{
                  item_.properties = []
                  selectedFeatures_.push(item_)
                })   
                
                
                map_.addLayer({
                  id: newRasterId_,
                  type: 'fill',
                  source: {
                    type: 'geojson',
                    data: {
                      type: 'FeatureCollection',
                      features: selectedFeatures_,
                    },
                  },
                  paint: {
                    'fill-color': '#c2395b',
                    'fill-opacity': 0.75,
                  },
                });
              } else {
                dispatch(analyzeOrderError('There is no result for selected area.'))
              }
            } catch (error) {
              dispatch(analyzeOrderLoader(false))
              dispatch(analyzeOrderError('There is no result for selected area.'))
            }
            dispatch(analyzeOrderLoader(false))
            dispatch(analyzeProjectPopUpSignal(true))
          },5000)
        });
        
      }catch (error) {
        console.log(error,'error 1');
        dispatch(analyzeOrderError('There is no result for selected area.'))
      }
    }
  }

  const updateHeightHandler = (type) =>{
    // let height = '1000px'
    // if (type != 'newImagery'){
    //     height = '2000px'
    // }
    // setViewport({...viewport,height})
  }

  let popupTextName = localStorage.getItem('popup_text_name') || ''

  return (
    <Box m={0} display={"flex"}>
      <Box className='left-overlay'>
        <TopBar updateHeight_={(type)=>{updateHeightHandler(type)}} polygonCoordinates_={polygonCoordinates} handler_={(data)=>maphandler(data)} analyzeOrderHandler={()=>{analyzeOrder_()}}/>
      </Box>
      <MapGL
        ref={mapRef}
        {...viewport}
        mapboxApiAccessToken={TOKEN}
        mapStyle="mapbox://styles/hassanms/ckhakjywz52lh19of76vsm4wn"
        onViewportChange={handleViewportChange}
      >
      
        <Geocoder
          mapRef={mapRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={TOKEN}
          position="top-right"
        />


        {popupSignal &&
          <Box className="mapboxgl-ctrl-top-right customBoxAoi" style={styleSx}>
            <Box display={"flex"} flexWrap={'wrap'} style={{width:'319px'}}>
              <Box display={"flex"}>
                <Box>
                  <Typography component={"span"}>Number of {popupTextName.toLocaleLowerCase()
                  }:</Typography>
                </Box>
                <Box pl={"14px"}>
                  <Typography component={"span"}>{noOfBuilding_}</Typography>
                </Box>
              </Box>
              <Box display={"flex"} m={'9px 0px'}>
                <Box>
                  <Typography component={"span"}>Area of select AOI:</Typography>
                </Box>
                <Box pl={"29px"}>
                  <Typography component={"span"}>{aoiArea_}km<sup>2</sup></Typography>
                </Box>
              </Box>
              <Box display={"flex"}>
                <Box >
                  <Typography component={"span"}>{popupTextName} area:</Typography>
                </Box>
                <Box pl={"65px"}>
                  <Typography component={"span"}>{noOfTotalAreaBuilding_}km<sup>2</sup></Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        }


        { isShowPolygon &&
          <>
          <Editor
            ref={_editorRef}
            style={{ width: '100%', height: '100%' }}
            clickRadius={12}
            mode={mode}
            onSelect={_onSelect}
            onUpdate={_onUpdate}
            featureStyle={getFeatureStyle}
            editHandleStyle={getEditHandleStyle}
            editHandleShape={'circle'}
            features={features}
            
          />

          <div className="navigationControl drawerPo">
            {_renderDrawTools()}
          </div>
          </>
        }
        <div className="navigationControl">
          <NavigationControl/>
        </div>

        <div className="fullScreenControl">
          <FullscreenControl />
        </div>
        <div className="bottomBar">
          <div className="latLong">
            <div className='latlongIcon'>
              <img src={latlongIcon}/>
            </div>
            <div className="latitude">
              {viewport.latitude.toFixed(5)}
              <span className="latlongcangle" >o</span>
              <span className="latitudeN">N</span>
            </div>,
            <div className="longitude">
                {viewport.longitude.toFixed(5)}
                <span className="latlongcangle" >o</span>
              <span className="latitudeN">E</span>
            </div>
          </div>
          <span style={{marginRight:'30px'}}><FullscreenControl  /></span>
          <ScaleControl className='scaleControl'/>
        </div>
      </MapGL>
    </Box>
  )
}
;


let styleSx = {
  top: 'calc(100% - 868px)',
  right: '154px',
  right: '10px',
  background: '#fff',
  padding: '20px',
  borderRadius: '10px',
}