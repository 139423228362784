import React,{useState,useEffect} from 'react';
import "../../styles/CollectionsDetail.css";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Backdrops from './BackDrop.js'
import CustomModal from './CustomModal'
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import APIs from '../../Api.js';

const useStyles = makeStyles({
    button:{
      padding:13,
      borderRadius:0,
      background: 'linear-gradient( 135deg, rgb(49, 0, 62) 0%, rgb(195, 40, 110) 100% )'
    }
});

const CollectionDetail = ({ collection,type }) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    const startDates = currentDate.toISOString().slice(0, 10);
    const [endDate, setEndDate] = useState(startDates);
    const [startDate, setStartDate] = useState(startDates);
    const [orderData,SetOrderData] = useState({})
    const [productCollectionData,SetProductCollectionData] = useState('')
    const [showBackDrop,SetBackDrop] = useState(false)
    const [productType,SetProductType] = useState('select')
    const [productID,SetProductID] = useState('')
    const [imagesIDs,SetImagesIDs] = useState([])
    const classes = useStyles();
    const [openModel,SetOpenModel] = useState(false)
    const [loader,SetLoader] = useState(false)
    const [linkID,SetLinkID] = useState('')
    const [IdsData,SetIdsData] = useState('')
    const [orderBtnText,SetOrderBtnText] = useState('Place Order')
    const [orderloader,SetOrderloader] = useState(false)
    const [coordinatorData,SetCoordinatorData] = useState([{lat:'',long:''}])
    const [error,SetError] = useState('')
    const [orderError,SetPlaceOrderError] = useState('')
    const [collectionName_,SetCollectionName] = useState('')
    const [estimateOrderLoader,SetEstimateOrderloader] = useState(false)
    const [estimateOrderCost,SetEstimateOrderCost] = useState('')
    const [paymentInfo,SetPaymentInfo] = useState('')
    const [enabelPlaceOrder,SetEnabelPlaceOrder] = useState(false)
    
    useEffect(()=>{
        paymentInfoHandler()
    },[])

    const paymentInfoHandler = () =>{
        let paymentData = localStorage.getItem('subscribe') || false
        if (paymentData){
            paymentData = JSON.parse(paymentData)
        } 
        SetPaymentInfo(paymentData)
    }
 
    const selectForm = (option)=>{
        return (
            <>
                { option.key == 'geometry' ?
                    <>
                        <select onChange={(event)=>geoMetryTypeHandler(event,option.data)}>
                        <option value='none'>Select</option>
                        { option.data[0].map((item,index)=>{
                                return (
                                    <option key={index} value={item.const}>{item.title}</option>
                                )
                            }) 
                        }
                        </select>
                        {coordinatorFieldForm(option)}
                    </>
                    :
                    <select onChange={(event)=>orderParamHandler(event,option)} value={orderData[option.key]}>
                    <option value='none'>Select</option>
                        { option.data[0].map((item,index)=>{
                            return (
                                <option key={index} value={item.const}>{item.title}</option>
                            )
                            }) 
                        }
                    </select>
                }
            </>
        )
    }

    const selectImageIdHandler = (event,data)=>{
        SetOpenModel(true)
        SetIdsData(data)
    }   

    const textForm = (data)=>{
        let key = data.key
        return (
            <>
                {  key == 'id'?
                    <span className='parms_id'>
                        <input readOnly={true} value={orderData[data.key]} type="text" />
                        <Button size="medium" variant="contained" color="secondary" onClick={(event)=>selectImageIdHandler(event,data)}>Select Id</Button>
                    </span>
                    :
                    <input onChange={(event)=>orderParamHandler(event,data)} value={orderData[data.key]} type="text" />
                }
            </>
            
        )
    }

    const dateTimeForm = (data)=>{
        return (
            <input  onChange={(event)=>orderParamHandler(event,data)} value={orderData[data.key]} type="date"/>
        )
    }

    const orderParamHandler = (event,data)=>{
        SetPlaceOrderError("")
        let values  = event.target.value
        if (event.target.type == 'number'){
            if (values.indexOf(".") !== -1) {
                values = parseFloat(values)
            } else {
                values = parseInt(values)
            }
        }
        let key  = data.key
        let updateParms = {...orderData,[key]:values}
        SetOrderData(updateParms)        
    }

    const integerForm = (data)=>{
        let value_ =orderData[data.key]
        let setp_ = 1
        if (value_ != '')
            if (Number.isInteger(data.maximum)){
                value_ = parseInt(value_)
                setp_ = 1
            } else {
                value_ = parseFloat(value_)
                setp_=0.1
            }
        return (
            <input type="number" step={setp_} onChange={(event)=>orderParamHandler(event,data)} value={value_} min={data.minimum} max={data.maximum}/>
        )
    }

    const geoMetryTypeHandler = (event)=>{ 
        let values  = event.target.value
        let updateParms = {...orderData,type:values}
        SetOrderData(updateParms)

    }

    const coordinatorParamHandler  = (event,data,index)=>{
        let valueKey = event.target.name
        coordinatorData[index][valueKey] = event.target.value
        SetCoordinatorData([...coordinatorData])   
        let key = data.key
        let coordinatorData_ = []
        let tempCoordinatorData_ = []
        coordinatorData.map((item,index)=>{
            let lat = item.lat;
            let long = item.long;
            tempCoordinatorData_.push([parseFloat(lat),parseFloat(long)])
        })
        
        let type = orderData.type
        if (type == 'Point'){
            coordinatorData_  = tempCoordinatorData_
        } else {
            coordinatorData_ = [tempCoordinatorData_]
        }
        orderData.geokey = key
        orderData[key] = coordinatorData_
        SetOrderData({...orderData})
    }
    
    const createCoordinatorParamHandler  = ()=>{
        SetCoordinatorData([...coordinatorData,{lat:'',long:''}])
    }

    const removeCoordinatorParamHandler = (event,data,index)=>{
        const updatedCoordinatorData = [...coordinatorData];
        updatedCoordinatorData.splice(index, 1);
        SetCoordinatorData(updatedCoordinatorData);
    
        let key = data.key
        let coordinatorData_ = []
        let tempCoordinatorData_ = []
        updatedCoordinatorData.map((item,index)=>{
            let lat = item.lat;
            let long = item.long;
            tempCoordinatorData_.push([parseFloat(lat),parseFloat(long)])
        })
        let type = orderData.type
        if (type == 'Point'){
            coordinatorData  = tempCoordinatorData_
        } else {
            coordinatorData_ = [tempCoordinatorData_]
        }
        orderData.geokey = key
        orderData[key] = coordinatorData_

        SetOrderData({...orderData})
    }

    const coordinatorFieldForm = (data)=>{
        return(
            <div>
                    <div className='latlong_label cood_form'>
                        <label>latitude</label>
                        <label>longitude</label>
                    </div>
                    <div>
                        {   
                            
                            coordinatorData.map((item,index)=>{
                                return(
                                    <div className='latlong_label'>
                                        <span>
                                            <input value={item.lat} name="lat" onChange={(event)=>coordinatorParamHandler(event,data,index)} style={{height:28}}></input>
                                        </span>
                                        <span>
                                            <input value={item.long} name="long" onChange={(event)=>coordinatorParamHandler(event,data,index)} style={{height:28}}></input>
                                        </span>
                                        { index == 0 &&
                                            <span><AddIcon onClick={()=>createCoordinatorParamHandler()}/></span>
                                        }
                                        { index != 0 &&
                                            <span><RemoveIcon onClick={(event)=>removeCoordinatorParamHandler(event,data,index)}/></span>
                                        }   
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                </div>
        )
    }

    const coordinatesForm = (data)=>{
        return (
            <>   
                <select onChange={(event)=>geoMetryTypeHandler(event,data.data)}>
                    <option value='none'>Select</option>
                    { 
                        data.selection.map((item,index)=>{
                            return (
                                <option key={index} value={item}>{item}</option>
                            )
                        })

                    }
                </select>
                {coordinatorFieldForm(data)}
            </>
        )
    }

    const formTypes = (key,data)=>{
        switch (key) {
            case 'select':
                return (selectForm(data))
                break;
            case 'text':
                return (textForm(data))
                break;
            case 'date-time':
                return (dateTimeForm(data))
                break;
            case 'integer':
                return (integerForm(data))
                break;
            case 'coordinates':
                return (coordinatesForm(data))
            break;
            default:
                break;
        }
    }

    const collectionForm = (params)=>{
        let properties = params.properties;
        let definitions = params.definitions;
        return(
            <div className="order-form">
                { Object.keys(properties).map((item,index)=>{
                    let  data = properties[item]
                    data['key']      = item
                    if (data.allOf){
                        
                        let coordinatesData = []
                        let allOfData =  data.allOf
                        allOfData.map((allOfItem,index)=>{
                            let allOfDef = allOfItem.$ref.replace('#/definitions/','');
                            const definitionsData = definitions[allOfDef]
                            if (definitionsData.anyOf){
                                coordinatesData.push(definitionsData.anyOf)
                            } else{
                                coordinatesData.push([definitionsData])   
                            }
                                              
                        })
                        data.input_type   = 'select'
                        data['data']      = coordinatesData
                        

                    } else if (data.anyOf){
                        let  anyOfData =  data.anyOf
                        let coordinatesData = []
                        let coordinatesSelection = []
                        anyOfData.map((anyOfItem,index)=>{
                            let anyOfDef = anyOfItem.$ref.replace('#/definitions/','');
                            const definitionsData = definitions[anyOfDef]
                            if (definitionsData.properties){
                                coordinatesData.push(definitionsData)
                                coordinatesSelection.push(anyOfDef)
                            }
                        })
                        data.input_type   = 'coordinates'
                        data['data']      = coordinatesData
                        data['selection'] = coordinatesSelection

                    } else if (data.$ref){
                        let refDef = data.$ref.replace('#/definitions/','');
                        const definitionsData = definitions[refDef]
                        let coordinatesData = [definitionsData]
                        let coordinatesSelection = [refDef]
                        data.input_type   = 'coordinates'
                        data['data']      = coordinatesData
                        data['selection'] = coordinatesSelection
                        data['title']   = item.toUpperCase()
                    }
                    else { 
                        if (data.format){
                            if (data.format == 'date-time'){
                                data['input_type'] = 'date-time'
                                data['value'] = ''
                            } else{

                            }
                        } else if (data.type == 'string'){
                            data['input_type'] = 'text'
                            data['value'] = ''
                        } else if (data.type == 'integer'  || data.type == 'number'){
                            data['input_type'] = 'integer'
                            data['value'] = 0
                        } 
                    }
                    return(
                        <div key={index}>
                            <label>
                                {data.title}                               
                            </label>
                            {formTypes(data.input_type,data)}
                        </div>
                    )
                })}
            </div>
        ) 
    }

    const placeOrderHandler = async(event)=>{
        SetPlaceOrderError('')
        SetOrderloader(true)
        let product_id = orderData.dataProduct
        let params = {
            product_type:productType,
            order_type:type,
            collection_name:collectionName_,
            params:{
                dataProduct:product_id,
                params:orderData
            }
        }
        console.log(collection,params);
        const token = localStorage.getItem('token');
        const headerOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body:JSON.stringify(params)
        };
        try {
        const response = await fetch(APIs.PLACE_ORDER, headerOptions);
        const {data,code,message } = await response.json();
            if (code == 200){
                SetOrderBtnText('Order Placed Successful')
                SetOrderData({})
            } else{
                SetPlaceOrderError(message)
                SetOrderData({})
            }
            SetOrderloader(false)
            SetOrderData({})
        } catch (error) {
            SetOrderloader(false)
            SetOrderData({})
        }
    }

    const productTypeHandler = (event)=>{
        let value = event.target.value
        let dataProductIds = collection.data_ids
        let collectionData = collection.collection_name
        if (value != 'select'){
            SetImagesIDs([])
            setEndDate(startDates)
            setStartDate(startDates)
            SetProductType(value)
            SetLinkID('')
            SetProductID(dataProductIds[value])
            productParamsHandler(dataProductIds[value],collectionData[value])
            SetCollectionName(collectionData[value])
        }
    }

    const productParamsHandler = async (productId,collection_name,fetchType='self') => {
        let  productId_ =  productID 
        if (productId && productId !=""){
            productId_ = productId
        }  

        SetBackDrop(true)
        const token = localStorage.getItem('token');
        const headerOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
            },
        };
        let payload = {hostName:collection.hostName,collectionName:collection_name,start:startDate,end:endDate,date:true};

        if (type == 'tasking'){
            payload.hostName = false
            payload.collectionName = false
            payload.date = false
        }
        
        try {
            const url = new URL(APIs.GET_PRODUCT_PARAMS+productId_);
            url.search = new URLSearchParams(payload).toString();
            const response = await fetch(url, headerOptions);
            const {code,data } = await response.json();
            if (code == 200){
                let scene_data = data.scene_data 
                if (scene_data.length > 0){
                    let imagesData = []
                    scene_data.map((item,index)=>{
                        let ids = item.properties.id
                        imagesData.push({value:ids,const:ids})
                    })
                   
                    SetImagesIDs(imagesData)
                    SetLinkID(data.linkid)
                } else{
                    if (fetchType!='self'){
                        SetError("Ids not found this date range")
                    }
                }
                let properties = data.properties
                let allKeys = {type:'',dataProduct:productId_,'geokey':''}
                Object.keys(properties).map((item,index)=>{
                    allKeys[item] = ''
                })
                SetBackDrop(false)
                SetOrderData(allKeys)
                SetProductCollectionData(data);
                SetLoader(false)
            } else {
                SetBackDrop(false)
                SetLoader(false)
            }
            
        } catch (error) {
            SetBackDrop(false)  
            SetLoader(false)
        }
    }

    const productTypeForm = (collection)=>{
        let dataTypes = collection.data_type
        return(
            <> 
                <select value={productType} onChange={(event)=> productTypeHandler(event)}>
                    <option value="select">Select</option>
                { 
                    dataTypes.map((item,index)=>{
                        return (
                            <option value={item.value}>{item.name}</option>
                        )
                    })
                }
                </select>
            </>
        )
    }

    const handleDateChange = (e,type) => {
        SetError("")
        if (type == 'start'){
            setStartDate(e.target.value);
        } else {
            setEndDate(e.target.value);
        }
        
    };

    const dateRangeForm = (collection)=>{
        return(
            <>  
                { type == 'catalog' &&
                    <>
                        <h4 className="collection-section">Date Range</h4>
                        <div className='date_range'>
                            <label>Start date</label>
                            <input onChange={(event)=>handleDateChange(event,'start')} value={startDate} type='date' name="start_date"></input>
                        </div>
                        <div className='date_range'>
                            <label>End date</label>
                            <input type='date' onChange={(event)=>handleDateChange(event,'end')} value={endDate} name="end_date"></input>
                        </div>
                    </>
                }
            </>
        )
    }

    const prductInfo =()=>{
        let productInfo = collection.info
        return(
            <>
                {
                    Object.keys(productInfo).map((item,index)=>{
                        let productData = productInfo[item]
                        return(
                            <div className='section_name' key={index}>
                                <h2>{productData.name}</h2>
                                <div className='section_content'>
                                    { productData.content 
                                        ?
                                            <p>{productData.content}</p>
                                        :
                                        <div className='section_data'>
                                            { productData.section ?
                                                productData.section.map((sectionItem,index)=>{
                                                    return(
                                                        <div key={index} className='section_data_container'>
                                                            <h2>{sectionItem.name}</h2>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{sectionItem.head.name}</th>
                                                                        <th>{sectionItem.head.content}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        sectionItem.data.map((sectionDataItem,index)=>{
                                                                            return(
                                                                                <tr key={index}>
                                                                                    <td><p>{sectionDataItem.name}</p></td>
                                                                                    <td>
                                                                                        <ul>
                                                                                            {
                                                                                                sectionDataItem.content!='' ?
                                                                                                sectionDataItem.content.map((contentItem,index)=>{
                                                                                                    return(
                                                                                                        <li key={index}>{contentItem}</li>
                                                                                                    )
                                                                                                })
                                                                                                :
                                                                                                sectionDataItem.data.map((contentDataItem,index)=>{
                                                                                                    return(
                                                                                                        <>
                                                                                                        <li key={index}>{contentDataItem.name}</li>
                                                                                                            <ul className='innerUl'>
                                                                                                                {
                                                                                                                    contentDataItem.content.map((contentItems,index)=>{
                                                                                                                        return(
                                                                                                                            <li key={index}>{contentItems}</li>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </ul>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            } 
                                                                                        </ul>  
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    } 
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })
                                            :
                                            productData.data.map((dataItem,index)=>{
                                                return(
                                                    <>
                                                    <div>{dataItem.name}</div>
                                                    <ul className='innerUl'>
                                                        { dataItem.content.map((contentItem,index)=>{
                                                            return(
                                                                <li>{contentItem}</li>  
                                                            )
                                                        })}
                                                    </ul>
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
            </>
        )
    }

    const closeImageIdModel = () => {
        SetOpenModel(false)
    }
 
    const loadMoreIdHandler = async(event,linkid) => {
        SetLoader(true)
        const token = localStorage.getItem('token');
        const headerOptions = {
        method: 'GET',
        headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
        };
        let payload = {hostName:collection.hostName,collectionName:collectionName_};
        try {
            const url = new URL(APIs.GET_SCENE_IDS+linkid);
            url.search = new URLSearchParams(payload).toString();
            const response = await fetch(url, headerOptions);
            const {code,data } = await response.json();
            if (code == 200){
                let scene_data = data.scene_data 
                if (scene_data.length > 0){
                    let imagesData = []
                    scene_data.map((item,index)=>{
                        let ids = item.properties.id
                        imagesData.push({value:ids,const:ids})
                    })
                    let allData = [...imagesIDs,...imagesData]
                    SetImagesIDs(allData)
                    SetLinkID(data.linkid)
                } else{
                    SetError("Ids not found this date range")
                }
                SetLoader(false)
                
            } else {
                SetLoader(false)
                //console.log()
            }
        } catch (error) {
            SetLoader(false)
          //console.log()
        }
    }

    const getIdHandler = (event) => {
        SetLoader(true)
        productParamsHandler('',collectionName_,'model')
    }
    
    const selectedIdHandler = (event)=>{
        event.preventDefault();
        let updatedData_ = {...orderData,[IdsData.key]:event.target.value}
        SetOrderData(updatedData_)
        SetOpenModel(false)
    }

    const estimateOrderHandler = async(event)=>{
        SetPlaceOrderError('')
        SetEstimateOrderloader(true)
        let product_id = orderData.dataProduct
        let params = {order_type:type,params:{dataProduct:product_id,params:orderData}}
        const token = localStorage.getItem('token');
        const headerOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body:JSON.stringify(params)
        };
        try {
            const response = await fetch(APIs.ESTIMATE_ORDER, headerOptions);
            const {data,code,message } = await response.json();
            if (code == 200){
                SetEstimateOrderCost('Order Cost:- '+data.cost)
                SetEstimateOrderloader(false)
                SetEnabelPlaceOrder(true)
            } else{
                SetPlaceOrderError(message)
                SetEstimateOrderloader(false)
                SetEnabelPlaceOrder(false)
                SetEstimateOrderCost('')
            }
        } catch (error) {
            SetEstimateOrderloader(false)
            SetEnabelPlaceOrder(true)
            SetEstimateOrderCost('')
        }
    }

    return (
        <div>
            <h1 className="list-collection-title">{collection.title}</h1>
            <p>{collection.description}</p>
            <img src={`/up42/${collection.thumbnail}`} alt="Thumbnail" />
            <div className='product_info'>
                {prductInfo()}
            </div>            
            <div className="product_type">
                <h4 className="collection-section">Product Type</h4>
                {productTypeForm(collection)}
            </div>
            <div className="product_parms">
                {
                    productCollectionData &&
                    <> 
                        <h4 className="collection-section">Order product</h4>
                        {collectionForm(productCollectionData)}
                        {orderError && <div className='error'>{orderError}</div>}
                        { estimateOrderCost && <div className='order_cost'>{estimateOrderCost}</div>}
                        <div className="order_place">
                            <Button 
                            className={classes.button} 
                            onClick={(event)=>estimateOrderHandler(event)} 
                            size="medium" 
                            style={{width:"20%",opacity:0.6,marginRight:50,borderRadius:5}} variant="contained" color="secondary">
                                {
                                    estimateOrderLoader ?
                                        <CircularProgress/>
                                    :
                                        'ESTIMATE ORDER'
                                }
                            </Button>

                            {
                                enabelPlaceOrder &&
                                <Button 
                                    className={classes.button} 
                                    onClick={(event)=>orderBtnText=='Place Order'&&placeOrderHandler(event)} size="medium" 
                                    style={{width:"25%",opacity:0.6,borderRadius:5}} variant="contained" color="secondary"
                                >
                                    {
                                        orderloader ? <CircularProgress/> : orderBtnText
                                    }  
                                </Button>
                            }
                        </div>
                    </>
                }
            </div>
            <Backdrops open={showBackDrop}/>
            <CustomModal open={openModel} title='Select Image ID' codeModelHandel={closeImageIdModel} >
                <div className='imageid_model' >
                    {dateRangeForm(collection)}

                    <div className='image_ids'>
                        { imagesIDs.length > 0 &&
                            <select onChange={(event)=>selectedIdHandler(event)}>
                                <option value='none' onChange={(event)=>()=>{}}>Select</option>
                                    {
                                        imagesIDs.map((imageItem,index)=>{
                                            return(
                                                <option value={imageItem.value}>{imageItem.const}</option>
                                            )
                                        })
                                    }
                            </select>
                        }       
                    </div>
                </div>
                {error && <span className='error'>{error.error}</span>}
                <div className='update_btn'>
                    {  
                        
                        <Button onClick={(event)=> linkID!=''? loadMoreIdHandler(event,linkID):getIdHandler(event)} size="medium" variant="contained" color="secondary">
                            {
                                linkID != '' ? 
                                    loader ?
                                        <CircularProgress/>
                                    :
                                    "LOAD MORE"
                                :
                                    loader ?
                                    <CircularProgress/>
                                    :
                                    'GET IDS'
                            }
                        </Button>
                    }
                    
                </div>
            </CustomModal>
        </div>
    )
};

export default CollectionDetail;