import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: '11/4/2023',
    pv: 2400
  },
  {
    name: '12/4/2023',
    pv: 1398
  },
  {
    name: '13/4/2023',
    pv: 9800
  },
  {
    name: '14/4/2023',
    pv: 3908
  },
  {
    name: '15/4/2023',
    pv: 4800,
  }
];

export default function GraphView() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={530}
          height={300}
          data={data}
          margin={{
            top: 0,
            right: 5,
            left: -14,
            bottom: -7,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"  horizontalCoordinatesGenerator={(props) => props.height > 250 ? [75, 150, 225] : [100, 200]}   />
          <XAxis dataKey="name"  tickLine={false} axisLine={false} />
          <YAxis tickLine={false}  axisLine={false}  />
          <Line type="monotone" dataKey="pv" stroke="#0E62E8" strokeWidth={3}  dot={false}/>
        </LineChart>
      </ResponsiveContainer>
    );
  }
