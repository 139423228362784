import React,{useEffect, useState} from 'react';
import { Layout, Menu, } from 'antd';
import { BrowserRouter, Redirect, Link, Route,useHistory } from 'react-router-dom';
import * as actions from '../store/actions/auth';
import { connect } from 'react-redux';
import Explore from './Explore';
import Draw from './Draw';
import Swipe from './Swipe';
import DataCollections from './DataCollections';
import Stellaria from './stellaria/Index'


import logo from '../images/logo-white.png';
import menu1 from '../images/1.PNG';
import menu2 from '../images/2.PNG';
import menu3 from '../images/3.PNG';
import menu4 from '../images/4.PNG';
import menu5 from '../images/5.PNG';
import menu6 from '../images/6.PNG';


const { Header, Content, Sider } = Layout;

const Home = (props) => {
    const [isShowHeaderSideBar,setShowHeaderSideBar] = useState(true)

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 25);
    const startDate_ = currentDate.toISOString().slice(0, 10);
    const endDate_ = new Date().toISOString().slice(0, 10);

    useEffect(()=>{
        setShowHeaderSideBar(true)
        localStorage.removeItem('aoi_selected')
        localStorage.removeItem('optical_satellite')
        localStorage.removeItem('optical_satellite_setting')
        localStorage.removeItem('optical_resolution')
        localStorage.removeItem('archive_angle')
        localStorage.removeItem('archive_aoi_selected')
        let filterDate = {"start": startDate_,"end": endDate_,"angle": 40,"cloudCoverages": 40}
        localStorage.setItem('filter_date',JSON.stringify(filterDate))
        localStorage.removeItem('cloud')
        localStorage.removeItem('archive_cloud')
        localStorage.removeItem('type_value')
        localStorage.removeItem('selected_catalog') 
        localStorage.removeItem('archive_aoidraw_enable')
        localStorage.removeItem('sar_satelite_method')
        localStorage.removeItem('sar_satelite_resolution')
        localStorage.removeItem('optical_setting_selected')
        localStorage.removeItem('ai_detection_image')
        localStorage.removeItem('ai_detection_aoi')
        localStorage.removeItem('ai_model_init')
        localStorage.removeItem('model_active')
        localStorage.removeItem('show_ai_models')
        localStorage.setItem('show_footprint_model_','0')
        localStorage.setItem('show_life_model_','0')
        localStorage.removeItem('archive_coordinator')
        localStorage.removeItem('ai_contaner_class')
        localStorage.removeItem('aoi_selected_image')

        // localStorage.setItem('',1) 
        // localStorage.setItem('',1) 
        // localStorage.setItem('',JSON.stringify({"id":1,"rasterId":"hassanms.adm_q4_final_1"}))
        
    },[])
    
    const StellariaView =() =>{
        setShowHeaderSideBar(false)
        return(
            <Stellaria />
        )
    }
    return (
        props.isAuthenticated ?
            <div style={{ margin: '0 auto'}}>
                <BrowserRouter>
                <Route exact path="/" component={StellariaView} />
                {/* { isShowHeaderSideBar && 
                    <Layout style={{height: "100vh"}}>
                        <Header style={{backgroundColor: "#015553"}}>
                            <Menu mode="horizontal" style={{backgroundColor: "#015553", borderBottom: "1px solid #015553", height: "99.9%"}}>
                                <Menu.Item><img src={logo} width={150} alt="white-logo" /></Menu.Item>
                                <Menu.Item></Menu.Item> 
                                <Menu.Item style={{float: "right"}} onClick={props.logout}>
                                    <Link to='/login' style={{color: "white"}}>Logout</Link>
                                </Menu.Item>
                                <Menu.Item style={{float: "right"}}>
                                    <p style={{color: "white"}}>Logged in as  <span style={{fontFamily: "sans-serif", fontWeight: "bold"}}>{localStorage.getItem('email')}</span></p>
                                </Menu.Item>
                            </Menu>
                        </Header>
                        <Layout>
                            <Sider style={{backgroundColor: "#015553"}} width={100} className="site-layout-background">
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={["1"]}
                                    defaultOpenKeys={['sub1']}
                                    style={{ height: '100%', borderRight: 0, backgroundColor: "#041620" }}
                                >
                                    <Menu.Item key="1" style={{height: "15%", content: menu1, backgroundColor: "#041620"}}>
                                        <img style={{width: "100%"}} src={menu1} alt="menu1"/>
                                        <Link to="/home"/>
                                    </Menu.Item>
                                    <Menu.Item key="2" style={{height: "15%", content: menu2, backgroundColor: "#041620"}}>
                                        <img style={{width: "100%"}} src={menu2} alt="menu2"/>
                                        <Link to="/swipe"/>
                                    </Menu.Item>
                                    <Menu.Item key="3" style={{height: "15%", content: menu3, backgroundColor: "#041620"}}>
                                        <img style={{width: "100%"}} src={menu3} alt="menu3"/>
                                        <Link to="/draw"/>
                                    </Menu.Item>
                                    <Menu.Item key="4" style={{height: "15%", content: menu4, backgroundColor: "#041620"}}>
                                        <img style={{width: "100%"}} src={menu4} alt="menu4"/>
                                        <Link to="/data-collections"/>
                                    </Menu.Item>
                                    <Menu.Item key="5" style={{height: "15%", content: menu5, backgroundColor: "#041620"}}>
                                        <img style={{width: "100%"}} src={menu5} alt="menu5"/>
                                        <Link to="/data-collections"/>
                                    </Menu.Item>
                                    <Menu.Item key="6" style={{height: "15%", content: menu6, backgroundColor: "#041620"}}>
                                        <img style={{width: "100%"}} src={menu6} alt="menu6"/>
                                        <Link to="/stellaria"/>
                                    </Menu.Item>
                                </Menu>
                            </Sider>
                            <Layout style={{backgroundColor: "#000f16"}}>
                                <Content
                                    className="site-layout-background"
                                    style={{ backgroundColor: "#000f16"}}
                                >
                                    <Route exact path="/home" component={StellariaView} />
                                    <Route exact path="/swipe" component={Swipe} />
                                    <Route exact path="/draw" component={Draw} />
                                    <Route exact path="/data-collections" component={DataCollections} />
                                </Content>   
                            </Layout>
                        </Layout>
                    </Layout>    
                } */}
                </BrowserRouter>
            </div>
        : <Redirect to='/login'/>    
    )     
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}

export default connect(null, mapDispatchToProps)(Home);  