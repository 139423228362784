import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Box,CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import APIs from '../../../../Api'

export default function RenameDialog({data,open,onClose}) {
  const [name,setName] = useState(data.name)
  const [loader,setLoader] = useState(false)
  const [btnName,setBtnName] = useState('Save')
  
  const handleClose = () => {
    onClose();
  };

  const nameHandler = (e) => {
    setName(e.target.value)
  }

  const saveHandler = async(e) =>{
      setLoader(true)
      const token = localStorage.getItem('token');
      const headerOptions = {
        method: 'PATCH',
        body:JSON.stringify({name:name,id:data.id}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
      };
      const response = await fetch(APIs.PLACE_FOOTPRINT, headerOptions);
      await response.json();
      setBtnName('Success')
      setLoader(false) 
      setTimeout(()=>{
        handleClose()
      },900)  
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
        <Box className='rename_container'>
            <CloseIcon className='modelCloseBtn' onClick={handleClose}/>
            <Box className="vaultBox" mt={1}>
                <Box display="flex" flexDirection="column">
                    <Box className="subTitle" mb={1} width={"100%"} display={"block"}>
                        <span>Name:</span>
                    </Box>
                    <div className="searchBox">
                        <input
                            className="searchInput"
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e)=>{nameHandler(e)}}
                        />
                    </div>
                </Box>
            </Box>
            <Box className="pattrenLife" mt={1}>
                {
                   loader ? 
                    <Box className="addToCartButton">
                      <CircularProgress/>
                    </Box>
                  :
                  <Box className="addToCartButton" onClick={()=>{saveHandler()}}>{btnName}</Box>
                }
            </Box>
        </Box>
    </Dialog>
  );
}

