import React, { useEffect,useState } from 'react';
import CheckoutForm from "../CheckoutForm.js";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import Plans from './Plans.js'
import Backdrops from './BackDrop.js'
import APIs from '../../Api.js';
import { log } from 'deck.gl';

const Stripe = ()=> {
    const stripePromise = loadStripe("pk_test_51OHM9lKTFbocMdpQKaYXWleSmUWpIoIfOL2aE6KhzeBT26IPubSNCj7jpKYjflZAphvi4CWfZCqB7STHyI9DQYKx00IaOvzAW9")
    const [credit,setCredit] = useState(0)
    const [purchasedPlan,SetPurchasedPlan] = useState(false)
    const [planData,SetPlanData] = useState([])
    const [showBackDrop,SetBackDrop] = useState(true)

    useEffect(()=>{
        getCredit()
        getAllPlan()
    },[])

    const getCredit = async ()=> {
        const token = localStorage.getItem('token');
        const headerOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
            },
        };
        const response = await fetch(APIs.GET_CREDIT_STRIPE, headerOptions);
        const { credit,data,code } = await response.json();
        if (code == 200){
            setCredit(credit)
            SetPurchasedPlan(data)
            let subscribedPlan = data
            if (data){
                subscribedPlan = JSON.stringify(data)
            }
            localStorage.setItem('subscribe',subscribedPlan)
           
        }
    }

    const getAllPlan = async ()=> {
        const token = localStorage.getItem('token');
        const headerOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
            },
        };
        const response  = await fetch(APIs.GET_PLANS, headerOptions);
        const { code,data } = await response.json();
        if (code == 200){
            SetPlanData(data)
        }
        setTimeout(()=>{
            SetBackDrop(false)
        },500)
    }

    const planHandler = async (plan_id)=> {
        const token = localStorage.getItem('token');
        const headerOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        body:JSON.stringify({id:plan_id})
        };
        const response  = await fetch(APIs.STRIPE_CHECKOUT, headerOptions);
        const {code,url} = await response.json();
        if (code == 200){
            window.open(url, '_blank');
        }
    }

    return (
        <>
            <div className='credit_container'>
                <div className="remaning_credit">
                    <h3>Remaning Credit {credit}</h3>
                </div>
                <div className='App'>
                    <div className="credit_info">
                        {   
                            planData.map((item,index) => 
                                <Plans 
                                    key={index} 
                                    planDetails={item}
                                    onClick={(plan_id)=>planHandler(plan_id)}
                                    purchased={purchasedPlan}
                                />  
                            ) 
                        }
                    </div>   
                    <div className="payment_info">
                        <div className={"product " + (purchasedPlan? '':'overlay')}>
                            <Elements stripe={stripePromise}>
                                <CheckoutForm  getCredit={getCredit} />
                            </Elements>
                        </div>
                    </div> 
                </div>
            </div>
            <Backdrops open={showBackDrop}/>
        </>
    );
}

export default Stripe;