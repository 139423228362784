import * as React from 'react';
import { Component } from 'react';
import MapGL, { Source, Layer, Marker} from 'react-map-gl';
import { Editor, DrawPolygonMode, EditingMode } from 'react-map-gl-draw';
import * as config from '../config';
import { getFeatureStyle, getEditHandleStyle } from './DrawStyle';
import axios from 'axios';
import { Image } from 'antd'
import ApartmentIcon from '@material-ui/icons/Apartment';
import SatelliteIcon from '@material-ui/icons/Satellite';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import OpacityIcon from '@material-ui/icons/Opacity';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import SettingsIcon from '@material-ui/icons/Settings';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import RoomIcon from '@material-ui/icons/Room';
import DataExtractionForm from "./DataExtractionForm";
import DataSettingsForm from "./DataSettingsForm";
import RastersUploadForm from "./RastersUploadForm";
import RedPin from './RedPin';
import GreenPin from './GreenPin';
import APIs from '../Api';


const TOKEN = config.REACT_APP_TOKEN

export default class Draw extends Component {
    constructor(props) {
        super(props);
        this.newMapRef = React.createRef();
        this._editorRef = null;
        this.state = {
            viewport: {
                latitude: -6.64205545,
                longitude: 156.85043421,
                zoom: 9
            },
            features: [],
            aoiExtent: '',
            mode: null,
            selectedFeatureIndex: null,
            hideCars: true,
            carsFilename: null,
            processingBuildings: null,
            processingCars: null,
            processingSettings: null,
            hideShips: true,
            shipsFilename: null,
            processingShips: null,
            hideSpills: true,
            spillsFilename: null,
            processingSpills: null,
            processingImage: null,
            showDataExtractionForm: false,
            showDataSettingsForm: true,
            showRasterForm: false,
            process_target: "",
            jobsList: [],
            current_order: null,
            previous_tileset_id: null,
            mapData: {},
            drawerBbox: [],
            errorMessage: '',
            redPinArea: [],
            greenPinArea: [],
            pinType: 'green',
            maskOpacity:1,
            showSegmentLayer:false,
            enabelStartProcess:true,
            showMarker: false,
            layerData:[],
            layerOpacity:localStorage.getItem('control')|| 1,
        };
        this.getTileSets = this.getTileSets.bind(this)
        
    }

    showProcessingForm = (process_target) => {
        const showDataExtractionForm = !this.state.showDataExtractionForm;
        this.setState({
            process_target: process_target,
            showDataExtractionForm: showDataExtractionForm,
        });
    }

    showSettingForm = () => {
        const showDataSettingsForm = !this.state.showDataSettingsForm;
        this.setState({
            showDataSettingsForm: showDataSettingsForm,
        });
    }

    showMarker = () => {
      const showMarkerToggle = !this.state.showMarker;
      
      if (showMarkerToggle) {
        setTimeout(() => {
          this.setState({
            showMarker: showMarkerToggle,
          });
        }, 750);
      } else {
        this.setState({
          showMarker: showMarkerToggle,
        });
      }
    }
    
    showRasterFormHandler = () => {
        const showRasterForm = !this.state.showRasterForm;
        this.setState({
            showRasterForm: showRasterForm,
        });
    }

    processShips = () => {
        this.setState({ processingShips: true })

        var dataUrl = this.mapInstance.getCanvas().toDataURL()

        axios.post('http://localhost:8000/ships/', {
            dataUrl
        })
            .then(res => {
                alert("Ships Processing Successful")
                this.setState({ processingShips: false })
                this.setState({ shipsFilename: res.data })
                this.setState({ hideShips: false })
            })
            .catch((err) => console.log(err))
    }

    processSpills = () => {
        this.setState({ processingSpills: true })

        var dataUrl = this.mapInstance.getCanvas().toDataURL()

        axios.post('http://localhost:8000/spills/', {
            dataUrl
        })
            .then(res => {
                alert("Spills Processing Successful")
                this.setState({ processingSpills: false })
                this.setState({ spillsFilename: res.data })
                this.setState({ hideSpills: false })
            })
            .catch((err) => console.log(err))

    }

    _updateViewport = viewport => {
        let updateData = this.mapData = {}
        if (this.newMapRef.current) {
            const bounds = this.newMapRef.current.target.getBounds();
            updateData.Bbox = bounds.toArray()
        }
        this.setState({ viewport });
        updateData.longLat = { latitude: viewport.latitude, longitude: viewport.longitude }
        this.setState({ mapData: updateData })
    };

    _onSelect = options => {
        this.setState({ selectedFeatureIndex: options && options.selectedFeatureIndex });
    };

    _onDelete = () => {
        const selectedIndex = this.state.selectedFeatureIndex || 0;
        if (selectedIndex !== null && selectedIndex >= 0) {
            this._editorRef.deleteFeatures(selectedIndex);
        }
    };

    _onUpdate = ({ data, editType }) => {
        if (editType === 'addFeature') {
            const extent = data[0].geometry.coordinates[0].reduce(
                (acc, [x, y]) => {
                    return {
                        xmin: Math.min(acc.xmin, x),
                        ymin: Math.min(acc.ymin, y),
                        xmax: Math.max(acc.xmax, x),
                        ymax: Math.max(acc.ymax, y),
                    };
                },
                {
                    xmin: Infinity,
                    ymin: Infinity,
                    xmax: -Infinity,
                    ymax: -Infinity,
                }
            );

            this.setState({
                mode: new EditingMode(),
                features: data,
                aoiExtent: `${extent.xmin.toFixed(4)} ${extent.ymin.toFixed(4)} ${extent.xmax.toFixed(4)} ${extent.ymax.toFixed(4)}`,
                drawerBbox: [parseFloat(extent.xmin.toFixed(4)), parseFloat(extent.ymin.toFixed(4)), parseFloat(extent.xmax.toFixed(4)), parseFloat(extent.ymax.toFixed(4))],
                enabelStartProcess:false,
            });

        }
    };

    getJobsList = () => {
        const token = localStorage.getItem("token");
        axios.get(APIs.GET_JOB_LIST, {
            params: {
                token: token
            }
        })
            .then(res => {
                this.setState({
                    jobsList: res.data.orders
                })
            }).catch((err) => console.log(err))
    }

    getRastersList = () => {
        const token = localStorage.getItem("token");
        axios.get(APIs.RASTER_LIST, {
            params: {
                token: token
            }
        })
            .then(res => {
                this.setState({
                    rastersList: res.data.rasters
                })
            }).catch((err) => console.log(err))
    }

    showResultOnMap = (mb_tileset_id, type) => {
        const { previous_tileset_id } = this.state;

        // Remove previous Source and Layer components from map
        if (previous_tileset_id) {
            this.mapRef.getMap().removeLayer(previous_tileset_id);
            this.mapRef.getMap().removeSource(previous_tileset_id);
        }

        // Add new Source and Layer components to map
        this.mapRef.getMap().addSource(mb_tileset_id, {
            type: type,
            url: `mapbox://${mb_tileset_id}`,
        });

        if (type == "vector") {
            if (this.state.process_target == "cars") {
                this.mapRef.getMap().addLayer({
                    id: mb_tileset_id,
                    type: 'line',
                    source: mb_tileset_id,
                    'source-layer': mb_tileset_id.split('.')[1],
                    paint: {
                        'line-color': '#627BC1',
                        'line-width': 3,
                    },
                });
            } else {
                this.mapRef.getMap().addLayer({
                    id: mb_tileset_id,
                    type: 'fill',
                    source: mb_tileset_id,
                    'source-layer': mb_tileset_id.split('.')[1],
                    paint: {
                        'fill-color': '#627BC1',
                        'fill-opacity': 1,
                    },
                });
            }

        } else {
            this.mapRef.getMap().addLayer({
                id: mb_tileset_id,
                type: 'raster',
                source: mb_tileset_id,
                'source-layer': mb_tileset_id.split('.')[1],
                'minzoom': 0,
                'maxzoom': 22
            });
        }

        this.setState({
            previous_tileset_id: mb_tileset_id,
        });

    }

    drawPolygon = () => {
        if (this._editorRef && this.state.features.length > 0) {
            this._editorRef.deleteFeatures(0);
        }
        return new DrawPolygonMode();
    };

    drawPolygonHandler = () => {
        this.setState({ mode: this.drawPolygon() })
    }

    _renderDrawTools = () => {
        // copy from mapbox
        return (
            <div className="mapboxgl-ctrl-top-left">
                <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
                    <button
                        className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
                        title="Polygon tool (p)"
                        onClick={this.drawPolygonHandler}
                    />
                    <button
                        className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash"
                        title="Delete"
                        onClick={this._onDelete}
                    />
                </div>
            </div>
        );
    };

    handleMapLoad = (map) => {
        if (map) {
            this.newMapRef.current = map;
        }
    };

    pins = () => (   
        <>  
            {
                this.state.redPinArea && this.state.redPinArea.map((city, index) => (
                    <Marker
                        key={`marker-${index}`}
                        longitude={city.longitude}
                        latitude={city.latitude}
                        anchor="bottom"
                    >
                        <RedPin />
                    </Marker>
                ))
            }
            {  
                this.state.greenPinArea && this.state.greenPinArea.map((city, index) => (
                    <Marker
                        key={`marker-${index}`}
                        longitude={city.longitude}
                        latitude={city.latitude}
                        anchor="bottom"
                    >
                        <GreenPin />
                    </Marker>
                ))
            }
        </>
                
    )

    removeData = () => {
        this.setState({ selectedFeatureIndex: null })
    }

    onClick = (event => {
        if(this.state.showMarker){
            let pinType = this.state.pinType
            if (pinType == 'red') {
                let allRedPinArea = this.state.redPinArea
                let newData = { "longitude": event.lngLat[0], "latitude": event.lngLat[1] }
                let updatedData = [...allRedPinArea, newData]
                this.setState({ redPinArea: updatedData })
            } else {
                let allGreenPinArea = this.state.greenPinArea
                let newData = { "longitude": event.lngLat[0], "latitude": event.lngLat[1] }
                let updatedData = [...allGreenPinArea, newData]
                this.setState({ greenPinArea: updatedData })
            }
        }

        const { features } = event;
        if (features && features.length > 0) {
          const clickedLayerId = features[0].layer.id;
      
          // Assuming your layer IDs follow the pattern 'my-tileset-layer-index'
          const index = clickedLayerId.split('-').pop();
      
          // Hide the layer associated with the clicked layer
          const hiddenLayerId = `my-tileset-layer-${index}`;
          const { mapRef } = this;
          if (mapRef) {
            mapRef.getMap().removeLayer(hiddenLayerId);
      
            // Remove the source associated with the clicked layer
            const hiddenSourceId = `my-tileset-source-${index}`;
            mapRef.getMap().removeSource(hiddenSourceId);
          }
        }
    });

    changeGroundType = (type) =>{
        this.setState({pinType:type})
    }
 
    resetPins = () => {
        this.setState({greenPinArea:[],redPinArea:[]})
    }

    maskOpacityHandler  = (value) => {
        this.setState({maskOpacity:value})
    }

    // segmentLayer = () =>{
    //     let flagInData = this.state.greenPinArea.map(item => [item.longitude, item.latitude])
    //     const greenGeoJson = {
    //         type: 'FeatureCollection',
    //         features: [
    //             {
    //                 type: 'Feature',
    //                 properties: {},
    //                 geometry: {
    //                     type: 'Polygon',
    //                     coordinates: [
    //                         flagInData,
    //                     ],
    //                 },
    //             },
    //         ],
    //     };

    //     let flagOutData = this.state.redPinArea.map(item => [item.longitude, item.latitude])
    //     const redGeoJson = {
    //         type: 'FeatureCollection',
    //         features: [
    //             {
    //                 type: 'Feature',
    //                 properties: {},
    //                 geometry: {
    //                     type: 'Polygon',
    //                     coordinates: [
    //                         flagOutData,
    //                     ],
    //                 },
    //             },
    //         ],
    //     };
    //     return(
    //         <>
    //         { flagInData.length > 0 && 
    //             <Source id="segmentation-source" type="geojson" data={greenGeoJson}>
    //                 <Layer 
    //                     id='segmentation-layer'
    //                     type= 'fill'
    //                     paint={{
    //                         'fill-color': '#072F6A',
    //                         'fill-opacity': 1
    //                     }}
    //                 />
    //             </Source>
    //         }
    //         { flagOutData.length > 0 && 
    //             <Source id="segmentation-source" type="geojson" data={redGeoJson}>
    //                 <Layer 
    //                     id='segmentation-layer'
    //                     type= 'fill'
    //                     paint={{
    //                         'fill-color': '#000',
    //                         'fill-opacity': 1
    //                     }}
    //                 />
    //             </Source>
    //         }
    //          </>
    //     )
    // }

    showSegmentLayerHanlder = ()=>{
        let showlayer = !this.state.showSegmentLayer
        this.setState({showSegmentLayer:showlayer})
    }

    showMarker = () => {
        const showMarkerToggle = !this.state.showMarker;
        
        if (showMarkerToggle) {
          setTimeout(() => {
            this.setState({
              showMarker: showMarkerToggle,
            });
          }, 650);
        } else {
          this.setState({
            showMarker: showMarkerToggle,
          });
        }
    }

    getTileSets = () => {
        let that = this
        const tilesetsUrl = `https://api.mapbox.com/tilesets/v1/hassanms?access_token=${config.MAP_BOX_TOKEN}`;
        axios.get(tilesetsUrl).then(function (response) {
            that.setState({layerData:response.data})

        }).catch(function (error) {
            console.error('Error:', error);
        })
    }

    mapLayer = () => ( this.state.layerData.map((item,index)=> (
    <div className='layerControl' style={{ position: 'relative' }} key={index}>
        <Source
            id={"my-tileset-source-"+index}
            type={item.type}
            url={"mapbox://"+item.id}
        />
        <Layer
            id={"my-tileset-layer-"+index}
            type="fill"
            source={"my-tileset-source-"+index}
            source-layer={item.name}
            paint={{
                'fill-color': 'pink',
                'fill-opacity': (this.state.layerOpacity==1)?1:0 ,
            }}
        />
    </div> 
        )
    )
    )

    componentDidMount() {
        this.mapInstance = this.mapRef.getMap()
        this.mapInstance = this.mapRef.getMap()
        this.getJobsList()
        this.getRastersList()
        this.getTileSets()

    }

    showLayerHandler = () =>{
        let layerControl = this.state.layerOpacity == 1?0:1
        this.setState({layerOpacity:layerControl})
        localStorage.setItem('control',layerControl)

    

        // const cityCoordinates_ = [
        //         [
        //             156.85043421,
        //             -6.64205545
        //         ],
        //         [
        //             156.72339512,
        //             -6.64252252
        //         ],
        //         [
        //             156.72371461,
        //             -6.76794685
        //         ],
        //         [
        //             156.72371505,
        //             -6.7687042
        //         ],
        //         [
        //             156.72366721,
        //             -6.89357034
        //         ],
        //         [
        //             156.7236653,
        //             -6.89434197
        //         ],
        //         [
        //             156.72331275,
        //             -7.00693435
        //         ],
        //         [
        //             156.85046159,
        //             -7.00760819
        //         ],
        //         [
        //             156.85069051,
        //             -6.89454749
        //         ],
        //         [
        //             156.85068952,
        //             -6.89376488
        //         ],
        //         [
        //             156.85082327,
        //             -6.76879598
        //         ],
        //         [
        //             156.85082085,
        //             -6.76801586
        //         ],
        //         [
        //             156.85043421,
        //             -6.64205545
        //         ]
        // ]
        

        // const map = this.mapRef.getMap()

        // if (map) {
        //     const imageUrl = 'https://search.21at.net/browser/index/pd/ndi/at21/bj2/2023/05/TRIPLESAT_3_PMS_20230503082337_00445BVI_010/TRIPLESAT_3_MS_20230503082337_00445BVI_010_browser.jpg'
        //     map.addSource('image-source', {
        //         type: 'image',
        //         url: imageUrl,
        //         coordinates: cityCoordinates,
        //     });
        
        //     map.addLayer({
        //         id: 'image-layer',
        //         type: 'raster',
        //         source: 'image-source',
        //     });
            

        //     map.addLayer({
        //         id: 'square-border',
        //         type: 'line',
        //         source: {
        //         type: 'geojson',
        //         data: {
        //             type: 'Feature',
        //             geometry: {
        //             type: 'Polygon',
        //             coordinates: [cityCoordinates_],
        //             },
        //         },
        //         },
        //         layout: {},
        //         paint: {
        //         'line-color': '#FF0000',
        //         'line-width': 2,
        //         },
        //     });
            

        //     for (const [index, coordinate] of cityCoordinates_.entries()) {
        //         map.jumpTo({ center: coordinate, zoom: 9 });
        //     }
        
        // }

    }
    
    render() {

        const {
            viewport,
            mode,
            hideCars,
            carsFilename,
            hideShips,
            shipsFilename,
            hideSpills,
            spillsFilename
        } = this.state;

        return (
            <>
                <DataExtractionForm
                    extent={this.state.aoiExtent}
                    drawPolygonHandler={this.drawPolygonHandler}
                    isVisible={this.state.showDataExtractionForm}
                    processTarget={this.state.process_target}
                    jobsList={this.state.jobsList}
                    submitHandler={this.getJobsList}
                    orderClickHandler={this.showResultOnMap}
                />
                <DataSettingsForm
                    extent={this.state.drawerBbox}
                    mapData={this.state.allCities}
                    flagIn={this.state.greenPinArea}
                    flagOut={this.state.redPinArea}
                    isVisible={this.state.showDataSettingsForm}
                    deleteData={this._onDelete}
                    changePinType={(type)=>this.changeGroundType(type)}
                    modelClose={this.showSettingForm}
                    resetPins={this.resetPins}
                    // opacitys={(value)=>this.maskOpacityHandler(value)}
                    // showSegment={this.showSegmentLayerHanlder}
                    enabelStartProcess={this.state.enabelStartProcess}
                />
                <RastersUploadForm
                    isVisible={this.state.showRasterForm}
                    rastersList={this.state.rastersList}
                    submitHandler={this.getRastersList}
                    rasterClickHandler={this.showResultOnMap}
                />

                <MapGL
                    ref={map => this.mapRef = map}
                    {...viewport}
                    width="100%"
                    height="100%"
                    mapStyle="mapbox://styles/hassanms/ckhakjywz52lh19of76vsm4wn"
                    mapboxApiAccessToken={TOKEN}
                    onViewportChange={(viewport) => this._updateViewport(viewport)}
                    preserveDrawingBuffer={true}
                    onLoad={this.handleMapLoad}
                    onClick={this.onClick}
                >

                    <button id="rasters" style={{ position: 'absolute', top: 100, left: 10 }} onClick={() => this.showRasterFormHandler()} title="Upload rasters">
                        <SatelliteIcon fontSize="large" />
                    </button>

                    <button id="buildings" style={{ position: 'absolute', top: 150, left: 10 }} onClick={() => this.showProcessingForm("buildings")} title="Detect Buildings" disabled={this.state.processingBuildings}>
                        <ApartmentIcon fontSize="large" />
                    </button>

                    <button id="cars" style={{ position: 'absolute', top: 200, left: 10 }} onClick={() => this.showProcessingForm("cars")} title="Detect Cars" disabled={this.state.processingCars}>
                        <DriveEtaIcon fontSize="large" />
                    </button>

                    <button id="ships" style={{ position: 'absolute', top: 250, left: 10 }} onClick={() => this.processShips()} title="Detect Ships" disabled={this.state.processingShips}>
                        <OpacityIcon fontSize="large" />
                    </button>

                    <button id="spills" style={{ position: 'absolute', top: 300, left: 10 }} onClick={() => this.processSpills()} title="Detect Spills" disabled={this.state.processingSpills}>
                        <LocalDrinkIcon fontSize="large" />
                    </button>

                    <button id="marker" className={this.state.layerOpacity==1? 'active':''} style={{ position: 'absolute', top: 400, left: 10 }} onClick={() => this.showLayerHandler()} title="Marker">
                      <RemoveRedEyeIcon fontSize="large" />
                    </button>

                    <button id="control" className={this.state.showMarker? 'active':''} style={{ position: 'absolute', top: 450, left: 10 }} onClick={() => this.showMarker()} title="Marker">
                      <RoomIcon fontSize="large" />
                    </button>

                    <button id="settings" style={{ position: 'absolute', top: 350, left: 10 }} onClick={() => this.showSettingForm()} title="settings" disabled={this.state.processingSettings}>
                      <SettingsIcon fontSize="large" />
                    </button>
                     
                    {this.mapLayer()}
                    

                    {!hideCars && <Image
                        style={{ float: "right" }}
                        width={100}
                        src={"http://localhost:8000/media/cars/" + carsFilename}
                    />}

                    {!hideShips && <Image
                        style={{ float: "right" }}
                        width={100}
                        src={"http://localhost:8000/media/ships/" + shipsFilename}
                    />}

                    {!hideSpills && <Image
                        style={{ float: "right" }}
                        width={100}
                        src={"http://localhost:8000/media/spills/" + spillsFilename}
                    />}

                    <Editor
                        ref={_ => (this._editorRef = _)}
                        style={{ width: '100%', height: '100%' }}
                        clickRadius={12}
                        mode={mode}
                        onSelect={this._onSelect}
                        onUpdate={this._onUpdate}
                        editHandleShape={'circle'}
                        featureStyle={getFeatureStyle}
                        features={this.features}
                        editHandleStyle={getEditHandleStyle}
                    />
                    {this._renderDrawTools()}
                    {this.pins()}
                </MapGL>
            </>
        );
    }

}